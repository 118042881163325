﻿
.site-map {
	.site-search-content {
		margin-bottom: 0;
	}
	
	.site-map-container {		
		display: inline-block;
		vertical-align: top;

		&:first-child {
			width: calc(96% / 3);
		}

		&:not(:first-child) {			
			padding-left: 1.5rem;
		}		

		.site-map-section {
			.section-title {
			    padding: 1.25rem;
			}

			.child-section-title {
				font-size: 1.125rem;
				padding: 0 0 1.25rem 2.5rem;
				font-weight: 700;		
			}

			.expand {		
				.child-section-title {
					cursor:	pointer;	

					&:after {
						content:$ionicon-var-ios-arrow-down;
						font-family:$font-family-ionicons;						
						font-size: 1em;
						position: relative;
						left: 7px;
						top: 1px;
						@include transition(transform);
					}

					&.open {						
						&:after {
							transform: rotateX(180deg);
						}
					}
				}

				.applied-filters-label {
					padding: 0 4rem $wrapper-subpadding;
					font-size: 1rem;
				}
			}		
		}

		@include respond-to-max-width($bp-mid-mobile) {
			padding-left: 0 !important;
            width: 100% !important;
			float: none;			
        }
	}	
}