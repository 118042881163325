$sb-font-size: rem(24px);
$sb-padding-vertical: rem(15px);
$sb-padding-side: rem(20px);

.searchbox {
    position: relative;
    border: 0 none;
    padding: 0;


    &.is-focused {
        .searchbox-label {
            display: none;
        }

        .searchbox-input {
            padding-left: rem(20px);

            @include placeholder() {
                font-size: 0;
            }
        }
    }
}

.searchbox-input {
    width: 100%;
    background-color: $color-brand-white;
    border: 1px solid $color-input-gray;
    font-size:$sb-font-size;
    color: $color-light-black;
    padding: $sb-padding-vertical rem(45px) $sb-padding-vertical $sb-padding-side;
    box-sizing: border-box;

    @include placeholder() {
        color: $color-light-black;
    }
}

.searchbox-label {
    color: $color-light-black;
    font-weight:$font-weight-bold; 
    text-transform: uppercase;
    font-size: $sb-font-size;
    cursor: text;
}

.searchbox-btn {
    background-color: transparent;
    border: 0 none;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    font-size:rem(30px);
    padding: 0 rem(25px);
    color: $color-light-black;


    &:before{
        content:$ionicon-var-search;
        font-family:$font-family-ionicons;
        position: relative;
    }
}


@include screen($bp-mobile) {
    .searchbox-input,
    .searchbox-label {
        font-size: rem(16px);
    }
}

@keyframes cursorBlink {
  0% {
    opacity: 1; 
  }
  100% {
    opacity: 0;
  }
}

.searchbox--landing {
    border-bottom: 1px solid $color-brand-white;
    margin:  $top-bottom-spacing 0;

    .searchbox-label {
        @include center(vertical);

        position: absolute;
        left: $sb-padding-side; 
        transform: uppercase;
        color: $color-brand-darkyellow;
        font-weight: $font-weight-bold;
        
        @include screen($bp-mobile-portrait) {
          display:none;
        }

        &:after {
            content: '|';
            font-weight: $font-weight-light;
            display: inline-block;
            margin-left: 0.25em;
            animation: cursorBlink 0.9s ease-in-out infinite alternate;
        }
        
    }
    
    .searchbox-mobile-label {
        display: none;
        @include screen($bp-mobile-portrait) {
          display:block ;
        }
    }

    .searchbox-input {
        padding-left: rem(125px);
        border: 1px solid $color-brand-darkyellow;
        color: $color-text-gray;

        @include placeholder() {
            text-transform: uppercase;
        }
    }   

    .searchbox-btn {
        
    } 
}

.searchbox--orange {


    .searchbox-label {
        color: $color-brand-orange;
    }

    .searchbox-input {
        border: 1px solid $color-brand-orange;
    }
}




@include screen($bp-mobile) {
    .searchbox--landing {
        .searchbox-input {
            padding-left: rem(90px);
        } 
    }
}