$full-height-with-nav: calc(100vh - $head-height-collapsed);

.statblock {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-color: $color-gray-section-background;

  &-list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(40px);
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    z-index: 1;
    padding: rem(48px) rem(36px);
    flex-wrap: nowrap;
    max-width: 100vw;

    @include screen($bp-desktop, min) {
      padding: rem(79px) rem(100px);
      gap: rem(100px);
    }
  }

  &-list-item {
    color: $color-brand-white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  }

  &-stat {
    @include font-soleil-bold(90px);
    line-height: 1.1;
    font-variant-numeric: tabular-nums;
    overflow-wrap: break-word;
    max-width: calc(100vw - 64px); // subtract container padding
    text-align: left;

    @include screen($bp-desktop, min) {
      @include font-soleil-bold(176px);
      max-width: calc(100vw - 200px); // subtract container padding
    }
  }

  &-description {
    @include font-soleil-regular(26px);
    display: block;
    
    @include screen($bp-desktop, min) {
      @include font-soleil-regular(61px);
    }
  }

  &-image-container {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    height: 100%;
    width: 100%;
    position: relative;

    @include screen($bp-desktop, min) {
      position: sticky;
      top: $head-height-collapsed;
      max-height: $full-height-with-nav;

      &:after {
        position: absolute;
        content: '';
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 40%;
        z-index: 2;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 90%
        );
      }
    }
  }

  &-image {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
