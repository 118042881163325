.select-dropdown {
    position: relative;
    display: inline-block;
    vertical-align: top;
}


.select-dropdown-select {
    position: absolute;
    top: 0;left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    opacity: 0;
    z-index: 5;
    cursor: pointer;
}

.select-dropdown-label {
    $padding: 1rem;

    display: block;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: $padding * 3;
    border-bottom: 1px solid  $color-black;
    background-color: $color-brand-white;


    color:$color-header-gray;

    @include transition(background-color);


    &:hover {
        background-color: $color-med-light-gray;
    }

    &:after {
        position: absolute;
        right: 0;
        margin-left: .5em;
        content:$ionicon-var-ios-arrow-down;
        font-family:$font-family-ionicons;
        font-size: rem(22px);


        .show-navigation &{
            content: $ionicon-var-ios-close-outline;
        }
    }
}

.select-dropdown-label-orange {
  border-color: $color-brand-orange;
  color: $color-brand-orange;
}
