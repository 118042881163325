.large-story-panel{
	@include clearfix;

	padding: $white-space-base 0 $white-space-base;
	box-sizing: border-box;
}

.large-story-panel-heading,
.large-story-panel-copy {
	width: 50%;
	float: left;
	box-sizing: border-box;
}

.large-story-panel-title {
	@include font-soleil-bold(38px);

	color: $color-brand-green;
	line-height: 1.15;
	margin:0 0 1em;
	position: relative;
	text-transform: uppercase;
	transition: transform 0.4s;
}

.large-story-img {
	box-sizing: border-box;
	margin-top: 1em;
	display: block;
	max-width: 100%;
	width: 100%;
}

.large-story-panel-copy {
	@include font-bookmania(20px);

	line-height: 1.55;
}


@include screen($bp-phablet) {


}