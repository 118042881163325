// FONT FAMILY
$font-base:                 16px;
$font-base-tablet:          15px;
$font-base-mobile:          14px;
$sub-section-title-size:    24px;
$section-title-size:        30px;
$font-breadcrumb-size:      18px; // Was 18
$hero-title-size:           55px;
$font-search-box:           20px;
$font-search-icon:          30px;

$font-family-primary:				'Soleil', sans-serif;
$font-family-secondary: 			'Soleil Book', sans-serif;
$font-family-light: 			    'Soleil Light', sans-serif;
$font-family-bold:   			    'Soleil Bold', sans-serif;
$font-family-titles: 				$font-family-primary;
$font-family-bookmania:       'Bookmania', sans-serif;
$font-family-font-awesome: 			'FontAwesome';
$font-family-ionicons:   			'Ionicons';

$font-weight-light: 				300;
$font-weight-normal: 			  400;
$font-weight-semibold:      600;
$font-weight-bold:   				700;
$font-weight-ultra-bold:    900;

body {
    font-family: $font-family-primary;
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #000;
    
    @include screen($bp-phablet) {
        font-size:$font-base-tablet;
    }
}

.section-title {
  font-family: $font-family-primary;
  font-size: $section-title-size;
  font-weight: $font-weight-bold;

  &--highlighted {
    color: $color-brand-orange;
  }

  &--small {
    font-size: rem(18px);
    // font-weight: $font-weight-normal;
  }
}


@mixin font-soleil-bold($font-size) {
  font-size: rem($font-size);
  font-family:$font-family-primary;
  font-weight: $font-weight-bold;
}
@mixin font-soleil-light($font-size) {
  font-size: rem($font-size);
  font-family: $font-family-primary;
  font-weight: $font-weight-light;
}

@mixin font-soleil-regular($font-size) {
  font-size: rem($font-size);
  font-family: $font-family-primary;
  font-weight:$font-weight-normal;
}

@mixin font-soleil-semibold($font-size) {
  font-size: rem($font-size);
  font-family: $font-family-primary;
  font-weight: $font-weight-semibold;
}

@mixin font-bookmania($font-size) {
  font-size: rem($font-size);
  font-family: $font-family-bookmania;
}