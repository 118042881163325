.flex-panels {
  padding: $white-space-base/2 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  .flex-panel {
    & + .flex-panel:not(.gray-background) {
      .flex-panel-col-container {
        border-top: $border-section;
      }
    }

    .flex-panel-col {
      img {
        width: 100%;
      }

      .featured-contact {
        .bio-card {
          width: 33%;
          @include respond-to-max-width($bp-tablet) {
            width: 50%;
          }
          @include respond-to-max-width($bp-mid-mobile) {
            width: 100%;
          }
        }
      }
    }
  }
  .flex-panel.gray-background + .flex-panel {
    .flex-panel-col-container {
      border-top: none;
    }
  }

  .flex-panel-header-full {
    padding-top: $white-space-base;
    .flex-panel-header {
      padding-left: rem(24px);
    }
  }
}
