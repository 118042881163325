.site-search-content {
     margin-bottom: $white-space-base * 2;
     padding-top: $white-space-base * 3;
     min-height: 50%;
     position: relative;

    .applied-filters {
    	margin-bottom: $white-space-half;
	}

	.searchbox--landing{
		margin: 0 0 $white-space-half;
	}

	.tabular-menu  {
		padding-top: $white-space-base;
		border-top: 3px solid $color-border-gray; 
		margin: $white-space-base 0 $white-space-base*2;

        &--no-border {
            border: none;
        }
	}

	.results-count {
		@include font-soleil-bold(30px);
	}
}


.site-search-content-title {
    padding: $section-title-spacing 0;
}

/*==============================
=            Panels            =
==============================*/
$panel-transition-timing: 0.5s;

.site-search-panels {

    padding-top: rem(10px);
    position: relative;
}

.site-search-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    z-index: 2;
    visibility: hidden;

    transition: opacity $panel-transition-timing $transition-ease;


    &.is-selected {
        position: relative;
        opacity: 1;
        z-index: 5;
        visibility: visible;

    }
}


.is-transition-out {
    $tile-transition-timing: .1s;

    .is-selected {
        
        .search-results-item {
            opacity: 0;
            transform: translateY(10px);

            transition: opacity $tile-transition-timing ease-out,
                        transform $tile-transition-timing ease-out;

            &:nth-of-type(1){
                transition-delay: $tile-transition-timing;
            }
            &:nth-of-type(2){
                transition-delay: $tile-transition-timing * 2;
            }
            &:nth-of-type(3){
                transition-delay: $tile-transition-timing* 3;
            }
            &:nth-of-type(4){
                transition-delay: $tile-transition-timing* 4;
            }
            &:nth-of-type(5){
                transition-delay: $tile-transition-timing* 5;
            }
            &:nth-of-type(6){
                transition-delay: $tile-transition-timing* 6;
            }
        }
    }
}


@include screen($bp-phablet) {
	.site-search-content {

		.tabular-menu  {
			margin-bottom: $white-space-half;
		}
	}
}

@include screen($bp-mobile) {

}