$triangle-size: rem(24px);

.tabular-menu {

}

.tabular-menu-list {
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
}

.tabular-menu-option {
    @include transition(background-color);

    flex: 1 1 20%;
    margin-right: 2%;
    background-color: transparent;
    border: 0;
    position: relative;
    font-size: rem(24px);
    line-height: 1.1;
    font-weight: $font-weight-light;
    color: $color-text-gray;
    text-transform: uppercase;
    position: relative;
    padding: rem(20px) .5em;
   

    &:after {
        @include center(horizontal);

        width: 0;
        height: 0;
        content: '';
        display: block;
        top: 100%;
        box-sizing: border-box;
        border-left: 12px solid transparent;
        border-top: 12px solid $color-med-light-gray;
        border-right: 12px solid transparent;
        opacity: 0;
        @include transition(opacity);
    }

    &:hover {
        background-color: $color-med-light-gray;

        &:after {
            opacity: 1;
        }
    }

    &.is-selected {
        background-color: $color-header-gray;
        color: $color-brand-white;

        &:hover {
           color: $color-brand-white; 
           cursor: text;
        }

        &:after {
            opacity: 1;
            border-top: 12px solid $color-header-gray;
        }
    }
}
    
/*================================================
=            dropdown specific styles            =
================================================*/


.tabular-menu-mobile-trigger {
    $arrow-spacing: rem(40px);
    $spacing: rem(20px);

    @include font-soleil-bold(16px);

    background-color: $color-med-light-gray;
    padding-right: $arrow-spacing;
    border: 1px solid $color-input-gray;
    padding: $spacing/2 $arrow-spacing $spacing/2 $spacing;
    cursor:pointer;
    position: relative;
    width: 100%;
    z-index: 10;
    text-align: left;
    display: none;

    &:after {
        @include center(vertical);

        content:$ionicon-var-ios-arrow-down;
        right: $arrow-spacing/2;
        font-family:$font-family-ionicons;
        font-size: rem(25px);
        transform-origin: left top;
        transition: transform $transition-ease $transition-timing;
    }


    .show-dropdown &{
        &:after {
            transform: rotateX(180deg) translateY(-50%);
        }
    }
}



@include screen($bp-desktop) {
    .tabular-menu-option { 
        font-size: rem(20px);
    }
}


@include screen($bp-phablet) {
    .tab-menu--dropdown {
        $spacing: rem(20px);

        position: relative;
        z-index: 20;

        .tabular-menu-list {
            background-color: $color-med-light-gray;
            border: 1px solid $color-input-gray;
            border-top-width: 0;
            z-index: 5;
            display: block;
            padding: $spacing/2 0;
            overflow: hidden;
            max-height: 0;
            opacity: 0;
            transform: translateY(-1em);
            transition: max-height $transition-timing $transition-ease, 
                        transform $transition-timing $transition-ease, 
                        opacity ease-in-out 0.2s;

        }

        &.show-dropdown {
            .tabular-menu-list {
                max-height: rem(700px);
                transform: translateY(0);
                opacity: 1;
            }
        }

        .tabular-menu-option { 
            flex: auto;
            width: 100%;
            text-align: left;
            padding: $spacing;
            margin-right: 0;

            &:after {
                display: none;
            }

            &.is-selected {
                display: none;
            }
        }
    }

    .tabular-menu-mobile-trigger {
        display: block;
    }

}

@include screen($bp-mobile) {
    .tabular-menu-option { 
        font-size: rem(18px);
    }
}