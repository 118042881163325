.flexible-hero {
  box-sizing: border-box;
  min-height: rem(387px);
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0px 40px;

  @include screen($bp-desktop) {
    padding: rem(16px);
  }

  .breadcrumb {
    z-index: 1;
    padding: 0px;

    .container {
      padding: 0px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 95%
    );
  }

  &__image-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__title-container {
    flex-grow: 1;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: $site-redesign-content-width;
    padding: calc($head-height-lg + rem(16px)) 0px rem(40px);

    @include screen($bp-desktop) {
      padding-top: $head-height-sm;
    }

    @include screen($bp-mid-mobile) {
      padding-top: 0px;
    }
  }
  &__title {
    @include font-h1-redesign;
    color: $color-brand-white;
    margin: 0;

    @include screen($bp-desktop, min) {
      // margin-left: rem(269px);
    }
  }
}
