$full-height-with-nav: calc(100vh - $head-height-lg);

.brand-pillars {
  display: flex;
  flex-direction: column;
  padding: rem(84px) rem(18px) rem(24px);
  align-items: center;

  @include screen($bp-desktop, min) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    padding: 0px;
  }

  &-eyebrow {
    @include font-soleil-semibold(14px);
    letter-spacing: 2.7px;
    text-transform: uppercase;
    margin: 0px;
    margin-bottom: rem(18px);
    opacity: 0;
    &.fade-in {
      animation: 0.8s ease-out 0s 1 forwards fade-up;
    }
    @include screen($bp-desktop-large, min) {
      @include font-soleil-semibold(18px);
      margin-bottom: rem(24px);
    }
  }

  &-title {
    @include font-soleil-semibold(28px);
    margin-bottom: rem(28px);
    line-height: 114%;
    opacity: 0;
    &.fade-in {
      animation: 0.8s ease-out 0s 1 forwards fade-up;
    }

    @include screen($bp-desktop-large, min) {
      @include font-soleil-semibold(34px);
      margin-bottom: rem(60px);
    }
  }

  &-eyebrow,
  &-title {
    @include screen($bp-desktop, min) {
      max-width: rem(406px);
    }
  }

  &-cta {
    background-color: $color-brand-orange;
    color: $color-brand-white;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    &.fade-in {
      animation: 0.8s ease-out 0s 1 forwards fade-up;
    }

    @include screen($bp-desktop, min) {
      align-self: flex-start;
    }

    &-label {
      padding: rem(7px) rem(20px);
      line-height: 32px;
      display: block;
      height: 100%;
      position: relative;
      transition: all 0.2s ease-in;
      color: $color-brand-white;
      text-align: center;
      &::after {
        position: absolute;
        padding: rem(7px) rem(20px);
        content: attr(data-label);
        top: 100%;
        right: 0;
        left: 0;
        text-align: center;
      }
    }

    &:hover {
      text-decoration: none;

      .brand-pillars-cta-label {
        transform: translateY(-100%);
      }
    }
  }

  @keyframes fade-up {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .center-container-outer {
    display: flex;
    justify-content: center;
    padding-bottom: rem(50px);
    max-width: $site-mobile-max-width;

    @include screen($bp-desktop, min) {
      height: $full-height-with-nav;
      max-width: none;
      position: sticky;
      top: $head-height-lg;
      z-index: 1;
      grid-row: 1;
      grid-column: 2 / span 1;
      border-right: 1px solid $color-med-light-gray;
      border-left: 1px solid $color-med-light-gray;
    }
  }

  .center-container-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @include screen($bp-desktop, min) {
      margin: 0 50px;
    }
  }

  .pillar-list {
    display: flex;
    flex-direction: column;
    max-width: $site-mobile-max-width;
    width: 100%;

    @include screen($bp-desktop, min) {
      display: contents;
    }
  }

  .pillar {
    width: 100%;
    padding: rem(18px) 0px;
    display: grid;
    column-gap: rem(20px);
    grid-template-rows: auto;
    justify-content: center;

    @include screen($bp-desktop, min) {
      display: flex;
      min-height: $full-height-with-nav;
      grid-column: 1 / span 3;
      grid-row: var(--pillar-row);
      gap: calc(100vw / 3);
      justify-content: space-between;
    }

    .pillar-title {
      @include font-soleil-semibold(42px);
      line-height: 1.1;
      margin: 0px;
      margin-bottom: rem(18px);
      grid-column: 1 / span 2;
      @include screen($bp-desktop-large, min) {
        @include font-soleil-semibold(55px);
        margin-bottom: rem(22px);
      }
    }

    .pillar-body {
      @include font-soleil-regular(20px);
      line-height: 1.3;

      @include screen($bp-desktop) {
        // margin-top: rem(30px);
      }

      @include screen($bp-desktop-large, min) {
        @include font-soleil-regular(26px);
      }
    }

    .pillar-image-container {
      @include screen($bp-desktop) {
        align-items: center;
      }
    }

    .pillar-copy-container,
    .pillar-image-container {
      display: flex;
      
      @include screen($bp-desktop, min) {
        justify-content: center;
        width: calc(100vw / 3);
        margin: 0px rem(24px);
      }
    }

    .pillar-body,
    .pillar-image-container {
      grid-row: 2 / span 1;
    }

    .pillar-copy {
      max-width: rem(302px);
    }

    .pillar-copy,
    .pillar-copy-container {
      @include screen($bp-desktop) {
        display: contents;
      }
    }

    .pillar-image {
      max-width: rem(350px);
      width: 100%;
      object-fit: cover;

      @include screen($bp-desktop) {
        aspect-ratio: auto;
        height: auto;
      }
    }

    &:nth-of-type(odd) {
      grid-template-columns: 1fr rem(136px);
      .pillar-image-container {
        justify-content: flex-end;
      }
      @include screen($bp-desktop, min) {
        flex-direction: row-reverse;
        .pillar-image-container {
          justify-content: center;
          align-self: flex-end;
          margin-bottom: rem(90px);
        }
        .pillar-copy-container {
          align-self: flex-start;
          margin-top: rem(90px);
        }
      }
    }
    &:nth-of-type(even) {
      grid-template-columns: rem(136px) 1fr;
      .pillar-body {
        grid-column: 2 / span 1;
      }
      .pillar-image-container {
        grid-column: 1 / span 1;
      }
      @include screen($bp-desktop, min) {
        .pillar-image-container {
          align-self: flex-start;
          margin-top: rem(90px);
        }
        .pillar-copy-container {
          align-self: flex-end;
          margin-bottom: rem(90px);
        }
      }
    }
  }
}
