.careers-landing-body {
  position: relative;
  border: 0 none;

  @include screen($bp-tile-mobile) {
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .opening-heading {
    @include screen($bp-phablet) {
    }

    @include screen($bp-tile-mobile) {
      min-height: 0;
    }
  }

  & > .container {
    padding: 0px;
  }
}

.careers-landing-body-copy {
  margin-top: $white-space-base;
  padding: 0px;

  @include screen($bp-tile-mobile) {
    margin-top: 0;
    padding-top: 2rem;
  }

  .section-title {
    text-align: center;
    padding-bottom: $white-space-base;
    margin-bottom: $white-space-base;
    border-bottom: $border-section;
  }
}

.careers-landing-body-flex-panels {
  .flex-panel {
    padding-top: 0;
  }

  .flex-panel-col {
    &:first-child {
      padding-left: 0;
    }
  }
}

@include screen($bp-tile-mobile) {
  .careers-landing-body-flex-panels {
    .flex-panel-col {
      margin-left: 0;
      & + .flex-panel {
        margin-top: $white-space-base;
      }
    }
  }
}

.careers-landing {
  @include screen($bp-tile-mobile) {
    .hero-intro-bg {
      display: none;
    }
  }
  .detail-body-copy {
    padding: 0 1.5rem 0.25rem;

    .opportunity-listing {
      width: 48%;
      display: inline-block;

      @include screen($bp-tile-mobile) {
        width: 100%;
      }
    }
  }
}
