.share-tools {
    @include clearfix;
    position: relative;
    left: auto;
    margin: 0;

    li {
        list-style: none;
    }
}

.share-tool {
    float: left;
    border: 1px solid $color-border-orange;
    margin-right: -1px;

    a, span {
        $icon-size: rem(38px);
        $icon-size-mobile: rem(32px);

        @include size($icon-size);

        background-color: $color-brand-orange;
        display: inline-block;
        color: $color-brand-white;
        position: relative;
        vertical-align: top;

        &:focus {
            outline: none;    
          }

        &:hover {
            background-color: $color-brand-white;
            color: $color-brand-orange;
        }

        @include respond-to-max-width($bp-mid-mobile) {
            @include size($icon-size-mobile);
        }

        i {
            @include center(both);
            font-size: rem(18px);

            @include respond-to-max-width($bp-mid-mobile) {
                font-size: rem(20px);
            }

            &.ion-social-linkedin {
                font-size: rem(22px);
            }

            &.vcard {
                transform: translate(-55%, -45%);
                font-size: rem(18px);
                font-weight: $font-weight-semibold;
                font-family: $font-family-bookmania;

                &:before {
                    content: 'V';
                }
            }

            &.pdf {
                font-size: rem(11px);
                font-weight: $font-weight-semibold;
                font-style: inherit;
                font-family: $font-family-bold;

                &:before {
                    content: 'PDF';
                }

                @include respond-to-max-width($bp-mid-mobile) {
                    font-size: rem(12px);
                }
            }
        }
    }

	.expand-share-tools {
		cursor: pointer;
	}
}
