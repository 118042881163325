$author-border-size: 4px;

.author-card {
    position: relative;
    background-color: $color-brand-white;
    transition: all $transition-ease $transition-timing;
    border-bottom: $author-border-size solid $color-border-gray;
    min-height: rem(340px);
    overflow: hidden;

    &:hover {
        box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.05);
        border-color: $color-brand-orange;
        color: $color-brand-black;
        text-decoration: none;
    }
}

//zooming effect
.author-card-body {
    display: block;
    position: relative;
    margin-bottom: -150px;
    padding-bottom: 150px;
    background-color:rgba(255, 255, 255, 0.01);
}

.author-card-figure {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-bottom: 48%;

    img {
        position: absolute;
        width: 120%;
        right: 0;
        display: block;
    }
}

.author-card-header {
    padding:  rem(12px) 1rem 0;
}

.author-card-name {
    @include font-h5();
    margin: 0;
    font-weight: $font-weight-bold;
    margin-bottom: rem(5px);

    .pdf & {
        font-size: 1rem;
    } 
}


.author-card-level {
    @include font-soleil-bold(16px);
}

.author-card-link {
    color: $color-brand-orange;

    &:hover {
        color: $color-text-gray;
    }
}

.author-card-social {
    padding: rem(18px);
    background-color: $color-brand-white;
    box-sizing: border-box;
    display: inline-block;

    .share-tool {
        margin-right: 1rem;

        a, span {
            $icon-size: rem(26px);

            @include size($icon-size);
            border-radius: 2px;

            i {
                font-size: rem(18px);
            }
        }
    }
}

@include screen($bp-mobile) {

}
