$content-offset: rem(90px); // For Redesign Overlay Content

.home {
  $overlay-padding: 200px;
  $overlay-timing-hide: 0.25s;
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: 50%, 0;

  &.home-hero-container-js {
    opacity: 0;
    transition: opacity 0.1s ease-in;

    @include screen($bp-desktop) {
      --isMobile: true;
    }
    &.show {
      opacity: 1;

      .hero-title {
        animation: 0.8s ease-out 0s 1 forwards fade-up;
      }
      .hero-tagline {
        animation: 0.6s ease-out 0.2s 1 forwards fade-up;
      }
    }
  }

  .hero {
    height: 100vh;
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: flex-end;
    @include screen($bp-desktop, min) {
      scroll-snap-align: start;
      scroll-margin-top: $head-height-lg;
      scroll-snap-stop: always;
    }
  }

  .hero-scroll-indicator {
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding-left: rem(16px);
    border-left: 1px solid $color-brand-white;
    height: rem(130px);
    width: rem(154px);

    @include screen($bp-desktop) {
      display: none;
    }
  }

  .hero-scroll-text {
    @include font-eyebrow-redesign;
    color: $color-brand-white;
    line-height: 1;
  }

  .hero-text-container {
    display: flex;
    flex-direction: column;
    gap: rem(30px);
    color: $color-brand-white;
    padding: 0px 0px rem(141px) rem(141px);

    @include screen($bp-desktop) {
      gap: rem(18px);
      padding: rem(40px) rem(36px) calc(rem(40px) + env(safe-area-inset-bottom));
    }

    .hero-title {
      @include font-soleil-regular(62px);
      font-weight: $font-weight-semibold;
      text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
      line-height: 110%;
      margin: 0px;
      max-width: rem(880px);
      transform: translateY(50px);
      opacity: 0;

      .show & {
        animation: 0.8s ease-out 0s 1 forwards fade-up;
      }

      @include screen($bp-desktop) {
        @include font-soleil-regular(37px);
        font-weight: $font-weight-semibold;
        line-height: 123%;
      }
    }

    .hero-tagline {
      @include font-soleil-regular(26px);
      max-width: rem(602px);
      transform: translateY(50px);
      opacity: 0;
      .show & {
        animation: 0.6s ease-out 0.2s 1 forwards fade-up;
      }

      @include screen($bp-desktop) {
        @include font-soleil-regular(20px);
      }
    }
  }

  @keyframes fade-up {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        358deg,
        rgba(0, 0, 0, 0.66) 22.69%,
        rgba(0, 0, 0, 0) 99.49%
      );
    }
  }
  .video-container video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include screen($bp-desktop) {
      display: none;
    }
  }

  .overlay-image {
    opacity: 0;
    visibility: hidden;
    z-index: -100;
    height: 100vh;
    width: 100%;
    position: fixed;
    transition: opacity $overlay-timing-hide ease-in-out,
      visibility $overlay-timing-hide ease-in-out;
    text-align: center;
    //this whole trickery was so we could have clear text.  It appears now they just want black. le sigh...
    span {
      position: absolute;
      background-image: url('#{$image-path}/Bilzin_Screensaver.png');
      display: block;
      border-top: solid 500px white;
      border-bottom: solid 500px white;
      width: 100%;
      height: 50vh;
      background-position: 0 center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .overlay-image-logo {
    position: absolute;
    left: 3rem;
    top: 3rem;
    z-index: 100;

    > img {
      width: 100%;
    }
  }

  .main-menu,
  .nav-container {
    opacity: 1;
    transition: opacity $overlay-timing-hide ease-in-out,
      visibility $overlay-timing-hide ease-in-out;
  }

  .nav-container {
    .logo {
      h1 {
        margin: 0;
        line-height: 1;
        display: inline-block;
      }
    }
  }

  &.show-overlay {
    $overlay-timing: 3s;

    .overlay-image {
      z-index: 100;
      opacity: 0.8;
      visibility: visible;
      transition: opacity $overlay-timing ease-in-out $overlay-timing,
        visibility $overlay-timing ease-in-out $overlay-timing;

      span {
        z-index: 100;
      }
    }

    .main-menu,
    .nav-container {
      opacity: 0;
      transition: opacity $overlay-timing ease-in-out $overlay-timing,
        visibility $overlay-timing ease-in-out $overlay-timing;
    }
  }

  .hero-scroll {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 20px;
    color: #e6e6e5;
    font-size: rem(36px);
    z-index: 101;
    display: none;

    i {
      cursor: pointer;
    }

    @media (max-width: $bp-mobile) {
      display: block;
    }
  }

  .variable-news-container {
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px $home-padding;
    color: #e6e6e5;
    font-size: rem(18px);
    z-index: 99;
    text-transform: uppercase;
    font-weight: 300;
    background-color: $color-opaque-dark;

    .variable-news {
      display: inline-block;
      &:after {
        content: ' | ';
        padding: 0 10px;
      }
    }

    .read-more {
      display: inline-block;
    }
  }

  //white image, fades out to colored on mouseout
  &:not(.show-overlay) .logo {
    position: relative;
    z-index: 200;
  }

  @include screen($bp-phablet) {
    .overlay-image-logo {
      img {
        max-width: 50%;
      }
    }

    .overlay-image {
      display: none;
    }

    .variable-news-container {
      font-size: rem(12px);
      padding: 18px 0;
      left: auto;
      right: auto;
      width: 100%;
      text-align: center;
      bottom: 52px;
    }
  }
}
// Some Redesign updates

.home-section-content {
  .flexible-content-component {
    padding-top: 25px;
  }

  @include screen($bp-phablet) {
    padding-top: 0;

    .flexible-content-component,
    .trending-topics {
      padding-top: 15px;
    }
  }
  .tile-item__abstract {
    display: none;
  }

  .trending-topics {
    min-height: calc(100vh - $head-height-lg);
    background-color: $color-gray-section-background;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
      background-color: $color-gray-section-background;
    }

    .trending-topics__list__item {
      border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    }
  }
}

@media (max-width: $bp-mobile) {
  .home-section-offset {
    margin-top: 0;
  }
  .home .hero-items {
    bottom: 0;
  }
  .home .inspiration-quote {
    bottom: 15px;
  }
}
