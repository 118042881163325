﻿.insight-detail {
	margin-top: calc(#{$head-height-lg} + 2rem);

	.breadcrumb {
        max-width: none;
        .container {
            padding: 0;
        }

        .nav-menu-item {
            color: $color-brand-black;

            &:not(:first-of-type):before {
                content: '/ ';
                color: $color-header-gray;
            }

            a {
                font-weight: $font-weight-semibold;
                padding: 0 1px;
            
                &:hover {
                    color: $color-bilzin-orange;
                }
            }

            &:last-of-type {
                color: $color-brand-orange;
            }
        }
    }

	.detail-overview-header {
		padding: $white-space-base 0;

		@include screen($bp-phablet) {
			padding-bottom: 0;
		}

		&:before {
			content: none;
		}

		.detail-overview-title {
			color: $color-brand-black;
            margin: 0em;
		}

        .detail-overview-subtitle{
            font-size: rem(18px);
            margin-top: 0em;
        }

		.date, .type-name {
			// font-size: rem(18px);
			color: $color-text-gray;
			display: inline-block;
		}

		.date:not(:first-of-type)::before {
			content: "|";
			margin: 0 0.4rem 0 0.3rem;
			font-size: rem(20px);
		}

		.date, .type-name, .related-lawyer-listing {
			font-size: rem(18px);
		}


		.related-lawyer-listing {
			.related-lawyer {
				&:after {
					content: ', ';
				}
				&:last-child {
					&:after {
						content: '';
					}
				}
			}
		}
	}

	.file-attachments, .source-url {
		font-family: $font-family-primary;
		font-weight: 400;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		color: $color-text-gray;

		a.btn.btn-white.insights-landing-link {
			text-align: center;
			@include respond-to-max-width($bp-mobile) {
			    max-width: 100%;
				display: block;
				margin-top: 1.5rem;
			}
		}
	}
}
