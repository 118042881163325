.section-nav {
    $margin-right: calc((100vw - 100rem) / 2);

    background-color: $color-light-black;
    float: right;
    padding: rem(25px) rem(10px);
    margin: 0;
    border-radius: rem(25px);
    position: fixed;
    margin-top: $white-space-half;
    transition: margin-left .5s;
    z-index: 150;
    right: 0;
    margin-right: calc(#{$margin-right} + #{$white-space-small});

    &.scrolled {
        margin-right: $margin-right;
        transition: margin-left .5s;

        @include respond-to-max-width($bp-section-nav-right) {
            margin-right: 7px;
            transition: margin-right .5s;
        }
    }
}

.section-nav-item {
    width: rem(15px);
    height: rem(15px);
    border-radius: 7px;
    background-color: $color-med-gray;
    padding: 0;
    margin: 0;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    .nav-item-hover {
        opacity: 0;
        padding: rem(2px) 0;
        position: relative;
        right: rem(140px);
        bottom: rem(5px);
        min-width: rem(140px);
        border-radius: rem(15px);
        background-color: $color-opaque-dark;
        text-align: center;
        font-family: $font-family-bold;
        color: $color-brand-white;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }

    &:not(:first-child) {
        margin-top: rem(25px);
    }

    &:hover, 
    &.is-active {

        &.orange {
            background-color: $color-brand-orange;
        }

        &.green {
            background-color: $color-brand-green;
        }

        &.yellow {
            background-color: $color-brand-yellow;
        }

        &.white {
            background-color: $color-brand-white;
        }
    }

    &:hover {
        .nav-item-hover {
            visibility: visible;
            opacity: 1;
        }
    }
}

.section-nav-mobile-toggle {
    display: none;
}


@include respond-to-max-width($bp-section-nav-right) {
    .section-nav {
        left: initial;
        right: 0;
        margin-right: 3rem;
        transition: margin-right .5s;
    }
}

@include respond-to-max-width($bp-mid-mobile) {

    .main-content--section-nav {
        padding-top: $submenu-height-sm;
    }

    .section-nav { 
         margin: 0;
         top: $head-height-sm;
         left: 0;
         right: 0;
         height: $submenu-height-sm;
         float: none;
         padding: 0;
         border-radius: 0;
         background-color: transparent;

         &.scrolled {
            margin: 0;
        }
    }

    .section-nav-mobile-toggle {
        $padding-side: rem(20px);

        display: block;
        height: 100%;
        width: 100%;
        padding: rem(5px) $padding-side;
        background-color: $color-light-black;
        text-align: left;
        color: $color-brand-white;
        position: relative;
        z-index: 20;

        &:after {
            @include center(vertical);

            content:$ionicon-var-ios-arrow-down;
            right: $padding-side;
            font-family:$font-family-ionicons;
            font-size: rem(25px);

            .show-navigation &{
                content: $ionicon-var-ios-close-outline;
            }
        }
    }

    .section-nav-list {
        $nav-speed: .35s;

        position: absolute;
        background-color: $color-light-black;
        width: 100%;
        bottom: 100%;
        padding:  $submenu-height-sm 0 $submenu-height-sm/2;
        z-index: 10;
        opacity: 0;
        transition: transform $nav-speed $transition-ease,
                    opacity $transition-timing $transition-ease;

        .show-navigation &{
            transform: translateY(100%);
            opacity: 1;
        }
    }

    .section-nav-item {
        width: auto;
        height: auto;
        border-radius: 0;
        padding: rem(10px) rem(20px);
        background-color: transparent !important;
        transition: none;

        &:not(:first-child) {
            margin-top: 0;    
        }

        &.orange {
            color: $color-brand-orange;
        }

        &.green {
            color: $color-brand-green;
        }

        &.yellow {
            color: $color-brand-yellow;
        }

        &.white {
            color: $color-brand-white;
        }


        .nav-item-hover {
            opacity: 1;
            visibility: visible;
            padding: rem(5px) 0;
            position: relative;
            right: auto;
            bottom: auto;
            min-width: inherit;
            border-radius: 0;
            background-color: transparent;
            text-align: left;
            color: inherit;
        }
    }
}