.trending-topics {
  color: $color-black;

  &__data {
    padding-right: 4rem;
    width: 50%;
    @include respond-to-max-width($bp-mid-mobile) {
      padding: 0;
      width: 100%;
    }
    &__title {
      font-size: 3rem;
      line-height: 1.1em;
      font-weight: $font-weight-semibold;
      margin:0 0 1rem 0;
    }

    &__abstract {
      font-size: 1.25rem;
      font-weight: $font-weight-light;
      margin-bottom: 1rem;
    }
  }

  .container {
    padding-top: $white-space-section;
    padding-bottom: $white-space-section;
    background-color: $color-brand-white;
    display: flex;

    @include respond-to-max-width($bp-mid-mobile) {
      display: block;
    }
  }

  &__list {
    width: 50%;
    height: auto;
    @include respond-to-max-width($bp-mid-mobile) {
      width: 100%;
    }

    &__item {
      vertical-align: middle;
      font-weight: $font-weight-normal;
      position: relative;
      border-bottom: 1px solid #f0f0f0;
      &--link {
        padding: 1rem 1rem 1rem 0;
        display: flex;
        align-items: center;
        &__icon {
          font-size: 1.8rem;
          margin-right: 1rem;
        }
        &:hover {
          .svg-icon {
            &--finance {
              background-image: url("#{$svg-path}/finance-orange.svg");
            }
            &--food {
              background-image: url("#{$svg-path}/food-orange.svg");
            }
            &--transit {
              background-image: url("#{$svg-path}/transit-orange.svg");
            }
            &--bar-graph{
                background-image: url("#{$svg-path}/Bar-Graph-Icon_Orange.svg");
            }
            &--binoculars{
                background-image: url("#{$svg-path}/Binoculars-Icon_Orange.svg");
            }
            &--briefcase{
                background-image: url("#{$svg-path}/Briefcase-Icon_Orange.svg");
            }
            &--building{
                background-image: url("#{$svg-path}/Building-Icon_Orange.svg");
            }
            &--compliance{
                background-image: url("#{$svg-path}/Compliance-Icon_Orange.svg");
            }
            &--courthouse{
                background-image: url("#{$svg-path}/Court-House-Icon_Orange.svg");
            }
            &--dining{
                background-image: url("#{$svg-path}/Dining-Icon_Orange.svg");
            }
            &--directional{
                background-image: url("#{$svg-path}/Directional-Icon_Orange.svg");
            }
            &--document-review{
                background-image: url("#{$svg-path}/Document-Review-Icon_Orange.svg");
            }
            &--downtown{
                background-image: url("#{$svg-path}/Downtown-Icon_Orange.svg");
            }
            &--hotels{
                background-image: url("#{$svg-path}/Hotels-Icon_Orange.svg");
            }
            &--hourglass{
                background-image: url("#{$svg-path}/Hourglass-Icon_Orange.svg");
            }
            &--line-graph{
                background-image: url("#{$svg-path}/Line-Graph-Icon_Orange.svg");
            }
            &--money{
                background-image: url("#{$svg-path}/Money-Icon_Orange.svg");
            }
            &--office-building{
                background-image: url("#{$svg-path}/Office-Building-Icon_Orange.svg");
            }
            &--teamwork{
                background-image: url("#{$svg-path}/Teamwork-Icon_Orange.svg");
            }            
          }
        }
        &__name {
          font-size: 1.5rem;
        }
      }

      &:after {
        font-size: 1.5em;
        color: $color-brand-orange;
        content: $ionicon-var-ios-arrow-right;
        font-family: $font-family-ionicons;
        position: absolute;
        vertical-align: middle;
        font-weight: $font-weight-bold;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
