button,
.btn {
  background-color: transparent;
  display: inline-block;
  border: 0 none;
  box-sizing: border-box;
}

$btn-padding-side: rem(15px);
$btn-padding-vertical: rem(20px);
$btn-font-size: rem(18px);

.btn {
  padding: $btn-padding-vertical $btn-padding-side;
  font-size: $btn-font-size;
  line-height: 1;
  color: $color-text-gray;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.btn-gray {
  background-color: $color-med-light-gray;
  transition: $transition-general;

  &:hover {
    background-color: $color-text-gray;
    color: $color-med-light-gray;
  }
}

.btn-white {
  background-color: $color-brand-white;
  border: 1px solid $color-button-border-gray;

  &:hover {
    background-color: $color-button-border-gray;
    color: $color-brand-white;
  }
}

.btn-viewmore {
  font-size: $btn-font-size;
  font-family: $font-family-bold;
  font-weight: $font-weight-semibold;
  padding: $btn-padding-vertical $btn-padding-side;
  color: $color-light-black;
  background-color: $color-brand-white;
  min-width: 28%;
  display: block;
  margin: 0 auto;
  border: 2px solid $color-light-black;
  text-align: center;
  transition: all 0.2s ease-in-out;
  a {
    transition: all 0.2s ease-in-out;
  }

  .button--large {
    text-transform: capitalize;
  }

  &:hover {
    color: $color-brand-orange;
    border-color: $color-brand-orange;
    text-decoration: none;
    a {
      color: $color-brand-orange;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }

    // background-color: inherit;
  }
}

.btn-viewmore--full {
  width: 100%;
}

.btn-viewmore-thin {
  font-size: $btn-font-size;
  font-family: $font-family-bold;
  font-weight: $font-weight-semibold;
  padding: 0.75rem $btn-padding-side;
  color: $color-light-black;
  background-color: $color-brand-white;
  min-width: 28%;
  display: block;
  width: 100%;
  border: 1px solid $color-light-gray;
  text-align: center;
  transition: all 0.2s ease-in-out;

  &:after {
    content: $ionicon-var-plus;
    font-family: $font-family-ionicons;
    position: relative;
    font-weight: bold;
    margin-left: 0.5rem;
    color: $color-brand-orange;
  }

  &:hover {
    text-decoration: none;
    background-color: $color-med-light-gray;
  }
}

.button-link {
  $btn-padding: rem(12px) rem(18px);

  color: $color-brand-white;
  background-color: $color-brand-orange;
  padding: $btn-padding;
  display: inline-flex;
  align-items: center;

  &:after {
    content: $ionicon-var-ios-arrow-right;
    font-family: $font-family-ionicons;
    position: relative;
    font-weight: bold;
    margin-left: 0.5rem;
  }
  &:hover {
    background-color: $color-brand-orange;
    color: $color-charcoal;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
  }

  &--ghost {
    background-color: transparent;
    border: 2px solid $color-brand-orange;
  }
}

@mixin orange-cta() {
  display: block;
  background-color: $color-brand-orange;
  color: $color-brand-white;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }

  &-label {
    padding: rem(7px) rem(20px);
    line-height: 32px;
    display: block;
    height: 100%;
    position: relative;
    transition: all 0.2s ease-in;
    color: $color-brand-white;
    text-align: center;

    &::after {
      position: absolute;
      padding: rem(7px) rem(20px);
      content: attr(data-label);
      top: 100%;
      right: 0;
      left: 0;
      text-align: center;
    }

    &:hover {
      transform: translateY(-100%);
    }
  }
}
