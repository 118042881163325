.people-search-content {
     @include base-wrapper;

     margin-bottom: $white-space-base * 2;
     min-height: 50%;
     position: relative;

    .applied-filters {
    	margin-bottom: $white-space-half;
	}
}


.people-search-content-title {
    padding: $section-title-spacing 0;
}

.people-search-sort {
	@include font-soleil-bold(22px);
	line-height: 1.1;

	text-align: right;
}

.people-search-sort-option {
	@include font-soleil-bold(22px);

	line-height: 1.1;
	color: $color-brand-orange;
	margin-bottom: $white-space-half;



	&.is-selected,
	&:hover {
		color: $color-text-gray;
	}

	& + & {
		border-left: 2px solid $color-border-gray;
		padding-left: .6em;
	}
}

.people-search-results {
	display: none;
}

.people-search-featured {
	position: relative;
	z-index: 5;
	width: 100%;
	visibility: visible;
	opacity: 1;
	
	transition: opacity $transition-timing  $transition-ease,
				visibility 1ms $transition-ease;
}

.people-search-listing {

	.bio-card-list {
		opacity: 0;
		transform: translateY(0px);
		transition: opacity $transition-timing ease-out, transform $transition-timing ease-out;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


//initiate fadein
.show-search-results {

	.people-search-results {
		display: block;
	}

	.applied-filters,
	.people-search-sort {
		animation: fadeIn $transition-timing ease-in-out forwards;
	}

	.bio-card-list {
		opacity: 1;
        transform: translateY(0);
		

		&:nth-of-type(1){
            transition-delay:  $transition-timing;
        }
        &:nth-of-type(2){
            transition-delay: $transition-timing * 8;
        }
        &:nth-of-type(3){
            transition-delay: $transition-timing* 3;
        }
        &:nth-of-type(4){
            transition-delay: $transition-timing* 4;
        }
	}

	.people-search-featured {
		opacity: 0;
		height: 0;
		overflow: hidden;
		visibility: hidden;
		position: absolute;


		transition: opacity $transition-timing  $transition-ease,
					visibility $transition-timing $transition-ease $transition-timing;
	}
}



@include screen($bp-mobile) {
	
}