$full-height-with-nav: calc(100vh - $head-height-lg);

@mixin full-height-with-nav() {
  .home & {
      height: calc(100vh - $head-height-lg);
  }
  height: calc(100vh - $head-height-sm);

  @include screen($bp-desktop) {
    height: calc(100vh - $head-height-sm);
  }
}

.featured-practices {
  display: flex;
  scroll-snap-type: y mandatory;
  border-top: 2px solid #B3B3B5;

  .carousel-container {
    display: flex;
    width: 100%;
  }

  .practices-container {
    width: 50%;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;

    @include screen($bp-desktop) {
      align-items: center;
    }
  }

  .practice-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    padding: rem(21px) rem(16px);
    max-width: $site-mobile-max-width;

    @include screen($bp-desktop, min) {
      @include full-height-with-nav;
      padding: 0px;
      padding: 0 rem(24px);
      max-width: none;
      scroll-snap-align: start;
      scroll-margin-top: $head-height-lg;
      scroll-snap-stop: always;
    }
  }

  .practice {
    display: flex;
    flex-direction: column;
    gap: rem(22px);
    justify-content: center;

    @include screen($bp-desktop, min) {
      width: 100%;
      gap: rem(30px);
      max-width: rem(484px);
    }

    @media screen and (max-height: '700px') {
      gap: rem(30px);
    }

    &-title {
      @include font-eyebrow-redesign;
    }

    &-description {
      @include font-h4-redesign;
      @include screen($bp-desktop) {
        @include font-soleil-regular(20px);
      }
      @media screen and (max-height: '700px') {
        @include font-soleil-regular(20px);
      }
    }

    &-quote {
      @include font-body-redesign;
      @media screen and (max-height: '700px') {
        @include font-soleil-regular(16px);
        line-height: 23px;
      }
    }

    img {
      max-width: 100%;
    }

    &-link {
      background-color: $color-brand-orange;
      color: $color-brand-white;
      overflow: hidden;

      @include screen($bp-desktop, min) {
        align-self: flex-start;
      }

      &-label {
        padding: rem(7px) rem(20px);
        line-height: 32px;
        display: block;
        height: 100%;
        position: relative;
        transition: all 0.2s ease-in;
        text-align: center;

        &::after {
          position: absolute;
          padding: rem(7px) rem(20px);
          content: attr(data-label);
          top: 100%;
          right: 0;
          left: 0;
          text-align: center;
        }
      }

      &:hover {
        text-decoration: none;

        .practice-link-label {
          transform: translateY(-100%);
        }
      }
    }
  }

  .practice-images--desktop {
    width: 50%;
    flex: 1 1 50%;
    @include full-height-with-nav;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: sticky;
    top: $full-height-with-nav;

    @include screen($bp-desktop) {
      display: none;
    }
  }

  .practice-img-container--desktop {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    overflow: hidden;
    @include full-height-with-nav;

    img {
      @include full-height-with-nav;
      object-fit: cover;
      width: 100%;
    }
  }

  .practice-img-container--mobile {
    width: rem(170px);
    height: rem(198px);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @include screen($bp-desktop, min) {
      display: none;
    }
  }

  .explore-all-link {
    @include font-soleil-regular(18px);
    font-weight: $font-weight-semibold;
    color: $color-brand-orange;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 35px;
    margin-top: 16px;
    bottom: 0;
    right: 0;
    transition: all 0.2s ease-in;

    @include screen($bp-desktop, min) {
      margin: 0;
      position: absolute;
    }

    circle,
    path {
      transition: all 0.2s ease-in;
    }

    &:hover {
      color: $color-black;
      text-decoration: none;
      path {
        stroke: $color-brand-white;
      }
      circle {
        fill: $color-bilzin-orange;
        stroke: $color-bilzin-orange;
      }
    }
  }
}
