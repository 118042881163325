.rich-text {
  line-height: $text-line-height;
  margin-bottom: 2rem;
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ul, ol {
    padding-left: 1.2em;
  }
  li {
    list-style: inherit;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
  }
  &__readmore {
      display: none;

      &__button {
        margin-top: $white-space-small;        
        font-weight: $font-weight-bold;
        transition: color 0.2s ease-in-out;

        &:after {
          color: $color-brand-orange;
          content: $ionicon-var-ios-arrow-right;
          font-family: $font-family-ionicons;
          position: relative;
          vertical-align: middle;
          font-weight: $font-weight-bold;
          padding-left: 1em;
        }
      }
  }

  &__cta {
    display: inline-block;
    margin-top: $white-space-small;  
    border: 2px solid $color-brand-orange;        
    padding: rem(10px) rem(25px);  
  }
  .bilzin-button {
    font-weight: $font-weight-normal;
  }

  // WordPress Specific
  .alignright {
      float: right;
      margin: 0 0 20px 20px;
  }
  .alignleft {
      float: left;
      margin: 0 20px 20px 0;
  }

  .aligncenter {
    text-align: center;
    clear: both;
    display: block;
    float: none;
    margin: 0 auto 20px auto;
  }

  &--thinner {
    @include font-soleil-light(16px);
  }

  &--blog-styles {
    a {
      color: $color-brand-orange;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
    ul, ol, iframe {
      margin-top: rem(20px);
      margin-bottom: rem(20px);
    }
    iframe {
      display: block;
      max-width: 100%;
    }
  }
}

