$bio-image-height: 39vw;
$bio-image-max-height: 640px;

.bio-image {
    //background-image: url(/content/site/images/bio-image.jpg);
    height: $bio-image-height;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    background-size: cover;;
    background-position: center;
    max-height: $bio-image-max-height; //75% of 725px
    background-repeat: no-repeat;

	&.landscape{
		visibility: hidden;

		@media screen and (min-width: 568px){
			visibility: visible;
		}
	}

    @include screen($bp-tablet) {

    }

    @include respond-to-max-width($bp-mid-mobile) {
        height: 70vw;
    }

    .video-container {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @include respond-to-max-width($bp-mid-mobile) {
            display: none;
        }
    }
}

.video-container video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: 100%;
    height: auto;
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    @include respond-to-max-width($bp-mid-mobile) {
        top: 27%;

        @media screen and (min-device-width: 769px) {
            top: 27.8%;
            min-height:auto;
        }
    }
}

.bio-hero {
    background-size: cover;
    padding: rem(125px) rem(48px) 0;
    clear: both;

    height: $bio-image-height;
    max-height: $bio-image-max-height;
    box-sizing: border-box;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    @include respond-to-max-width($bp-bio-overview-scale) {
        padding: rem(100px) rem(16px) 0;
        height: auto;
    }

    @include respond-to-max-width($bp-mid-mobile) {
        padding: rem(16px);
    }

    .image-placeholder {
        opacity: 0;
    }

    .bio-overview {
        float: left;
    }

    .section-nav {
        margin-top: 0;
    }

    .share-tools {
        float: left;
        margin: 0;
        padding-left: .5rem;

        @include respond-to-max-width($bp-bio-overview-scale) {
            position: absolute;
            left: 34%;
        }

        @include respond-to-max-width($bp-tablet) {
            left: 46.5%;
        }

        @include respond-to-max-width($bp-mid-mobile) {
            left: rem(10px);
        }

        .share-tool {
            float: none;

            &:not(:first-child) {
                margin-top: -1px;
            }
        }
    }
}
