.svg-icon {
    height: 1em;
    width: 1em;
    display: block;
    background-size: contain;

    &--finance {
        background-image: url("#{$svg-path}/finance.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/finance-orange.svg");
            opacity: 0;
        }
    }

    &--food {
        background-image: url("#{$svg-path}/food.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/food-orange.svg");
            opacity: 0;
        }
    }

    &--transit {
        background-image: url("#{$svg-path}/transit.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/transit-orange.svg");
            opacity: 0;
        }
    }

    &--bar-graph {
        background-image: url("#{$svg-path}/Bar-Graph-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Bar-Graph-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--binoculars {
        background-image: url("#{$svg-path}/Binoculars-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Binoculars-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--briefcase {
        background-image: url("#{$svg-path}/Briefcase-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Briefcase-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--building {
        background-image: url("#{$svg-path}/Building-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Building-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--compliance {
        background-image: url("#{$svg-path}/Compliance-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Compliance-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--courthouse {
        background-image: url("#{$svg-path}/Court-House-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Court-House-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--dining {
        background-image: url("#{$svg-path}/Dining-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Dining-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--directional {
        background-image: url("#{$svg-path}/Directional-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Directional-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--document-review {
        background-image: url("#{$svg-path}/Document-Review-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Document-Review-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--downtown {
        background-image: url("#{$svg-path}/Downtown-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Downtown-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--hotels {
        background-image: url("#{$svg-path}/Hotels-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Hotels-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--hourglass {
        background-image: url("#{$svg-path}/Hourglass-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Hourglass-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--line-graph {
        background-image: url("#{$svg-path}/Line-Graph-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Line-Graph-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--money {
        background-image: url("#{$svg-path}/Money-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Money-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--office-building {
        background-image: url("#{$svg-path}/Office-Building-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Office-Building-Icon_Orange.svg");
            opacity: 0;
        }
    }

    &--teamwork {
        background-image: url("#{$svg-path}/Teamwork-Icon.svg");

        &::before {
            content: '';
            background-image: url("#{$svg-path}/Teamwork-Icon_Orange.svg");
            opacity: 0;
        }
    }


    .blog-podcast-card__icon & {
        &--finance {
            background-image: url("#{$svg-path}/finance-white.svg");
        }

        &--food {
            background-image: url("#{$svg-path}/food-white.svg");
        }

        &--transit {
            background-image: url("#{$svg-path}/transit-white.svg");
        }

        &--bar-graph {
            background-image: url("#{$svg-path}/Bar-Graph-Icon_White.svg");
        }

        &--binoculars {
            background-image: url("#{$svg-path}/Binoculars-Icon_White.svg");
        }

        &--briefcase {
            background-image: url("#{$svg-path}/Briefcase-Icon_White.svg");
        }

        &--building {
            background-image: url("#{$svg-path}/Building-Icon_White.svg");
        }

        &--compliance {
            background-image: url("#{$svg-path}/Compliance-Icon_White.svg");
        }

        &--courthouse {
            background-image: url("#{$svg-path}/Court-House-Icon_White.svg");
        }

        &--dining {
            background-image: url("#{$svg-path}/Dining-Icon_White.svg");
        }

        &--directional {
            background-image: url("#{$svg-path}/Directional-Icon_White.svg");
        }

        &--document-review {
            background-image: url("#{$svg-path}/Document-Review-Icon_White.svg");
        }

        &--downtown {
            background-image: url("#{$svg-path}/Downtown-Icon_White.svg");
        }

        &--hotels {
            background-image: url("#{$svg-path}/Hotels-Icon_White.svg");
        }

        &--hourglass {
            background-image: url("#{$svg-path}/Hourglass-Icon_White.svg");
        }

        &--line-graph {
            background-image: url("#{$svg-path}/Line-Graph-Icon_White.svg");
        }

        &--money {
            background-image: url("#{$svg-path}/Money-Icon_White.svg");
        }

        &--office-building {
            background-image: url("#{$svg-path}/Office-Building-Icon_White.svg");
        }

        &--teamwork {
            background-image: url("#{$svg-path}/Teamwork-Icon_White.svg");
        }
    }
}
