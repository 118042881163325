.latest-insights {
  padding: $white-space-section 0;

  &__wrapper {
    color: $color-black;
    &--gray {
      background-color: $color-section-gray;
    }
    &--dark-gray {
      background-color: $color-charcoal;
      color: $color-brand-white;
    }
  }

  &__header {
    width: 45%;
    margin-bottom: 3rem;
    @include respond-to-max-width($bp-mid-mobile) {
      width: 100%;
    }
    &__title {
      font-size: 3rem;
      line-height: 1.1em;
      font-weight: $font-weight-semibold;
      margin:0 0 1rem 0;
    }

    &__abstract {
      font-size: 1.25rem;
      font-weight: $font-weight-light;
      margin-bottom: 1rem;
    }
  }

  .feature-card__data {
    background: $color-brand-white;
  }

  &__footer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    &--viewall {
      position: relative;
      font-weight: $font-weight-bold;
      padding-right: 1em;

      &:after {
        color: $color-brand-orange;
        content: $ionicon-var-ios-arrow-right;
        font-family: $font-family-ionicons;
        position: absolute;
        right: 0;
        vertical-align: middle;
        font-weight: $font-weight-bold;
      }
    }
  }

  &-cta-link {
    @include orange-cta;
    margin-top: rem(48px);

    @include screen($bp-mid-mobile, min) {
      width: fit-content;
      margin-left: auto;
    }
  }
}
