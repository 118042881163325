$max-width: 75em; // 1200px / 16px

$a-tags: 'a, a:active, a:hover';
$a-tags-hover: 'a:active, a:hover';

#{$a-tags}, span.email {
    color: inherit;
    text-decoration: none;
	transition: color 0.2s ease-in-out;
}

#{$a-tags-hover} {
	color: $color-brand-orange;
}

html,
body {
    height:100%;
    //max-width: 1600px;
}

body {
	margin: auto;
	line-height: 1.4;
}

p {
    margin-bottom: $default-spacing;
    margin-top: 0;

    &:last-child {
        margin-bottom: 0;
    }
}


ul,
ol,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}


a, button {
  &:focus {
    //outline: none;    
    outline: 2px solid #e76d00;
  }
  &:hover {
    text-decoration: underline;
  }
  // &::after {
  //   &:hover {
  //     text-decoration: none !important;
  //   }
  // }
  &.no-link {
	cursor: text;
  }
}

fieldset {
    border: 0 ;
    margin: 0;
    padding: 0;
}

input{
	background-color:$color-input-background-gray;
	color: $color-input-gray;
	outline:none;
	border:none;
}

.to-upper {
	text-transform: uppercase;
}

.padding-top-0 {
	padding-top: 0 !important;
}

.left-align {
	text-align: left !important;
}

.right-align {
	text-align: right !important;
}

.center-align {
	text-align: center !important;
}

.global-header{
	&.pdf{
		img{
			max-width: 425px;
		}
	}
}

a.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}

a.skip-main:focus {
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow: auto;
    margin: 0 35%;
    padding: 5px;
    font-size: 20px;
    outline: 3px solid red;
    text-align: center;
    z-index: 999;
}