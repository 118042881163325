.insights-events-listing {
	&--item {
		padding-bottom: 4em;

		&__meta-data {
			padding-bottom: 0.2em;
			display: flex;
			font-weight: $font-weight-semibold;

			li {
				padding-right: 0.5em;
				&:not(:first-child) {				
					&:before {
						padding-right: 0.5em;
						content: '|';					
					}
				}
			}
		}

		&__title {
			font-size: 1.5em;
			font-weight: $font-weight-bold;
			padding-bottom: 0.2em;
		}

		&__abstract {
			color: $color-text-gray;
			font-weight: $font-weight-light;
		}
	}	
}