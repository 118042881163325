.pdf {
    font-size: 12px;

    &.service-detail-pdf {
        font-size: 24px;

        .section-title,
        .bio-card-name,
        .tile-item__title {
            font-size: 32px !important;
        }

        .practices-area  {
            .practice {
                font-size: 24px;
            }
        }

        .tile-base {
            .title {
                font-size: 24px !important;
            }
        }
    }
}
