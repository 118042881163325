﻿.experience-landing {
	.applied-filters {
		@include base-wrapper;
	}

	.searchbox {
		margin-top: rem(192px);

		@include screen($bp-mobile) {
			margin-top: rem(64px);	
		}
	}

	.call-out-panel {
		$white-space: rem(50px);
		@include base-wrapper;

		border-top: 3px solid $color-border-gray;
		padding-top: $white-space;
		margin-top: $white-space;
		margin-bottom: $white-space;

		.section-title {
    		$vertical-spacing: rem(50px);
    		text-transform: uppercase;
    		text-align: center;
    		margin-bottom:$vertical-spacing;
		}
	}
}