.type-ahead {
	$animation-time: 0.3s; 

  position: relative;
  // z-index: -1;
  z-index: $z-index-typeahead;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: visibility $animation-time ease-in-out, opacity $animation-time ease-in-out, height $animation-time ease-in-out;				

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $color-brand-white;
    padding: 1rem;

    &--section {
      &-title {	
        font-size: rem(24px);
        font-weight: $font-weight-semibold;
      }
      ul {
        margin-bottom: 1rem;
      }
      li {
        font-size: rem(18px);
        margin: 0.5rem 0;
        list-style: none;
        color: $color-input-gray;
        outline: none;
        border: none;
        a {
          cursor: pointer;
        }
      }
    }
  }

  &--insights {
    .type-ahead__content {
      top: calc(100% - 5px);
      left: 1rem;
      right: 1rem;
      padding: 1rem 2rem;
    }
  }

  &.type-ahead-active {
    visibility: visible;
    opacity: 1;
    height: auto;
  }					
}
