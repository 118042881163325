﻿.mobile-header {    
    background-color: #fff;
    position: relative;
    padding: .75em;
    text-transform: uppercase;
    display: none;
    text-align: center;
    @include screen($bp-mobile){
        display: block;
    }
}
