.page-layout {
    &--nav-padded {
        margin-top: $head-height-lg;
    }
    @include respond-to-max-width($bp-desktop) {
        &--nav-padded {
            margin-top: $head-height-sm;
        }
    }
}


.page-layout-blog {
    max-width: $site-max-width;
    margin: 0 auto;
    padding: 0 2rem;
    box-sizing: border-box;
    margin-bottom: 2rem;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    &__two-column-left {
        width: 71%;
        float: left;
        clear: left;
    }

    &__two-column-right {
        width: 25%;
        float: right;
        clear: right;
    }

    &__sticky-box {
        position: relative;
        position: sticky;
        top: 185px;
        z-index: $z-index-jumpmenu - 1;
        transition: top .3s ease-in;
    }

    &__rte {
        padding: 0 1rem;
    }

    .practices-area {
        background-color: $color-brand-white;
        color: $color-brand-black;
        padding: 0;
        margin-bottom: rem(60px);

        .container {
            padding: 0;
        }

        &:before {
            display: none;
        }
        &__background {
            display: none;
        }

        .practices {
            font-size: rem(15px);
        }
    }

    .attorney-area {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
    }

    .insights-area {
        .section-title {
            font-size: rem(18px);
        }
    }

    @include screen($bp-tablet) {
        &__two-column-left,
        &__two-column-right {
            float: none;
            width: 100%;
            margin-bottom: 2rem;
        }

        &__sticky-box {
            position: relative;
            top: 0;
        }
    }
    @include screen($bp-phablet) {
        padding: 1rem;

        .practices-area {
            margin-bottom: rem(40px);
        }
    }
}

    
