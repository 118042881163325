.experience-area {
  margin: $top-bottom-spacing 0;
  .tiles {
    margin: 0;
    padding: 0;
  }
  .tile-border-expand {
    &:after {
      background-color:$color-brand-green;
    }
  }
  .tile-item:nth-child(n+4) {
    .tile-item__image {
        display: none;
    }
  }

}
