.flex-page-component {
	&--rich-text__wrapper {	

		.rich-text {

		}

		&__has-image {
			display: flex;
			@include screen($bp-tile-mobile) {
				display: block;
			}

			&.left-align {
				flex-direction: row-reverse;

				.rich-text {
					text-align: left;
					width: 60%;
					margin-left: $white-space-base;
					@include screen($bp-tile-mobile) {
						width: 100%;
						margin-left: 0;
					}

					&__image {
						width: 40%;
						align-self: start;
						img {
							width: 100%;
						}
						@include screen($bp-tile-mobile) {
							width: 100%;
						}
					}
				}				
			}

			&.right-align {
				flex-direction: row;

				.rich-text {
					text-align: left;
					width: 60%;
					margin-right: $white-space-base; 
					@include screen($bp-tile-mobile) {
						width: 100%;
						margin-right: 0;
					}
					&__image {
						background-size: cover;
						width: 40%;
						img {
							width: 100%;
						}
						@include screen($bp-tile-mobile) {
							width: 100%;
							min-height: 200px;
						}
					}
				}	
			}

			&.center-align {
				flex-direction: column;

				.rich-text {
					text-align: left;
					display: block;
					margin-bottom: $white-space-base;

					&__image {
						img {
							max-width: 100%;
        					max-height: 100%;
        					display: block;
						}
					}
				}
			}
		}
	}
}