$white-space: rem(50px);

.webinars-landing {

	.insights-landing-actions {
		margin-left: 0;
		margin-right: 0;
		border-top: none;
		padding-top: $white-space;
		margin-top: 0;
		margin-bottom: $white-space;

		.section-title {
			padding-bottom: 0;
		}

		.insights-landing-links {
			border-left: none;
		}
	}

	.insights-landing-content {
		margin-top: $white-space;
	}
}

.insights-landing-actions {
    @include base-wrapper ;
    
    border-top: 3px solid $color-border-gray;
    padding-top: $white-space;
    margin-top: $white-space;
    margin-bottom: $white-space;

    .tabular-menu {
        min-width: 50%;
    }
}


.insights-landing-actions {
    display: flex;
    flex-wrap: nowrap;
}


.insights-landing-links {
    $spacing: 3%;

    margin-left: $spacing;
    padding-left: $spacing;
    border-left: $border-base;
    text-align: right;
    flex-grow: 1;
}

.insights-landing-link {
    margin-left:  1%;
    margin-bottom: 1%;
}


/*==============================
=            Panels            =
==============================*/
$panel-transition-timing: 0.5s;

.insights-landing-panels {
    @include base-wrapper ;

    padding-top: rem(10px);
    position: relative;
}

.insights-landing-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    z-index: 2;
    visibility: hidden;

    transition: opacity $panel-transition-timing $transition-ease;


    &.is-selected {
        position: relative;
        opacity: 1;
        z-index: 5;
        visibility: visible;

    }
}


.is-transition-out {
    $tile-transition-timing: .1s;

    .is-selected {
        
        .tile-base {
            opacity: 0;
            transform: translateY(10px);

            transition: opacity $tile-transition-timing ease-out,
                        transform $tile-transition-timing ease-out;

            &:nth-of-type(1){
                transition-delay: $tile-transition-timing;
            }
            &:nth-of-type(2){
                transition-delay: $tile-transition-timing * 2;
            }
            &:nth-of-type(3){
                transition-delay: $tile-transition-timing* 3;
            }
            &:nth-of-type(4){
                transition-delay: $tile-transition-timing* 4;
            }
            &:nth-of-type(5){
                transition-delay: $tile-transition-timing* 5;
            }
            &:nth-of-type(6){
                transition-delay: $tile-transition-timing* 6;
            }
        }
    }
}



@include screen($bp-desktop) {

    .insights-landing-link {
        font-size: rem(14px);
        padding: 0.9em 0.5em;
    }
}

@include screen($bp-mobile) {
    .insights-landing-content {

        position: relative;
        padding-bottom: rem(150px);
    }


    .insights-landing-actions {
        display: block;   
    }

    .insights-landing-links {
        padding-left: 0;
        margin-left: 0;
        border-left: 0 none;
        position: absolute;
        right: $left-right-spacing-tablet;
        left: $left-right-spacing-tablet;
        bottom: $white-space-base/4;        
        text-align: center;
    }

    .insights-landing-link {
        
        margin-left: 0;
        max-width: 35%;
    }

}