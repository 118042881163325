.job-opportunity {
  margin-bottom: rem(123px);

  &__anchor-links {
    display: flex;
    justify-content: center;
    background-color: $color-gray-section-background;
    margin-bottom: rem(110px);
    position: sticky;
    top: $head-height-sm;

    @include screen($bp-phablet) {
      margin-bottom: rem(42px);
    }

    &--flex-container {
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      padding: rem(20px);
      gap: rem(20px);
      width: 100%;
      max-width: $site-redesign-content-width;

      @include screen($bp-phablet) {
        display: block;
        padding: 0px;
      }
    }

    &--label-desktop {
      @include screen($bp-phablet) {
        display: none;
      }
    }

    &--button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: rem(20px);

      svg {
        transform-origin: center;
        transition: all 0.3s ease-in;
        overflow: visible;
      }
      .expanded & {
        svg {
          transform: rotateX(180deg);
        }
      }

      &:hover {
        text-decoration: none;
      }
      @include screen($bp-phablet, min) {
        display: none;
      }
    }

    &--label-desktop,
    &--label-mobile {
      @include font-eyebrow-redesign;
    }

    &--content {
      display: grid;
      grid-template-rows: 0fr;
      overflow: hidden;
      transition: all 0.3s ease-in;
      .expanded & {
        grid-template-rows: 1fr;
      }
      @include screen($bp-phablet, min) {
        display: contents;
      }
    }

    &--wrapper {
      overflow: hidden;
      @include screen($bp-phablet, min) {
        display: contents;
      }
    }

    &--list {
      display: flex;
      flex-direction: column;
      padding: rem(20px);
      gap: rem(20px);
      @include screen($bp-phablet, min) {
        display: contents;
        padding: 0px;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    padding: 0px rem(16px);
  }
  .job-opportunity-listing {
    display: flex;
    flex-direction: column;
    gap: rem(87px);
    max-width: $site-redesign-content-width;

    

    .audience-list {
      display: flex;
      flex-direction: column;
      gap: rem(87px);
      width: 100%;
    }

    .audience-list-item {
      &__label {
        @include font-h3-redesign;
        border-bottom: 1px solid $color-med-light-gray;
        padding-bottom: rem(8px);
        margin: 0px;
        margin-bottom: rem(18px);
      }
    }

    .opportunity-list {
      display: flex;
      flex-direction: column;
      gap: rem(23px);
    }

    .opportunity-list-item {
      display: flex;
      flex-direction: column;
      gap: rem(30px);
      padding: rem(24px) 0px;
      border-bottom: 1px solid $color-med-light-gray;

      &__title {
        @include font-h4-redesign;
        margin: 0px;
      }

      &__abstract {
        @include font-body-redesign;
      }
    }

    .opportunity-list-item-cta {
      @include orange-cta;

      @include screen($bp-mobile, min) {
        align-self: flex-start;
      }
    }
  }

  .no-positions {
    @include font-body-redesign;
  }

  .rss-feed {
    margin-bottom: rem(18px);
    img {
      height: rem(44px);
      width: rem(44px);
    }

    .rss-img {
      display: flex;
      align-items: center;
      gap: rem(16px);
      color: $color-brand-orange;
    }
  }
}
