$full-height-with-nav: calc(100vh - $head-height-lg);

.careers-testimonials {
  display: flex;
  flex-direction: column;
  @include screen($bp-desktop, min) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  .careers-sticky-section {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
    @include screen($bp-desktop, min) {
      height: $full-height-with-nav;
      display: flex;
      position: sticky;
      top: $head-height-lg;
      grid-column: 1 / span 2;
      grid-row: 1;
    }
  }

  .careers-copy {
    width: 100%;
    max-width: rem(453px);
    display: flex;
    flex-direction: column;
    gap: rem(33px);

    @include screen($bp-desktop) {
      max-width: $site-mobile-max-width;
      padding: rem(32px) rem(18px);
      margin: rem(42px) 0px;
      gap: rem(22px);
    } 
  }

  .careers-title {
    @include font-h2-redesign;
    margin: 0px;

    &.fade-in {
      @include fade-up;
    }
  }

  .careers-description {
    @include font-soleil-regular(20px);

    &.fade-in {
      @include fade-up;
    }

    @include screen($bp-desktop) {
      @include font-soleil-regular(18px);
    }
  }

  .careers-cta {
    @include orange-cta;
    align-self: stretch;

    &.fade-in {
      @include fade-up;
    }

    @include screen($bp-desktop, min) {
      align-self: flex-start;
    }
  }

  .careers-image-container {
    @include screen($bp-desktop) {
      display: none;
    }
  }

  .careers-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .careers-copy-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .careers-copy-container,
  .careers-image-container {
    flex: 1 1 50%;
  }

  .testimonials-list {
    box-sizing: border-box;
    padding: rem(31px) rem(16px);
    display: flex;
    gap: rem(20px);
    justify-content: flex-start;
    align-items: stretch;
    grid-column: 1 / span 1;
    grid-row: 2;
    z-index: 1;
    overflow-x: auto;
    background-image: var(--background-image);
    background-size: cover;

    @include screen($bp-desktop, min) {
      padding: rem(44px) 0px;
      grid-column: 2 / span 1;
      grid-row: 1;
      gap: rem(34px);
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-image: none;
      overflow-x: hidden;
    }

    .testimonial {
      box-sizing: border-box;
      width: rem(258px);
      min-width: rem(258px);
      min-height: rem(376px);
      height: auto;
      padding: rem(22px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      background-color: $color-brand-white;
      border-left: 7px solid $color-bilzin-orange;

      @include screen($bp-desktop, min) {
        width: 100%;
        max-width: rem(454px);
        min-height: rem(660px);
        padding: rem(44px) rem(44px) rem(55px);
      }

      &-image-container {
        overflow: hidden;
        border-radius: 50%;
        height: rem(120px);
        width: rem(120px);
        flex-shrink: 0;
        margin-bottom: rem(72px);

        @include screen($bp-desktop, min) {
          height: rem(212px);
          width: rem(212px);
          margin-bottom: rem(128px);
        }
      }

      &-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: right bottom;
      }

      &-copy {
        display: flex;
        flex-direction: column;
      }

      &-eyebrow {
        @include font-eyebrow-redesign;
        margin-bottom: rem(19px);

        @include screen($bp-desktop) {
          font-size: rem(10px);
          margin-bottom: rem(5px);
        }
      }

      &-name {
        @include font-soleil-regular(21px);
        margin: 0px;
        margin-bottom: rem(18px);
        line-height: 1;

        @include screen($bp-desktop, min) {
          @include font-soleil-regular(36px);
          margin-bottom: rem(33px);
          line-height: 1.1;
        }
      }

      &-quote {
        @include font-body-redesign;
        line-height: 30px;
        quotes: '“' '”' '‘' '’';

        @include screen($bp-desktop) {
          font-size: rem(12px);
          line-height: 17px;
        }

        &::before {
          content: open-quote;
        }
        &::after {
          content: close-quote;
        }
      }
    }
  }
}
