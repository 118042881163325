.page-content-wrapper {
  .hero-content-wrapper {
    min-height: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2rem 0;

    @include respond-to-max-width($bp-mid-mobile) {
      padding-top: calc(#{$head-height-sm} + 2rem);
    }

    .breadcrumb {
      z-index: 20;
      top: 0;
      position: absolute;
      padding: calc($head-height-lg + rem(16px)) 0 5rem 0;
      width: 100%;

      &::before {
			content: '';
			position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 30%, transparent 100%);
            mix-blend-mode: multiply;
        }
    }

    &__image {
      display: block;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        @include gradient(rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0), 0deg, 80%);
      }
    }

    &__image--mobile {
      display: none;

      @include screen($bp-mobile) {
        display: block;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          @include gradient(rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0), 0deg, 80%);
        }
      }
    }

    &__title {
      color: $color-brand-white;
      font-size: em($hero-title-size);
      font-weight: $font-weight-semibold;
      margin: 0;
      // padding-left: $indented-padding;
      // margin-top: em(-$hero-title-size/2);
      position: relative;
    }

    &__title--flex {
      line-height: 1.15;

      @include screen($bp-mobile) {
        font-size: 2em;
      }
    }

    &__subtitle {
      color: $color-brand-white;
      font-size: em($hero-title-size/2);
      position: relative;
    }
  }

  .search-bar-wrapper {
    background-color: $color-med-light-gray;
    padding: 1.5em 0;
    position: relative;
    z-index: $z-index-jumpmenu + 1;

    &__btn {
      position: absolute;
      font-size: em($font-search-icon);
      padding: em(4px) em(12px);
      color: $color-brand-orange;

      &:before {
        content: $ionicon-var-search;
        font-family: $font-family-ionicons;
        position: relative;
      }
    }

    &__input {
      border-radius: em($font-search-box);
      width: fill-available;
      background-color: $color-brand-white;
      color: $color-text-gray;
      font-size: em($font-search-box);
      padding: em(10px) em(20px) em(10px) em(50px);
    }
  }

  .simple-header-content-wrapper {
    margin-top: $head-height-lg;
    padding: 3em 0;

    &__title {
      font-size: em($hero-title-size);
      font-weight: $font-weight-semibold;
      padding-bottom: rem(20px);
    }

    &__backlink {
      position: relative;
      padding-left: 1em;

      &:before {
        color: $color-brand-orange;
        content: $ionicon-var-ios-arrow-left;
        font-family: $font-family-ionicons;
        position: absolute;
        left: 0;
        vertical-align: middle;
        font-weight: $font-weight-bold;
      }
    }
  }

  .page-section-content {
    &__noresults {
      padding: 1.5em 0;
      font-size: rem(24px);

      &--topmatches {
        &-title {
          font-size: rem(24px);
          font-weight: $font-weight-semibold;
        }

        ul {
          margin-bottom: 3rem;
        }

        li {
          font-size: rem(18px);
          margin: 0.5rem 0;
          list-style: none;
          color: $color-input-gray;
          outline: none;
          border: none;

          a {
            cursor: pointer;
          }
        }
      }
    }
  }
}