.header {
  $animation-time: 0.3s;
  $input-side-padding: 5px;
  $icon-spacing: 30px;
  height: $head-height-lg;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  transition: all $transition-timing linear;
  z-index: $z-index-mobile-menu;
  max-width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  border-bottom: 1px solid $color-gray-section-background;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @include respond-to-max-width($bp-mid-mobile) {
    background: $color-charcoal;
  }

  .search {
    cursor: pointer;
    border-right: 1px solid $color-gray-section-background;
    border-left: 1px solid $color-gray-section-background;
    padding: 0px rem(32px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to-max-width($bp-mid-mobile) {
      padding: 0px rem(16px);
    }

    svg {
      pointer-events: none;
    }

  }

  &-search {
    height: 100%;
    background: $color-brand-white;
    width: 60%;
    position: absolute;
    right: 0;
    z-index: 1;
    transform: translateX(100%);
    transition: transform $transition-timing linear;
    &__keyword {
      height: 100%;
      width: 100%;
      position: relative;
      transition: visibility $animation-time ease-in-out, opacity $animation-time ease-in-out;
      &--icon {
        font-family: $font-family-ionicons;
        font-size: rem(32px);
        display: flex;
        align-items: center;
        position: absolute;
        width: 2rem;
        height: 100%;
        padding: 0 1rem;
      }
      &--input {
        background: none;
        font-size: rem(18px);
        height: 100%;
        width: 100%;
        padding: 0 4rem;
      }
      &--close {
        font-family: $font-family-ionicons;
        font-size: rem(32px);
        display: flex;
        align-items: center;
        position: absolute;
        width: 2rem;
        height: 100%;
        padding: 0 1rem;
        right: 0;
        top: 0;
      }
    }
    .type-ahead {
      &__content {
        top: -5px;
        left: 0;
        right: 0;
      }
    }
    @include respond-to-max-width($bp-tablet) {
      top: $head-height-lg;
      width: 100%;
      right: 0;
      left: 0;
      &__keyword {
        &--input {
          width: 70%;
        }
      }
    }
    @include respond-to-max-width($bp-mid-mobile) {
      top: $head-height-sm;
      &__keyword {
        &--input {
          width: 70%;
        }
      }
    }  
  }


  &.show-search {
    .header-search {
      visibility: visible;
      opacity: 1;        
      transform: translateX(0);
    }
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(10px);
    padding: 0px rem(32px);
    position: relative;
    cursor: pointer;
    vertical-align: middle;
    color: $color-brand-white;
    transition: color 0.2s ease-in-out;

    @include respond-to-max-width($bp-mid-mobile) {
      padding: 0px rem(16px);
    }

    svg {
      pointer-events: none;
      path {
        transition: all 0.2s ease-in-out;
      }
    }

    &:hover {
      text-decoration: none;
      color: $color-brand-orange;

      svg {
        path {
          &:first-of-type {
            d: path('M4.5 2h29s1.5 0 1.5 1.5v0s0 1.5 -1.5 1.5h-29s-1.5 0 -1.5 -1.5v0s0 -1.5 1.5 -1.5');
          }
          &:last-of-type {
            d: path('M4.5 18.667h29s1.5 0 1.5 1.5v0s0 1.5 -1.5 1.5h-29s-1.5 0 -1.5 -1.5v0s0 -1.5 1.5 -1.5');
          }
        }
      }
    }

    .menu-btn {
      font-size: rem(13px);
      text-transform: uppercase;
    }
  }

  &--blog-topics {
    font-size: 3em;
    margin-top: 6rem;
  }

  &--featured-topic {
    color: $color-brand-orange;
    font-size: 2.375em;
    font-weight: 600;

    &:hover,
    &:active {
      color: $color-text-gray;
    }
  }

  &--gray {
    background: $color-charcoal;
  }

  .language-switcher {
    text-align: center;
    display: inline-block;
    width: 36px;
    position: relative;
    top: -5px;
    cursor: pointer;
    vertical-align: middle;
    margin-right: 1.5em;
    color: #c2c2c2;

    .spanish {
      border-top: solid 2px #fff;
      line-height: 2em;
      padding-top: 5px;

      &.active {
        color: #fff;
      }
    }

    .english {
      line-height: 2.5em;

      &.active {
        color: #fff;
      }
    }

    @include respond-to-max-width($bp-mid-mobile) {
      text-align: center;
      display: inline-block;
      width: 36px;
      position: relative;
      top: -4px;
      cursor: pointer;
      vertical-align: middle;
      margin-right: 7px;
      font-size: 10px;
    }
  }

  @include respond-to-max-width($bp-desktop) {
    height: $head-height-sm;
  }

  .nav-container {
    height: 100%;
    width: 100%;
    background-color: $color-brand-orange;

    .logo {
      background-color: $color-brand-orange;
      width: $one-third-width;
      height: 100%;

      @include respond-to-max-width($bp-tablet) {
        width: 45.8%;
      }

      @include respond-to-max-width($bp-mid-mobile) {
        width: 50%;
      }
    }

    .logo-link {
      display: inline-block;
      padding: rem($home-padding);
      transition: padding $transition-timing linear;

      @include respond-to-max-width($bp-bio-overview-scale) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      @include respond-to-max-width($bp-mid-mobile) {
        padding: 1.7rem 1rem;
      }

      > img {
        transform: translateZ(0);
        float: left;
        width: 100%;
      }
    }

    .primary-nav {
      background-color: $color-header-gray;
      height: 100%;
      width: 66.6667%;

      @include respond-to-max-width($bp-tablet) {
        width: 54.2%;
      }

      @include respond-to-max-width($bp-mid-mobile) {
        width: 50%;
      }

      .primary-nav-container {
        display: table;
        height: 100%;
        width: 100%;
        padding: 0.5em rem($home-padding);
        line-height: 1em;
        box-sizing: border-box;

        @include respond-to-max-width($bp-tablet) {
          line-height: inherit;
          padding-left: 0;
        }

        @include respond-to-max-width($bp-mid-mobile) {
          padding-right: 1.6rem;
        }
      }

      .primary-nav-ui {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
      }

      .breadcrumb {
        display: table-cell;
        width: 80%;
        vertical-align: middle;
        text-align: left;
        padding-left: 5%;

        @include respond-to-max-width($bp-mid-mobile) {
          display: none;
        }

        @include respond-to-max-width($bp-tablet) {
          width: 70%;
        }
      }
    }
  }
  //collapsed styles
  &.is-collapsed {
    height: $head-height-collapsed;

    .nav-container {
      .logo-link {
        padding: $head-collapsed-padding rem($home-padding);

        @include respond-to-max-width($bp-bio-overview-scale) {
          padding-left: 1em;
        }
      }
    }

    .primary-nav {
      .primary-nav-container {
      }
    }

    .breadcrumb {
      display: none;
    }

    @include respond-to-max-width($bp-mid-mobile) {
      height: $head-height-sm;

      .nav-container {
        .logo-link {
          padding: $head-collapsed-padding rem($home-padding);

          @include respond-to-max-width($bp-bio-overview-scale) {
            padding: 1.7rem 1.6rem;
          }
        }
      }
    }
  }

  &-logo {
    // padding: 1rem;
    margin-left: rem(32px);
    max-width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
    @include respond-to-max-width($bp-mid-mobile) {
      max-width: 170px;
      margin: 0px rem(16px);
    }
  }

  &-tools {
    // padding: 1rem;
    font-size: rem(12px);
    display: flex;

    &__language {
      text-align: center;
      display: inline-block;
      width: 36px;
      position: relative;
      top: -5px;
      cursor: pointer;
      vertical-align: middle;
      margin-right: 1.5em;
      color: #c2c2c2;

      .spanish {
        border-top: solid 2px #fff;
        line-height: 2em;
        padding-top: 5px;

        &.active {
          color: #fff;
        }
      }

      .english {
        line-height: 2.5em;

        &.active {
          color: #fff;
        }
      }

      @include respond-to-max-width($bp-mid-mobile) {
        text-align: center;
        display: inline-block;
        width: 36px;
        position: relative;
        top: -4px;
        cursor: pointer;
        vertical-align: middle;
        margin-right: 7px;
        font-size: 10px;
      }
    }
  }
}
