.search-panel-wrapper {
	$animation-time: 0.3s;
	$input-side-padding: 5px;

	padding: 1.5em 0;

	&__keyword {
		margin-bottom: 2rem;
		position: relative;

		&--input {
			padding: 1rem 1.25rem;
			border: 1px solid  $color-button-border-gray;
			background-color: $color-brand-white;
			width: 100%;
			box-sizing: border-box;
		}

		.ion-android-search {
			position: absolute;
			font-size: rem(40px);
			top: 0;
			right: 0;
			color: $color-brand-orange;
			box-sizing: border-box;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 1rem;
		}	
	}

	.search-panel {
		margin-bottom: 2rem;
		display: flex;
		align-items: center;

		&--label {
			margin-right: 1rem;
			font-size: rem(18px);
			flex: 0 0 auto;
		}
		&__dropdowns {
			display: flex;
			justify-content: space-between;
			width: 100%;
			.select-dropdown {
				width: 30%;
				margin: 0;
			}
			@include respond-to-max-width($bp-tablet) {
				display: block;
				.select-dropdown {
					margin-right: 1rem;
				}
			}
		}
	}


	.select-dropdown {
		min-width: 200px;
		margin-right: 1rem;
	}

	&__selectedfilters {
		display: flex;	
		align-items: center;

		&--label {
			margin-right: 1rem;
			font-size: rem(18px);
		}

		&--button {
			padding: rem(8px) rem(16px);
			border: 1px solid $color-brand-black;
			border-radius: rem(30px);
			display: flex;
			align-items: center;
			font-size: rem(16px);
			margin-right: 1rem;
			position: relative;
			padding-left: 1.75em;
			&:hover {
				border-color: $color-brand-orange;
			}

			&:before {
				left: 0.75em;
				content: $ionicon-var-android-close;
				font-family: $font-family-ionicons;
				position: absolute;
				color: $color-brand-orange;
			}
			&-clear {
				background-color: $color-input-background-gray;
				border: none;
				padding: rem(8px) rem(16px);
				&:before {
					display: none;
				}
				&:hover {
					background-color: $color-light-gray;
				}
			}
		}
	}
}
