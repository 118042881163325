.search-bar-wrapper {
	background-color: $color-med-light-gray;
	padding: 1.5em $indented-padding;

	&__btn {
		position: absolute;
	    font-size: em($font-search-icon);
	    padding: em(4px) em(12px);
	    color: $color-brand-orange;

	    &:before{
	        content:$ionicon-var-search;
	        font-family:$font-family-ionicons;
	        position: relative;		        
	    }
    }
	&__input {
		border-radius: em($font-search-box);
		width: 100%;
		background-color: $color-brand-white;
		color: $color-text-gray;
		font-size: em($font-search-box);
		padding: em(10px) em(20px) em(10px) em(50px);
	}

}