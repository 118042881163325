.careers-landing {
  .hero {
    box-sizing: border-box;
    min-height: rem(655px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: rem(38px);
    padding: calc($head-height-lg + rem(16px)) rem(40px) rem(16px);
    margin-bottom: rem(20px);

    @include screen($bp-desktop) {
      padding: calc($head-height-sm + rem(16px)) rem(16px) rem(16px);
      justify-content: flex-start;
      min-height: rem(633px);
    }

    @include screen($bp-mobile) {
      padding: rem(16px);
    }

    .breadcrumb {
      z-index: 1;

      .container {
        padding: 0px;
      }
    }
  }

  .hero-text-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: rem(30px);
    color: $color-brand-white;
    width: 100%;
    max-width: $site-max-width;
    min-width: 0px;

    @include screen($bp-desktop) {
      gap: rem(18px);
      margin-bottom: rem(50px);
    }

    .hero-title {
      @include font-soleil-regular(62px);
      font-weight: $font-weight-semibold;
      text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
      line-height: 110%;
      margin: 0px;
      max-width: rem(617px);
      transform: translateY(50px);
      opacity: 0;
      animation: 0.8s ease-out 0s 1 forwards fade-up;

      @include screen($bp-desktop) {
        @include font-soleil-regular(37px);
        font-weight: $font-weight-semibold;
        line-height: 123%;
      }
    }

    .hero-tagline {
      @include font-soleil-regular(26px);
      max-width: rem(515px);
      transform: translateY(50px);
      opacity: 0;
      animation: 0.6s ease-out 0.2s 1 forwards fade-up;

      @include screen($bp-desktop) {
        @include font-soleil-regular(20px);
      }
    }
  }

  @keyframes fade-up {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 36px);
    overflow: hidden;

    @include screen($bp-desktop) {
      height: calc(100% - 138px);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 66.18%,
          rgba(0, 0, 0, 0.8) 100%
        ),
        linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 66.46%);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__links-bar {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(16px);
    align-self: stretch;

    .link-bar {
      max-width: $site-max-width;
    }
    &--label {
      @include font-eyebrow-redesign;
      color: $color-brand-white;
      width: 100%;
      max-width: $site-max-width;
      padding-left: rem(16px);
    }
  }

  &-cta-link {
    @include orange-cta;
    align-self: flex-start;
    z-index: 1;
  }

  .flexible-content-component {
    padding: 0px;
    .rich-text {
      margin-bottom: 0px;
    }
  }
}
