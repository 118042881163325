.industries-listing {
    columns: 3 auto;
}

.industries-listing-item {
    break-inside: avoid;
    //overflow: hidden;
    text-align: center;
}

.industries-listing-industry {
    @include font-bookmania(22px);

    line-height: 1.4;
    color: $color-text-gray;
    display: inline-block;
    padding-bottom:$white-space-small;

    
}

a.industries-listing-industry {

    &:hover {
        color: $color-brand-green;
    }
}



@include screen($bp-tablet) {
   .industries-listing {
        columns: 2 auto;
    }
}

@include screen($bp-mobile-portrait) {
   .industries-listing {
        columns: auto;
    }
}