.interrupter-image {
	text-align: center;
	padding: $white-space-section 0;
	background-size: cover;
	background-position: center;
	
	.container {
		display: flex;
		align-items: center;
	}

	&--text {	
		width: 50%;		
		color: $color-brand-white;
		font-size: rem(30px);
		font-weight: $font-weight-semibold;
	}

	&--cta {		
		width: 50%;
		margin: 2rem 0;
		&__link {
			position: relative;
			padding: rem(10px) rem(25px);   
			background-color: $color-brand-orange;
			color: $color-brand-white;
			transition: all 0.2 ease-in-out;
			&:hover {
				background: $color-brand-white;
				color: $color-brand-orange;
			}
		}
	}
	@include screen($bp-tile-mobile) {
		padding: 2rem 0;
		.container {
			display: block;
		}
		&--text,
		&--cta {
			width: 100%;
		}
	}
}