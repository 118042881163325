.professional-detail {

    .flexible-content-component {
        margin-bottom: rem(60px);

        @include respond-to-max-width($bp-mid-mobile) {
            margin-bottom: rem(40px);
        }
    }

    .experience-area {
        .tile-item {
            &__image {
                display: none;
            }
        }
    }

    .insights-area {
        .tile-item {
            &__image {
                display: none;
            }
        }
    }

    @include respond-to-max-width($bp-mid-mobile) {
        padding-top: 50px;

        .jump-navigation {
            position: fixed;
            top: $head-height-sm;
            width: 100%;
            margin-bottom: 0;

            &__mobile-toggle {
                height: rem(50px);
            }
        }
    }
        
    &.pdf {
        padding-top: 1rem;

        .bio-section {
            display: inline-block;
            width: 100%;

            .pdf-bio-image {
                max-height: 300px;
                width: auto;
                padding-right: 5px;
                display: inline-block;
            }

            .bio-overview {
                display: inline-block;
                padding-left: 20px;
                vertical-align: top;

                .contact-info {
                    padding: 5px 0;
                }

                .name {
                    font-size: 24px;
                    margin-bottom: 5px;
                    white-space: nowrap;
                }
            }

            .quote {
                padding: 10px 0;
            }
        }

        .bio-q-and-a {
            padding: 15px;

            .section-title {
                margin-bottom: 10px;
            }

            .right {
                display: block;
                padding: 1.25rem 0 0 0;
                width: 100%;
            }
        }

        .related-section {
            margin-top: 2rem;
        }

        .credential {
            display: block;
            margin-top: 2rem;

            .rich-text {
                margin-bottom: 0;
            }
        }

        .involvement {
            .credential-subitem {
                margin-left: 30px;
            }
        }

        .related-topics {
            display: block;

            .topics {
                display: inline-block;

                .topic-pdf {
                    display: inline-block;

                    &:not(:last-child) {
                        &:after {
                            content: '|';
                            color: $color-light-gray;
                            padding: 0 rem(13px) 0 rem(10px);
                        }
                    }
                }
            }
        }

        .bio-q-and-a {
            margin: 0;

            .qa-wrapper {
                overflow: visible;
                height: auto;

                &:after {
                    content: none;
                }
            }

            .topics-area {
                margin: 0;
            }

            .practices-area {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .credential .title, .section-title {
            margin-top: 10px;
            font-size: 1rem;
            font-family: $font-family-bold;
            font-weight: $font-weight-semibold;
            color: $color-brand-orange;
        }

        .pdf-tile {
            .tile-base {
                margin: 0;
                width: 100%;

                .tile-container {
                    margin-top: 10px;
                    margin-bottom: 20px;
                    border: 0;

                    .tile-image {
                        display: none;
                    }
                }


                .tile-border-expand {
                    display: none;
                }

                .type {
                    padding: 0;
                }

                .title {
                    font-size: 18px;
                    padding: 0;
                }

                .abstract {
                    font-size: $font-base;
                    padding: 0;
                }

                .topics {
                    display: none;
                }

                .topic {
                    font-size: $font-base;
                }
            }
        }
    }
}

.main-content.professional-detail {
    @include screen($bp-tablet) {
      
    }
}
