$large-story-height: rem(250px);
$large-story-padding: rem(140px);;

.large-story-header {
    @include bg-cover;

    background-color: $color-brand-black;
    padding-top: 6rem;
    color: $color-text-gray;
    position: relative;
    overflow: hidden;

    @include screen($bp-mobile) {
        padding-top: 3rem;
    }

    & + & {
        margin-top: rem(55px);
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color:rgba(49,49,49,0.25);
    }
}

.large-story-panels,
.large-story-title {
    position: relative;
    z-index: 2;
}

.large-story-title {
    @include font-soleil-bold(50px);

    color: $color-brand-white;
    line-height: 1.2;
    margin: 0;
    max-width: 80%;
    min-height: $story-height;
}


.large-story-related-topics {
    font-size: rem(20px);
    color: $color-brand-white;
    position: relative;
    z-index: 2;
    margin-bottom: $large-story-padding * .8;
}

.large-story-related-topic {
    @include font-soleil-bold(20px);
    color: $color-brand-white;
    line-height: 1.2;

    &:hover{
        color: $color-text-gray;
    }
}

$timeline-offset:  rem(90px);

.large-story-panels {
    padding-top: $white-space-small;
    position: relative;
    overflow: hidden;
    border-bottom: 3px solid $color-text-gray;


    .large-story-panel-heading {
        text-align: right;

        > * {
            padding-right: $timeline-offset;
            padding-left: $timeline-offset;
        }
    }

    .large-story-panel-copy {
        padding-left: $timeline-offset;
        padding-right: 5%;
    }

    .large-story-panel {

        &:nth-child(2n) {

            .large-story-panel-heading {
                text-align: left;
                padding-right: 0;
                float: right;

                > * {
                    padding-left: $timeline-offset;
                }
            }

            .large-story-panel-copy {
                float: left;
                padding-left: 5%;
                padding-right: $timeline-offset;
            }
        }
    }
}

.large-story-timeline {
    @include center(horizontal);

    top: 0;
    width: rem(1px);
    height: 100%;
    background-color: $color-text-gray;
    z-index: 2;
}

.large-story-timeline-circle {
    $circle-radius: 2em;

    @include size($circle-radius);
    @include center(horizontal);
    @include center(transition);

    top: $white-space-base + $white-space-small;
    border-radius: $circle-radius;
    background-color: $color-border-gray;
    z-index: 3;

    &.is-fixed-timeline {
        position: fixed;
        top: 50%;
        margin-top: $head-height-collapsed -  $white-space-base;

    }

    &.is-timeline-bottom {
        position: absolute;
        bottom: rem(100px);
        margin-top: 0;
        top: auto;
    }

    &.is-scrolled-to {
         background-color: $color-brand-green;
    }
}


@include screen($bp-tablet) {
    $timeline-offset:  rem(50px);

    .large-story-panels {

        .large-story-panel-heading {
             padding-right: $timeline-offset;
        }

        .large-story-panel-copy {
            padding-left: $timeline-offset;
        }

        .large-story-panel {

            &:nth-child(2n) {

                .large-story-panel-heading {
                    > * {
                        padding-left: $timeline-offset;
                    }
                }

                .large-story-panel-copy {
                    padding-right: $timeline-offset;
                }
            }
        }
    }

    .large-story-timeline-circle {
        $circle-radius: 1.6em;

        @include size($circle-radius);


        border-radius: $circle-radius;
    }

}

@include screen($bp-phablet) {
    $timeline-offset: rem(30px);

    .large-story-timeline {
        left: $timeline-offset;
        transform: translateX(0);
    }

    .large-story-timeline-circle {
        left: $timeline-offset;
        margin-left: rem(2px);
    }

    .large-story-panels {

        .large-story-panel-heading {
            text-align: left;
            padding-right: 0;
            float: none;
            width: 100%;
        }

        .large-story-panel-copy {
            padding-left: 0;
            float: none;
            width: 100%;
            margin-top: $white-space-small;
        }

        .large-story-panel {

            padding-left: $timeline-offset * 2;
            padding-right: $timeline-offset * 2;

            &:nth-child(2n) {

                .large-story-panel-heading {
                    padding-left:0;
                }

                .large-story-panel-copy {
                    padding-left: 0;
                    padding-right:0;
                }
            }
        }
    }
}