$flip-card-transition: all 0.3s ease-in-out;

.flip-cards {
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  &__flex-container {
    max-width: rem(926px);
    width: 100%;
    margin: rem(40px) rem(16px);

    @include screen($bp-desktop) {
      margin: rem(27px) rem(16px);
      max-width: $site-mobile-max-width;
    }
  }

  &__plus-icon,
  &__minus-icon {
    @include screen($bp-desktop) {
      height: rem(47px);
      width: rem(47px);
    }
  }

  &__headline {
    @include font-h2-redesign;
    margin-top: 0px;
    margin-bottom: rem(30px);
  }

  &__body {
    @include font-body-redesign;
    display: block;
    margin-bottom: rem(36px);
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(17px);
    height: rem(626px);

    @include screen($bp-desktop) {
      --isMobile: true;
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  }

  &__list-item {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    height: rem(521px);
    min-width: 0px;
    max-width: rem(453px);

    @include screen($bp-desktop, min) {
      &:first-of-type {
        align-self: flex-start;
      }

      &:last-of-type {
        align-self: flex-end;
      }
    }
  }

  &__inner-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    transition: transform 0.5s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    box-shadow: 0px 4px 11.4px 0px rgba(0, 0, 0, 0.1);

    .flipped & {
      transform: rotateY(180deg);
    }
  }

  &__button {
    height: 100%;
    width: 100%;
    padding: 0;
    position: relative;
    color: $color-brand-black;

    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: 1px solid $color-brand-orange;
    }

    svg {
      position: absolute;
      top: rem(35px);
      right: rem(35px);
      z-index: 1;

      @include screen($bp-desktop) {
        top: rem(30px);
        right: rem(30px);
      }
    }

    circle,
    path {
      transition: $flip-card-transition;
    }

    &:hover {
      text-decoration: none;

      img {
        transform: scale(1.3);
      }

      .flip-cards__plus-icon circle {
        fill: $color-brand-orange;
      }
      .flip-cards__plus-icon path {
        stroke: $color-brand-white;
      }
    }
  }
  &__button-text {
    color: $color-brand-white;
  }

  &__button-text,
  &__title {
    @include font-h3;
    position: relative;
    z-index: 1;
    text-align: left;
  }

  &__back {
    transform: rotateY(180deg);
    justify-content: space-between;
    flex-direction: column-reverse;
    background-color: $color-brand-white;
  }

  &__front {
    justify-content: flex-end;
    flex-direction: column;
  }

  &__front,
  &__back {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: rem(65px) rem(43px) rem(43px);
    overflow: hidden;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    display: flex;
    align-items: flex-start;

    @include screen($bp-desktop) {
      padding: rem(65px) rem(36px) rem(36px);
    }
  }

  &__image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: $flip-card-transition;
  }

  &____button-text {
    position: relative;
    z-index: 1;
  }
  &__title {
    margin: 0px;
    color: $color-brand-black;
  }
  &__content {
    @include font-body-redesign;
    max-width: rem(280px);
    text-align: left;
    color: $color-brand-black;
    
    @include screen($bp-desktop) {
      max-width: rem(220px);
    }
  }
}
