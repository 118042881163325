//header
$head-height-lg: rem(96px);
$head-height-collapsed: rem(69px);
$head-collapsed-padding: rem(28px);
$head-height-sm: rem(70px);

$submenu-height-sm: rem(50px);

$site-max-width: rem(1140px);
$site-mobile-max-width: rem(357px);
$site-redesign-content-width: rem(922px);
$section-spacing: 20px;
$one-third-width: calc(100% / 3);
$inner-one-third-width: calc(100% / 3);
$two-thirds-width: 66%;
$half-width: 50%;
$full-width: 100%;

$tile-grid-one-third: calc(100% / 3 - 2rem);
$tile-grid-one-third-gutter: 1rem;

$tile-grid-half: calc(50% - 2rem);
$tile-grid-half-gutter: 1rem;

$wrapper-subpadding: 1.5rem;

$indented-padding: rem(48px);
$indented-padding-desktop: rem(16px);

$left-right-spacing: rem(50px);
$left-right-spacing-tablet: rem(16px);

$section-title-spacing: 0 rem(20px) rem(30px);
$text-line-height: 1.5em;

$border-base: 1px solid $color-border-gray;
$border-section: 3px solid $color-border-gray;

$white-space-section: rem(80px);
$white-space-base: rem(50px);
$white-space-half: rem(36px);
$white-space-small: rem(20px);
$top-bottom-spacing: rem(36px);

@mixin base-wrapper {
  margin-left: $left-right-spacing;
  margin-right: $left-right-spacing;

  @include respond-to-max-width($bp-bio-overview-scale) {
    margin-left: $left-right-spacing-tablet;
    margin-right: $left-right-spacing-tablet;
  }
}

.base-wrapper {
  @include base-wrapper;
}
