.blog-detail-hero {
    margin-bottom: 60px;

    &__title {
        @include font-h3();
        margin-bottom: rem(8px);
    }
    &__brief {
        @include font-h7-body-1();
    }

    &__authors {
        font-weight: $font-weight-bold;
    }

    .breadcrumb {
        margin-bottom: rem(24px);
        max-width: none;
        .container {
            padding: 0;
        }

        .nav-menu-item {
            color: $color-brand-black;

            &:not(:first-of-type):before {
                content: '/ ';
                color: $color-header-gray;
            }

            a {
                font-weight: $font-weight-semibold;
                padding: 0 1px;
            
                &:hover {
                    color: $color-bilzin-orange;
                }
            }

            &:last-of-type {
                color: $color-brand-orange;
            }
        }
    }

    &__meta {
        margin-bottom: rem(20px);
        font-size: rem(12px);
        text-transform: uppercase;

        > li, > span {
            padding-right: 0.5em;
            display: inline;
            &:not(:first-child) {
                &:before {
                padding-right: 0.5em;
                content: '|';
                }
            }
        }
    }

    &__image {
        width: 100%;
        margin-top: rem(24px);
    }

    @include screen($bp-phablet) {
        margin-bottom: rem(36px);


        &__main {
            min-height: 250px;
            margin-bottom: 32px;
        }

        &--nav-padded {
            margin-top: $head-height-sm;
        }

        &__title {
            font-size: 40px;
        }
    }
}
