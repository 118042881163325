.link-bar {
  width: 100%;
  .link-bar-list {
    display: flex;

    @include screen($bp-desktop) {
      flex-direction: column;
    }
  }

  .link-bar-item {
    flex: 1 1;
    border: 1px solid $color-med-light-gray;
    border-collapse: collapse;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link-bar-link {
    box-sizing: border-box;
    @include font-soleil-semibold(14px);
    padding: rem(32px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-transform: uppercase;
    background-color: $color-brand-white;
    color: $color-brand-orange;
    text-align: center;
    transition: all 0.3s ease-in-out;
    outline-offset: -8px;
    text-align: left;

    @include screen($bp-desktop) {
      justify-content: flex-start;
      padding: rem(20px);
    }

    &:hover {
      text-decoration: none;
      color: $color-brand-white;
      background-color: $color-brand-orange;
    }
  }

  .link-bar-text {
    text-align: left;
  }
}