.blog-landing-hero {
    margin-bottom: 55px;

    &__title {
        @include font-soleil-bold(50px);
        line-height: 1;
    }
    &__brief {
        @include font-h7-body-1();
    }

    .breadcrumb,
    &__content {
        position: relative;
        z-index: 2;
    }

    .breadcrumb--white {
        .container {
            padding: 0;
            max-width: none;
        }
        .nav-menu-item {
            &:last-of-type {
                color: $color-brand-white;
            }
        }
    }

    &__main {
        @include bg-cover();
        padding: 25px;
        display: flex;
        flex-direction: column;
        min-height: 300px;
        justify-content: space-between;
        color: $color-brand-white;
        position: relative;
        background-color: $color-brand-black;
        box-sizing: border-box;
        margin-bottom: 46px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-opaque-dark
        }
    }

    &__content {
        width: 100%;
        margin-top: 56px;
    }

    &__lead {
        @include font-h7-body-1();
    }

    @include screen($bp-tablet) {
        &__lead {
            padding: 0 1rem;
        }
    }
    
    @include screen($bp-phablet) {
        margin-bottom: 32px;

        &__main {
            min-height: 250px;
            margin-bottom: 32px;
        }

        &--nav-padded {
            margin-top: $head-height-sm;
        }

        &__title {
            font-size: 40px;
        }
    }
}
