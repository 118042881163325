﻿.general-content {
	$overview-column-width: rem(190px);

	@include respond-to-min-width($bp-mid-mobile) {
		padding-top: 5rem;
	}

	.detail-overview-header {
		padding: $white-space-base $indented-padding;

		@include screen($bp-phablet) {
			padding-bottom: 0;
		}

		&:before {
			content: none;
		}

		.detail-overview-title {
			color: $color-brand-black;
			text-align: center;
		}
	}

	$subpage-dropdown-width: 20rem;

	.select-dropdown {
		min-width: $subpage-dropdown-width ;
		float: right;
		margin-top: -0.9em;
	}

	.detail-overview-content {
		padding-top: 0;
		//padding-top: $white-space-base;

		.rich-text-area {
			width: 100%;
			.section-title {
				margin-left: $overview-column-width;
				//break text before dropdown
				padding-right: $subpage-dropdown-width * 1.1;

				@include respond-to-max-width($bp-mobile-portrait) {
					padding-right: 0;
				}
			}

			.video-container {
				position: relative;
				padding-bottom: 56.25%;
				height: 0;
				width: 100%;
				overflow: visible;
			}

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				border: 0;
				width: 100%;
				height: 100%;
			}

			.rich-text {
				padding-top: $white-space-base;
			}

			@include respond-to-max-width($bp-phablet) {
				.section-title {
					margin-left: 0;
					margin-top: 2.5rem;
				}
			}
		}

		@include respond-to-max-width($bp-phablet) {
			.detail-overview-social {
				margin-bottom: 0;

				.js-expand-section {
					display: inline-block;
				}
			}
		}
	}

	&.componentized, &.basic {
		.rich-text-area {
			margin: 0.5em 0 0;
			@include respond-to-max-width($bp-phablet) {
				margin-left: $overview-column-width;
			}

			&:not(:last-child) {
				margin-bottom: $white-space-base;
			}

			.section-title {
				margin-bottom: 0.5em;
				margin-left: 0;
			}

			.rich-text {
				margin-left: 0;
			}
		}

		@include respond-to-max-width($bp-phablet) {
			.rich-text-area {
				margin-left: 0;
			}

			.language-toggle {
				margin-bottom: 0.5rem;
			}

			.career-section-nav li {
				margin-bottom: 0.5rem;
				text-align: center;
			}
		}

		@include respond-to-max-width($bp-mobile-portrait) {
			.detail-overview-header {
				padding: $wrapper-subpadding;
				padding-bottom: $wrapper-subpadding;
			}
		}
	}

	&.error-page {
		padding: 6rem 0 3.125rem 0;

		.rich-text-area {
			.section-title {
				margin-left: 0;
				@include respond-to-max-width($bp-mobile-portrait) {
					line-height: 2.5rem;
				}
			}

			.rich-text {
				margin-left: 0;
			}
		}
	}

	.toggle-wrapper{
		text-align: center;
	}
}
