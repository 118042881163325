﻿.language-toggle {
    text-align: center;

	a {
		cursor: pointer;

		&.is-active {
			color: $color-brand-orange;
		}
	}
}
