.pull-quote {
	text-align: center;
	position: relative;
	padding: $white-space-base;
	margin: $white-space-base/2 0 $white-space-base 0;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 75px;
		width: 75px;
		border-left: 3px solid $color-brand-orange;
		border-top: 3px solid $color-brand-orange;
	}
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		height: 75px;
		width: 75px;
		border-right: 3px solid $color-brand-orange;
		border-bottom: 3px solid $color-brand-orange;
	}

	&--text {		
		font-size: rem(40px);
		font-weight: $font-weight-semibold;
	}
	@include screen($bp-tile-mobile) {
		&:before,
		&:after {
			height: 40px;
			width: 40px;
		}
	}
}