$jump-menu-height: rem(70px);
$jump-menu-height-mobile: rem(60px);


.jump-menu {
	
	height: $jump-menu-height;
	background-color: $color-med-light-gray;
	position: fixed;
	width: 100%;
	z-index: $z-index-jumpmenu;
	max-width: $site-max-width;
	transform: translateZ(0);
	transition: transform $transition-timing linear,
				height $transition-timing linear;

	& + .practice-grouping {
		padding-top: $jump-menu-height;
	}
}

//collapsed header
.is-collapsed {
	& ~ .main-content {
		$menuOffset:  $head-height-lg - $head-height-collapsed;

		.jump-menu {
			height: $head-height-collapsed;
			transform: translateY( $menuOffset * -1) translateZ(0);
		}
	}
}

.jump-menu-content {
	padding: 0 $indented-padding;
	
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	
}
.jump-menu-list {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;

}


.jump-menu-label {
	font-size: 18px;
	text-align: center;
	display: inline-block;
	width: 15%;
	min-width: rem(100px);
	flex-shrink: 0;
}

.jump-menu-link {
	@include font-soleil-bold(22px);

	line-height: 1.2;
	color:$color-text-gray;
	display: inline-block;
	position: relative;
	text-align: center;

	&:after {
		@include transition(width);
		@include center(horizontal);

		content: '';
		display: block;
		top: 125%;
		border-bottom: 3px solid $color-text-gray;
		width: 0;

	}

	&:hover,
	&.is-selected {
		color: $color-brand-green;
		&:after {
			width: 100%;
			border-color:  $color-brand-green;
		}
	}
}

@include screen($bp-desktop) {
    .jump-menu-content {
        padding-left: $indented-padding-desktop;
        padding-right: $indented-padding-desktop;
    }
}

@include screen($bp-tablet) { 
	.jump-menu-link {
		font-size: rem(18px);
	}
}

@include screen($bp-mid-mobile) { 

	.jump-menu {
		height: $jump-menu-height-mobile;

		& + .practice-grouping {
			padding-top: $jump-menu-height-mobile;
		}
	}

	//collapsed header
	.is-collapsed {
		& ~ .main-content {
			$menuOffset:  $head-height-sm;;

			.jump-menu {
				height: $jump-menu-height-mobile;
				transform: translateZ(0);
			}
		}
	}

	.jump-menu-content { 
		display: block;
	}

	.jump-menu-label {
		text-align: left;
	}
	.jump-menu-link,
	.jump-menu-label {
		font-size: rem(14px);
	}
}
