.practices-area {
  padding: rem(60px) 0;
  background-color: $color-input-background-gray;
  

  &--with-background {
    background-color: $color-header-gray;
    color: $color-brand-white;
    position: relative;

    &:before {
      content: '';
      display: block;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-opaque-dark;
      z-index: 2;
    }
  }

  &__background {
    position: absolute;
    background: $color-header-gray no-repeat center;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.7;
  }

  .container {
    position: relative;
    z-index: 3;
  }

  @include respond-to-max-width($bp-mid-mobile) {
    padding: rem(40px) 0;
  }
  

  .pdf & {
    background-color: transparent;
    color: $color-black;
    .container {
      max-width: none;
      padding: 0;
    }
    .practice {
      font-size: 12px;
      width: 100%;
    }
    .practices {
      display: block;
    }
  }
  .section-title {
    margin-bottom: 1rem;
  }
  .tile-item:nth-child(n+4) {
    border-top: 3px solid $color-light-gray;
    .tile-item__image {
        display: none;
    }
  }

}
