.feature-card {
  display: flex;
  cursor: pointer;
  color: $color-black;
  margin-bottom: 1rem;
  transition: all $transition-ease $transition-timing;

  @include respond-to-max-width($bp-tablet) {
    flex-direction: column;
  }
  &:hover {
    color: $color-black;
    box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.05);
    background-color: $color-brand-white;
    text-decoration: none;
    .feature-card__data {
      &__title {
        color: $color-brand-orange;
        text-decoration: underline;
      }
      &__links__cta,
      &__links__podcast {
        color: $color-brand-orange;
      }
    }
  }

  &__image {
    border-left: 10px solid $color-brand-orange;
    background-size: cover;
    background-position: center;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-top: 25%;
    @include respond-to-max-width($bp-tablet) {
      width: 100%;
      padding-top: 50%;
    }

    &--overlay {
      padding: 2rem;
      color: $color-brand-white;
      background-color: $color-black;

      &__title{
        font-size: 1.4rem;
        padding-bottom: 0.5rem;
      }
      &__link {
        &:not(:last-child) {
          &:after {
            content: ',';
          }
        }      
      }
    }
  }

  &__data {
    padding: 2rem 3rem;
    width: 50%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      90deg,
      rgba(196, 196, 196, 0.1) 0%,
      rgba(235, 235, 235, 0.1) 56.3%,
      rgba(255, 255, 255, 0.1) 100%
    );
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: $color-brand-white;

    }
    @include respond-to-max-width($bp-tablet) {
      padding: 1.5rem;
      width: 100%;
    }

    &__meta-data {
      margin-bottom: 1rem;
      display: flex;

      li {
        padding-right: 0.5em;
        &:not(:first-child) {
          &:before {
            padding-right: 0.5em;
            content: '|';
          }
        }
      }
    }

    &__title {
      font-size: 2rem;
      line-height: 1.1em;
      font-weight: $font-weight-bold;
      margin-bottom: 1rem;
      transition: color 0.2s ease-in-out;
    }

    &__source {
      margin-bottom: 1rem;
    }

    &__abstract {
      font-size: 1.1rem;
      font-weight: $font-weight-light;
      line-height: 1.5em;
      margin-bottom: 1rem;
    }

    &__links {
      display: flex;
      margin-top: auto;
      justify-content: space-between;

      &__cta {
        font-weight: $font-weight-bold;
        transition: color 0.2s ease-in-out;

        &:after {
          color: $color-brand-orange;
          content: $ionicon-var-ios-arrow-right;
          font-family: $font-family-ionicons;
          position: relative;
          vertical-align: middle;
          font-weight: $font-weight-bold;
          padding-left: 1em;
        }
      }

      &__podcast {
        font-weight: $font-weight-bold;
        transition: color 0.2s ease-in-out;

        &:after {
          color: $color-brand-orange;
          content: $ionicon-var-android-microphone;
          font-family: $font-family-ionicons;
          position: relative;
          vertical-align: middle;
          font-weight: $font-weight-bold;
          padding-left: 1em;
        }
      }
    }

    &__stats {
      padding: 1em 0;
      &--header {
        font-size: 1rem;
        font-weight: $font-weight-bold;
        color: $color-brand-orange;
        padding-bottom: 0.5em;
        text-transform: uppercase;
      }
      &--items {
        display: flex;
        justify-content: space-between;
      }

      &--item {        
        width: 30%;

        &_title {          
          padding-bottom: 0.25em;
          font-size: 1.2rem;
          font-weight: $font-weight-semibold;
        }
        &_text {
        
        }
      }
    }    
  }
  @include respond-to-min-width($bp-mid-mobile) {
    &--casestudy {
      flex-direction: row-reverse;
      .feature-card__image {
        border: none;
      }
    }
  }

}
