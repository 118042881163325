$featured-height: rem(400px);
$featured-cta-padding: rem(140px);;

.featured-article {
    @include bg-cover;
    background-color: $color-brand-black;
    min-height: $featured-height;
    margin-bottom: rem(55px);
    position: relative;
    overflow: hidden;
    display: flex;
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60%;
        z-index: 5;

        @include gradient( rgba(0,0,0,.45), rgba(45,53,57, 0), 0deg, 100% ) ; 
    }
    &:hover {
        .featured-article-info {
            background: $color-med-light-gray;
        }
    }
    &-info {
        background-color: $color-brand-white;
        padding: 2rem;
        position: relative;
        z-index: 20;
        width: 50%;
        transition: all .5s;
    }

}

.featured-article-banner {
    @include center(both);

    min-width: 100%;
    min-height: 100%;
    z-index: 1;
    transform: scale(1.0, 1.0) translate(-50%, -50%);
    transition: transform .5s;
    transform-origin: 0;

    .is-zoomed &{
        transform: scale(1.05, 1.05) translate(-50%, -50%);
    }
}



.featured-article-type {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    transform: rotate(-90deg);
    left: rem(-36px);
    z-index: 10;
    position: absolute;
    margin-top: rem(30px);
    width: 10%;
}

.featured-article-title-wrapper {
	.date, .subtitle {
		padding-left: rem(65px);
	}
}

.featured-article-title {
    cursor: pointer;
    &:hover {
        color: $color-brand-orange;
    }
}

.featured-article-heading {
    font-size: rem(38px);
    line-height: 1.2;
    margin: 0 0 1rem 0;
}


.featured-article-abstract {
}

.featured-article-cta {
    @include center(horizontal);
    bottom: rem(25px);
    z-index: 15;
    color: $color-brand-white;
    text-transform: uppercase;
    font-size: rem(18px);
    line-height: 1.1;
    text-align: center;


    &:after {
        display: block;
        content:$ionicon-var-ios-arrow-down;
        font-family:$font-family-ionicons;
        margin: rem(5px) auto 0;
        font-size: rem(45px);

    }
}
@include respond-to-max-width($bp-bio-image-scale) {
    .featured-article-type {
        width: 150px;
        left: rem(-32px);
        margin-top: rem(32px);
    }
}

@include respond-to-max-width($bp-desktop) {

    .featured-article-type {
        margin-top: rem(74px);
    }

    .featured-article {
        padding: rem(48px) rem(16px) $featured-cta-padding;
    }
}


@include respond-to-max-width($bp-phablet) {

    .featured-article-info {
        display: block;
    }


    .featured-article-heading {
        font-size: rem(28px);
    }

    .featured-article-abstract {
        width: auto;
        border-left: 0;
        margin-top: rem(15px);
    }
}