.topics-area {
    margin: 0 $left-right-spacing $top-bottom-spacing;
    padding-top: rem(50px);

    @include respond-to-max-width($bp-bio-overview-scale) {
        margin: 0 $left-right-spacing-tablet $top-bottom-spacing;
    }

    .section-title {
        width: calc(96% / 3);
        margin-right: calc(6% / 3);
        display: inline-block;
        border-top: 2px solid $color-light-gray;
        vertical-align: top;

        @include respond-to-max-width($bp-mid-mobile) {
            width: 100%;
        }

        .purple {
            display: block;
            padding: rem($section-spacing);
            color: $color-dark-purple;
        }
    }

    .related-topics {
        width: calc(96% - (92%/ 3));
        display: inline-block;
        border-top: 2px solid $color-light-gray;

        @include respond-to-max-width($bp-mid-mobile) {
            width: 100%;
        }

        .topics {
            padding: rem($section-spacing);
            padding-top: rem(29px);
        }
    }

    &--border {
        border-top: 3px solid $color-border-gray;
    }
}
