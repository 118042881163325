.show-overlay .nav-bar .logo img {
    opacity: 0;
    transition: opacity 3s ease-in-out 3s, visibility 3s ease-in-out 3s;

    @include screen($bp-phablet) {
        opacity: 1;
    }
}

.nav-bar {
    $icon-spacing: 30px;
    @include clearfix();
    display: block;
    min-height: $nav-height;
    padding: rem($home-padding);

    .logo {
        display: inline-block;
        width: 50%;
        float: left;

        > a {
            display: inline-block;
        }

        img {
            opacity: 1;
            width: 100%;
        }
    }

    .nav-container {
        @include clearfix();
    }

    .primary-nav {
        color: $color-brand-white;
        display: inline-block;
        width: 40%;
        text-align: right;
        float: right;

        .search {
            cursor: pointer;
            font-size: rem(40px);

            user-select: none;
            line-height: 1;

            &:before {
                content: $ionicon-var-ios-search;
                font-family: $font-family-ionicons;
                position: relative;
                vertical-align: middle;
            }
        }
    }

    .menu-icon {
        display: inline-block;
        width: 36px;
        margin-left: $icon-spacing;
        position: relative;
        top: -2px;
        cursor: pointer;
        vertical-align: middle;

        .menu-btn {
            font-size: rem(12px);
            text-transform: uppercase;

            &::before {
                background-color: $color-brand-white;
                content: '';
                box-shadow: 0 10px 0 #fff;
                margin-bottom: 16px;
                display: block;
                height: 2px;
                transition: all 200ms ease-in-out;
            }

            &::after {
                position: relative;
                display: inline-block;
                content: 'menu';
                font-weight: $font-weight-bold;
                top: -3px;
                text-align: center;
            }
        }
    }

    $animation-time: 0.3s;
    $input-side-padding: 40px;

	.search-input-wrapper {
		.search-input {
			.ion-search {
				display: none;
			}
		}
	}

    .search-input {
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        width: 100%;
        transition: visibility $animation-time ease-in-out, opacity $animation-time ease-in-out, max-height $animation-time ease-in-out;
        overflow: auto;
        z-index: 1;

        input {
            display: block;
            font-size: rem(18px);
            width: calc(100% - #{$input-side-padding}*2);
            padding: 12px $input-side-padding;
        }
    }

    &.show-search {
        .search {
            &:before {
                content: $ionicon-var-android-close;
            }
        }

        .search-input {
            visibility: visible;
            opacity: 1;
            max-height: 80px;
            /*height: 80px;*/
			height: auto;
            position: relative;

            i {
                position: absolute;
                font-size: rem(28px);
                color: $color-input-gray;
                right: 14px;
                top: 10px;
                display: none;

				&.ion-search {
					display: inline-block;
					cursor: pointer;
				}
            }

            &.has-input {
                i {
                    display: inline-block;
                }
            }
        }

        .breadcrumb {
            display: none;
        }
    }

    @include screen($bp-phablet) {
        padding: $home-padding-mobile;


        .primary-nav {
            .search {
                font-size: rem(30px);
                position: relative;
                //top:-10px;
            }

            .menu-icon {
                font-size: rem(30px);
                width: 30px;
                margin-left: 20px;
                top: 7px;

                .menu-btn {
                    font-size: rem(10px);

                    &::before {
                        box-shadow: 0 9px 0 #fff;
                        margin-bottom: 0;
                    }

                    &::after {
                        top: -10px;
                    }
                }
            }
        }
    }
}
