.practices-landing {
  .hero-intro-bg {
    position: relative;
    padding-bottom: 0;
  }
}

.practice-sections {
  &__header {
      font-size: 3rem;
      line-height: 1.2em;
      font-weight: $font-weight-semibold;
      margin-bottom: 2rem;    
  }

  &__container {
    &--section {
      display: flex;
      flex-direction: row;
      margin-bottom: 4rem;
      padding-bottom: 4rem;
      border-bottom: 1px solid $color-light-gray;
      @include respond-to-max-width($bp-tablet) {
        display: block;
      }

      &__header {
          width: 26%;
          font-size: 1.5rem;
          line-height: 1.2em;
          font-weight: $font-weight-semibold;
          padding-right: 2rem;
          margin:0;
          &:focus {
            outline: none;
          }
          @include respond-to-max-width($bp-tablet) {
            width: 100%;
            padding: 0;
            margin-bottom: 2rem;
          }
      }    

      &__list {
        flex: 2;
        column-count: 2;
        column-width: auto;

        &__parent {
          padding-right: 2rem;
          margin-bottom: 1rem;
          break-inside: avoid-column;
        }

        &__sublist {
          margin: 0.5rem 0 0 1rem;
          
          &--item {
            padding-bottom: 0.5rem;
          }
        }
      } 
    }    
  }

  &__industries {
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;

    &__list {
      flex: 2;
      column-count: 3;
      column-width: auto;
      @include respond-to-max-width($bp-tablet) {
        column-count: 2;
      }
      @include respond-to-max-width($bp-mobile) {
        column-count: 1;
      }

      &--item {
        margin: 0 2rem 2rem 0;
        &:after {
          content: $ionicon-var-ios-arrow-right;
          font-family: $font-family-ionicons;
          vertical-align: middle;
          font-weight: $font-weight-bold;
          float: right;          
        }

        &:hover {
          color: $color-brand-orange;

          &:after {
            color: $color-brand-orange;
          }
        }
      }
    }     
  }
}
