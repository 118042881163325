.alpha-search {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: $white-space-half;
}

.alpha-search-list {
	display: flex;
	flex-grow: 1;
	flex-wrap: nowrap;
}

.alpha-search-item {
	flex-grow: 1;
}

.alpha-search-btn {
	@include font-soleil-bold(24px);

	line-height: 1.2;

	&.inactive {
		color: $color-text-gray;
		cursor: default;
	}
}

.alpha-search-all {
	@include font-soleil-bold(18px);	
	text-transform: uppercase;
}

.alpha-search-btn,
.alpha-search-all {
	cursor: pointer;
	transition: color 0.2s ease-in-out;
	color: $color-brand-orange;

	&:hover {
		color: $color-text-gray;
	}
}

.is-disabled {
	opacity: .5;
	color: $color-text-gray;
}

.is-selected {
	color: $color-text-gray;

	&:hover {
		color: $color-text-gray;
	}
}

@include screen($bp-desktop) {
	.alpha-search {
		display: block;
		text-align: center;
	}

	.alpha-search-all {
		margin-top: $white-space-small;
		display: inline-block;
	}
}

@include screen($bp-tablet) {
	.alpha-search-list {
		flex-wrap: wrap;
	}

	.alpha-search-item {
		flex-grow: 0;
		width: (100/13) * 1%;
		margin-bottom:  $white-space-small;
	}
}