$pg-abstract-padding: rem(45px);

.practice-grouping {
    @include base-wrapper;

    padding: $white-space-base 0;

    .hero-intro-bg + & {
        padding-top: $landing-img-ratio - 15%;
    }
}

.practice-grouping-header-toggle {
    position: relative;
}

.practice-grouping-header {
    background-color: $color-brand-white;
    position: relative;
    margin: .75em 0;
}

.practice-grouping-toggle {
    position: absolute;
    top: 40%;
    right: 1em;
    transform: translate3d(0, -50%, 0);
}

.practice-grouping-toggle-prompt {
    text-transform: uppercase;
    font-size: .75em;
    display: block;
    margin-bottom: .5em;
    color: $color-brand-orange;
    font-weight: bold;
}

.practice-grouping-toggle-btn {
    $size: 2em;
    $transition-duration: .4s;
    $circle-size: $size * .9;
    
    width: $size * 2;
    height: $size;
    border-radius: $size;
    border: 1px solid $color-light-gray;
    background-color: $color-light-gray;
    position: relative;
    vertical-align: middle;
    transition: border-color $transition-duration, background-color $transition-duration;

    &:after{

        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        width: $circle-size;
        height: $circle-size;
        border-radius: $size;
        background-color: $color-brand-white;
        transform: translate3d(0, -50%, 0);
        transition: left .4s;
    }

    &.expand-accordions {
        background-color: $color-brand-orange;
        border-color: $color-brand-orange;

        &:after {
          left: calc(100% - #{$circle-size});
          
        }
    }

}

.practice-grouping-listing {
    margin-bottom: 2rem;
}

.practice-group {
    position: relative;
    border-bottom: 1px solid $color-med-light-gray;
    padding: 1rem 3rem 1rem 1rem;
}


.practice-group-overview  {
    align-items: center;

    .overview-heading {
        @include font-soleil-bold(22px);
        margin:0 0 5px 0;
        cursor: pointer;
        @include transition(color);
        &:hover {
            color: $color-brand-orange;
        }

        &:before{
            content:$ionicon-var-ios-plus-empty;
            font-family:$font-family-ionicons;
            position: absolute;
            right: 0;
            font-size: 1.2em;

            @include transition(transform);
        }

        .is-expanded & {
            color: $color-text-gray;

            &:before {
                transform: rotate(45deg);
            }
        }
    }

    .overview-abstract {
    }


}

.practice-group-listing {
    display: none;

    margin:1rem 0;
    background-color: $color-brand-white;
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 100%;
        top:0;
        left: 0;
        border-top: 1px solid $color-brand-white;
        position: absolute;
    }
}

.practice-group-list {
    margin-left: calc(96% / 3);
    padding:$white-space-small 0 $white-space-small $pg-abstract-padding;
}

.practice-group-list-option {
    max-width: rem(850px);
    padding: .75rem 0;
}

.practice-group-list-link,
.practice-group-sublist-link {
    color: $color-brand-green;

    &:hover {
        color: $color-text-gray;
    }
}


.practice-group-list-link {
    @include font-soleil-bold(20px);
    line-height: 1.3;
    display: inline-block;

}

.practice-group-sublist {
    padding-left: rem(25px);
    padding-top:rem(10px);
}

.practice-group-sublist-link {
    font-size: rem(18px);
    line-height: 1;
    display: inline-block;

}

@include screen($bp-desktop) {

    .practice-group-overview {
        display: block;

        .overview-abstract {
            width: auto;
            border-left: 0 none;
            padding-left: 0;
            font-size: rem(20px);

        }
    }

    .practice-group-list {
        margin: 0 auto;
        padding-left: 0;
    }

    .practice-group-list-option {
        max-width: rem(450px);
        margin: 0 auto;
    }
}

@include screen($bp-tablet) {


}

@include screen($bp-mobile-portrait) {

}

@include screen(550px){
    .practice-grouping-toggle {
        text-align: left;
        position: static;
        transform: none;
        margin-bottom: 2em;
    }
}