﻿.experience-detail {
		margin-top: calc(#{$head-height-lg} + 2rem);
    &.pdf{
        .detail-overview{
            width: 63%;
        }
        .attorney-area{
            .section-title{
                text-align: left;
            }
        }
    }
	.detail-overview-header {
		padding: $white-space-base 0;
		@include screen($bp-phablet) {
			padding-top: 0;
		}
		&:before {
			content: none;
		}

		.detail-overview-title {
			color: $color-text-gray;			
		}		

		.related-lawyer-listing {
			.related-lawyer {
				&:after {
					content: ', ';
				}
				&:last-child {
					&:after {
						content: '';
					}
				}
			}
		}
	}	

	@include respond-to-max-width($bp-phablet) {
		.detail-overview-social {
			.share-tools_wrapper {
				.detail-overview-social {
					margin-bottom: 0;	
				}
			}			
		}
	}
}