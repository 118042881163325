////////////////////
/// THEME COLORS
////////////////////
$image-overlay: rgba(0, 0, 0, 0.2);

///////////////////////
/// Vertical Rhythm
///////////////////////
$default-spacing: 1rem;

///////////////////////
/// Paths
///////////////////////
$ionicons-font-path: '/content/site/fonts';

$image-path: '/content/site/images';

$svg-path: '/content/site/images/icons';

$tile-path: '/content/site/images/tile-items';

///////////////////////
/// Layout
///////////////////////
$nav-height: 60px;
$home-padding: 48px;
$home-padding-mobile: 24px;

///////////////////////
/// transition
///////////////////////
$transition-timing: 0.22s;
$transition-ease: ease-in-out;

$transition-general: color $transition-timing $transition-ease,
  background-color $transition-timing $transition-ease,
  border-color $transition-timing $transition-ease;

///////////////////////
/// images
///////////////////////
$landing-img-ratio: (575/1600) * 100%;
$landing-hero-ratio: (400/1600) * 100%;
$z-index-mobile-menu: 2000;
$z-index-mobile-menu--close: 2020;
$z-index-mobile-menu--container: 2020;

///////////////////////
/// z-index
///////////////////////
$z-index-header: 1000;
$z-index-jumpmenu: $z-index-header - 10;
$z-index-typeahead: $z-index-header + 100;
$z-index-mobile-menu: 2000;
