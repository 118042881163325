.insight-tile {
  .type {
    .dot {
      background-color: $color-brand-yellow;
    }

    .type-name {
      color: $color-brand-darkyellow;
      &:not(:last-child):after{
          content: "|";
          color:$color-text-gray;
          margin:0 rem(5px);
      }
    }


    .date {
        font-size:rem(18px);
        color:$color-text-gray;
    }
  }

  .title {
    a {
      color: inherit;
	  &:not(.no-link) {
		  &:hover {		
			color: $color-brand-darkyellow;
		  }
	  }      
    }
  }
}
