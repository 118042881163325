.main-menu{
	$menu-vertical-gap:20px;
	$animation-timing:0.3s;
	$animation-type:ease-in-out;
	position: absolute;
	top:48%;
	width:calc(100% - #{$home-padding});
	display: block;
	color:$color-brand-white;
	//container is used so the hover doesn't span entire width, just the width of title
	.main-menu-container{
		@include clearfix();
		position: relative;
		height:100%;
	}
	.container{
		display: inline-block;
	}
	.menu-option{
		font-size:rem(40px);
		float:left;
		display: inline-block;
		width:33%;
		text-align: center;
		text-transform: uppercase;
		&.active {
			.sub-menu{
				visibility: visible;
				max-height:200px;
			}
		}
		.container{
			padding:0 rem(24px);
		}
	}
	.menu-title{
		display: inline-block;
		font-size:rem(40px);
		font-weight: $font-weight-light;
		padding-bottom: $menu-vertical-gap;
		//cursor: pointer;
		//transition: border-bottom $animation-timing $animation-type;
	}
	.sub-menu{
		@extend %list-reset;
		padding-top: $menu-vertical-gap;
		border-top:solid 4px $color-brand-orange;
		visibility: hidden;
		font-size: rem(28px);
		max-height: 0;
		overflow: hidden;
		transition: max-height $animation-timing $animation-type, visibility $animation-timing $animation-type;
		li{
			margin-bottom:0.5*$menu-vertical-gap;
			&:last-child{
				display: none;
			}
		}
	}

	
	@include screen(1000px) {
		top:40%;
		width:100%;
		left:0;
		.menu-title{
			font-size:rem(36px);
		}
	}

    @include screen(1300px){
        .menu-title{
            font-size:2.5vw;
        }
        .sub-menu{
            font-size:2.25vw;
        }
    }
	@include screen($bp-phablet){
		top:31%;
		height:60%;
		.container{
			width: 70%;
			transform: translateY(-50%);
		}
		.menu-option{
			width: 100%;
			position: absolute;
			transition: visibility $animation-timing $animation-type, opacity $animation-timing $animation-type, top $animation-timing $animation-type;
			&:nth-child(1){
				top:0;
			}
			&:nth-child(2){
				top:33%;
			}
			&:nth-child(3){
				top:66%;
			}
			display: block;
			.menu-title{
				font-size: rem(28px);
				border-bottom:solid 2px $color-brand-orange;
				padding-bottom: rem($menu-vertical-gap * 0.5);
			}
		}
		.sub-menu{
			border-top:none;
			font-size: rem(18px);
			transition: $animation-timing max-height $animation-type, $animation-timing visibility $animation-type;
			animation-delay:$animation-timing;
			li{
				margin-bottom:1*$menu-vertical-gap;
			}
		}
		&.active{
			.container{
				transform: translateY(0);
			}
			.menu-option{
				visibility: hidden;
				opacity: 0;
				&.active{
					top: 0;
					visibility: visible;
					opacity: 1;
					transition: $animation-timing visibility $animation-type, $animation-timing opacity $animation-type, $animation-timing top $animation-type;
					animation-delay:$animation-timing;
					.sub-menu{
						transition: max-height $animation-timing $animation-type, visibility $animation-timing $animation-type;
						max-height:280px;
						li:last-child{
							display: block;
							font-size:rem(30px);
							padding-top:20px;
						}
					}
				}
			}
		}
	}
	
	//don't cut off for really wide proportioned screens
	@media(min-width: $bp-phablet) and (max-height: 700px){
		top: 30%;		
	}
}