.topics {
    padding: rem($section-spacing);

    .topic {
        font-weight: $font-weight-bold;
        color: $color-dark-purple;
        cursor: pointer;
        font-size: rem(18px);

        &:last-child {
            @include respond-to-max-width($bp-mid-mobile) {
                display:block;
            }
        }

        &:hover {
            color: $color-text-gray;

            &:before {
                color: $color-med-gray;
            }
        }


        &:not(:last-child) {
            &:after {
                content: '|';
                padding: 0 rem(10px) 0 rem(8px);
                color: $color-med-gray;
            }
        }
    }
}
