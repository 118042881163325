.practices {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 1.2rem;
    .practice {
        width: 45%;
        padding-right: 2rem;
        margin-bottom: 1em;
        line-height: 1.5;
        position: relative;
        box-sizing: border-box;
        display: block;
        a {
            &:after {
                content: $ionicon-var-ios-arrow-right;
                position: absolute;
                right: 1rem;
                top: 0;
                font-family: $font-family-ionicons;
                vertical-align: middle;
                font-weight: $font-weight-bold;
              }      
        }
        
    }
    .pdf & {
        a {
            &:after {
                display: none;
            }
        }
    }
    @include screen($bp-tile-mobile) {
        display: block;
        .practice {
            width: 100%;
        }
    }
}
