.subnavigation {
  background-color: $color-med-light-gray;
  color: $color-black;
  position: relative;  
  margin-bottom: 3rem;
  position: sticky;
  top: calc(#{$head-height-collapsed} - 1px);
  z-index: $z-index-jumpmenu;
  transition: top .3s ease-in;


  &__list {
    max-width: $site-max-width;
    margin: 0 auto;
    padding: 0 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
  }
  
  &__item:first-child &{
    &__link {
      margin-left: 0;
    }
  }
  
  &__link {
    font-weight: $font-weight-bold;
    border-bottom: 3px solid transparent;
    padding: 5px 0;
    margin: 1rem;
    position: relative;
    display: block;
    &--is-active {
      border-bottom: 3px solid $color-brand-orange;
    }
  }

  @include respond-to-max-width($bp-phablet) {
    top: calc(#{$head-height-sm} - 1px);
  }
  

  @include screen($bp-phablet) {
    margin-bottom: 0;
    overflow-x: scroll;

    &__item {
      flex-shrink: 0;
    }

    &__link {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
