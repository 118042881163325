﻿.career-audience {
  .detail-overview-header {
    padding: $white-space-base $indented-padding;
    &:before {
      content: none;
    }

    .detail-overview-title {
      color: $color-text-gray;
      display: inline;
    }

    .career-section-nav {
      float: right;

      @include screen($bp-phablet) {
        float: none;
      }
    }

    @include screen($bp-mobile-portrait) {
      padding-left: $wrapper-subpadding;
      padding-right: $wrapper-subpadding;
    }
  }
}
