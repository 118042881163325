.people-search-area {
	@include base-wrapper;

    position: relative;
    border: 0 none;
    padding-top: $white-space-base;
    padding-bottom: $white-space-base;
    margin-bottom: $white-space-base;
    border-bottom: $border-section;
    

    .hero-intro-bg + & {
    	padding-top: $landing-img-ratio - 5%;
        @include screen($bp-mid-mobile) {
            padding-top: $landing-img-ratio - 15%;    
        }

    }

            .searchbox-btn{
                height: rem(66px);
            }

                @include screen($bp-mid-mobile) {
                    .searchbox-btn{
                        height: rem(48px);
                    }    
                }
}

.people-search-area-header {
	padding: $wrapper-subpadding;
	background-color: $color-brand-white;

	.searchbox--landing {
		margin: 0 0 $white-space-half;
		background-color: $color-brand-white;
	}
}

.people-search-area-filters {

	.select-dropdown {
		width: 20%;
		max-width: 20%;
	}
}

.people-search-area-filters {
	display: none;
	padding:$white-space-half 0 0;

	.select-dropdown {
		display: inline-block;
		
		 & + .select-dropdown {
		 	margin-left: 3%;
		 }
	}
}

.people-search-area-toggle  {
	
	&:before {
		content: '+';	
		display: inline-block;
		margin-right: .5em;
		width: 1em;
	}

	&.is-expanded {
		&:before {
			content: '-';
		}
	}
}

@include screen($bp-mid-mobile) {

	.people-search-area-filters {

		.select-dropdown {
			display: block;
			width: 100%;
			max-width: none;
			
			 & + .select-dropdown {
			 	margin:$white-space-small  0 0;
			 }
		}
	}
}