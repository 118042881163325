%image-darken{
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: $image-overlay;
	z-index: -1;
}
%list-reset {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		margin-bottom: 0;
	}
}
