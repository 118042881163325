$ap-spacing: rem(20px);

.applied-filters {
    margin-bottom:$white-space-half;
    display: table;    

}

.applied-filters-label {
    @include font-soleil-bold(24px);
    line-height: 1;
    padding:$ap-spacing  $wrapper-subpadding;
    display: table-cell;
    vertical-align: top;
    white-space: nowrap;
	//padding-left: 0;
}

.applied-filters-list {
    @include clearfix;
    display: table-cell;
    padding: 0 0 1px;
    border-left: 1px solid $color-text-gray;
}


.applied-filter,
.applied-filter-clear {
    @include font-soleil-bold(18px);

    color: $color-text-gray;
    padding: $ap-spacing;
    background-color: transparent;
    border: 1px solid $color-text-gray;
    border-left-width: 0;
    height: rem(70px);
    margin-bottom: -1px;
    float: left;
    display: inline-block;
     box-sizing: border-box;
    transition: background-color $transition-timing $transition-ease,
                color $transition-timing $transition-ease;

}

.applied-filter-clear  {
    background-color: $color-med-light-gray;
    line-height: 1.5;
    cursor: pointer;

    &:hover {
        background-color: $color-text-gray;
        color: $color-med-light-gray;
    }
}

.applied-filter-remove {
    @include font-soleil-bold(18px);
    padding: 0 0.5em 0 0;
    
    color: $color-text-gray;
}

@include screen($bp-phablet) {
    .applied-filters,
    .applied-filters-label,
    .applied-filters-list {
        display: block;
    }
}
