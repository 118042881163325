.bio-sticky-header {
    position: fixed;
    left: 0;
    right: 0;
    // top: calc(#{$head-height-collapsed} - 1px);
    top: 0;
    z-index: $z-index-jumpmenu + 10;
    background-color: $color-brand-orange;
    color: $color-brand-white;
    padding: rem(14px) 0 1rem;
    font-size: rem(18px);
    transform: translateY(-100%);
    transition: top 0.3s ease-in, transform 0.3s ease-in;
    height: rem(90px);
    box-sizing: border-box;

    &__content {
        display: flex;
        justify-content: space-between;
    }

    .bio-hero & .container {
        padding: 0 2rem;
    }

    &__name {
        @include font-soleil-bold($sub-section-title-size);
    }

    &__email {
        font-weight: $font-weight-semibold;

        a:hover {
            color: $color-brand-white;
        }
    }
    @include respond-to-max-width($bp-mid-mobile) {
        font-size: 1rem;
        height: rem(75px);

        .bio-hero & .container {
            padding: 0 1rem;
        }

        &__name {
            font-size: 1.1rem;
        }
        &__content {
            display: block;
        }

        &__email,
        &__level {
            display: none;

        }
    }
}

.bio-hero.is-collapsed {
    .bio-sticky-header {
        top: calc(#{$head-height-collapsed} - 1px);
        transform: translateY(0);
    }
    & ~ .jump-navigation {
        top: calc(#{$head-height-collapsed} + 89px) 
    }

    @include respond-to-max-width($bp-mid-mobile) {
        & ~ .jump-navigation {
            top: calc(#{$head-height-collapsed} + 73px) 
        }   
    }
}
