.highlight-tile.tile-base {
    $icon-header-height: rem(90px);
    $icon-line-spacing: rem(15px);
    $tile-padding: rem(20px);
    $tile-min-height: rem(425px);

    border: none;
    background: $color-med-light-gray;
   

    .tile-container {
        border-top: none;
        position: relative;
        padding: $icon-header-height $tile-padding $tile-padding;
        min-height: $tile-min-height;
        box-sizing: border-box;
    }

    .icon-wrapper {
        position: absolute;
        top: $tile-padding;
        left: $tile-padding;
        right: $tile-padding;
        height: $icon-header-height/2;
        text-align: center;
        display: flex;
        align-items: center;

        &:before,
        &:after {

            content: '';
            flex-grow: 1;
            display: inline-block;
            left: 0;
            border-top:1px solid $color-brand-white;
            
        }
    }

    [class^="icon-svg-"] {
        display: inline-block;
        border: $icon-line-spacing solid $color-med-light-gray;
        border-width:0 $icon-line-spacing;
        position: relative;
        z-index: 5;
    }
}

.highlight-title--infographic.tile-base {

    .icon-wrapper {
        display: none;
    }
}


.highlight-tile-abstract {
    line-height: 1.5;

    .highlight-title {
       @include font-soleil-bold(30px);
       line-height: 1.2;
       margin:0 0 rem(20px);
    }

    .highlight-number {
       @include font-soleil-bold(70px);
       
       line-height: 1.2;
       margin:0 0 rem(15px);
    }

    .highlight-link {
        @include font-soleil-regular(18px);
        @include transition(background-color);

        display: inline-block;
        margin-top: rem(30px);
        border: 1px solid $color-border-gray;
        padding: .85em 1em;



        &:hover {
            background-color: $color-brand-white;
            border-color: $color-brand-white;
            color: $color-header-gray;
        }
    }



}