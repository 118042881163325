@mixin clearfix {
    &:after {
        clear: both;
        display: table;
        content: '';
    }
}

@mixin borderBox {
    box-sizing: border-box;
}

@mixin borderBox {
    box-sizing: border-box;
}

@mixin display($display: block) {
    display: $display;

    @if ($display == inline-block and $legacy_support_for_ie) {
        *display: inline;
        zoom: 1;
    }
}

/// Helper mixin to size elements
/// @param {Number} $width - Width
/// @param {Number} $height ($width) - Height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin transition($property: all, $timing: $transition-timing) {
    transition: $property #{$timing} $transition-ease;
}

@mixin respond-to-max-width($width) {
  @media screen and (max-width: rem($width)) {
    @content;
  }
}

@mixin respond-to-min-width($width) {
  @media screen and (min-width: rem($width)) {
    @content;
  }
}


@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

@mixin bg-cover {
  background:transparent no-repeat center center;
  background-size: cover;
}

@mixin center($direction, $position: absolute) {
  
  @if $position == 'relative' {
    position: relative;
  }

  @else {
    position: absolute;
  }

  @if $direction == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  }

  @else if $direction == 'horizontal' {
    left: 50%;
    transform: translate(-50%);
  }

  @else if $direction == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}



@mixin gradient($start-color: #555, $end-color: #333, $deg: 45deg, $spread: 100%) {
  background-repeat: repeat-x;
  background-image: linear-gradient($deg, $start-color, $end-color $spread); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}