.related-industries {	
    background-color: $color-med-light-gray;
    padding-top: $white-space-base;
    padding-bottom: $white-space-base;
    margin-bottom: rem(64px);

    .section-title {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: $white-space-base;
    }

    .btn-viewmore {
    	margin-top:$white-space-base;
    }
}



@include screen($bp-desktop) {
    .related-industries {
        padding-left: $indented-padding-desktop;
        padding-right: $indented-padding-desktop;
    }
}

@include respond-to-max-width($bp-mid-mobile) {
    .related-industries {
	 
        .section-title {
            margin-bottom: $white-space-half;
        }

        .section-title,
        .industries-listing {
            width: 100%;
        }
    }
}

@include screen($bp-mobile) {
	.stories-area {

	    .section-title {
	    	$vertical-spacing: rem(30px);
	    	margin-bottom:$vertical-spacing;
	    }
	}
}