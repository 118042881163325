$overview-column-width: rem(190px);

.detail-overview {
	margin-bottom: $white-space-base;
	// padding-top: 2rem;
}

.detail-overview-header {
	@include bg-cover;

	background-position: center top;

	padding: rem(150px) 0 $white-space-base 0;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.25);
	}
	// .breadcrumb {
	// 	z-index: 20;
	// 	position: absolute;
	// 	font-size: $font-breadcrumb-size;    
	// 	padding: $head-height-lg 0 5rem 0;
	// 	width: 100%;
	// 	top: 0;
	// 	left: 0;
	// 	&::before {
	// 			content: '';
	// 			position: absolute;
	// 			top: 0;
	// 			left: 0;
	// 			right: 0;
	// 			bottom: 0;
	// 			background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 30%, transparent 100%);
	// 			mix-blend-mode: multiply;
	// 	}
	// }
}

.detail-overview-title {
	@include font-soleil-bold(38px);

	color: $color-brand-white;
	position: relative;
	z-index: 5;

}

.detail-overview-content {
	@include clearfix;
	display: flex;
	@include respond-to-max-width($bp-phablet) {
		display: block;
	}
	padding-top: $white-space-base;

    &--left {
        padding-left: 0;
    }

    &--topic {
        padding: 0 $indented-padding;
    }

	.rich-text {
		@include font-soleil-light(18px);
		min-height: 220px;
		width: 90%;

		.type-name{
			    font-size: 1.875rem;
				font-family: "Soleil", sans-serif;
				font-weight: 700;
				line-height: 1.15;
				margin-bottom: 1em
		}

        &--left {
            margin-left: 0;
        }
	}

  .share-tools {
		right: 0;
		position: absolute;

		@include respond-to-max-width($bp-phablet) {
			position: static;
			margin-bottom: 1.25rem;
		}
	}

	.share-tool {
		float: none;

		&:not(:first-child) {
			margin-top: -1px;
		}

		@include respond-to-max-width($bp-phablet) {
			float: left;
			&:not(:first-child) {
				margin-top: 0;
			}
		}

		a, span {
			$icon-size: rem(50px);

			@include size($icon-size);

			@include respond-to-max-width($bp-mid-mobile) {
					@include size($icon-size);
			}
		}
	}
}

@include screen($bp-desktop) {
    .detail-overview-header {
    	padding-top: rem(150px);
    }
}

@include respond-to-max-width($bp-phablet) {

	.detail-overview-social {
		float: none;
		margin-bottom: $white-space-small;

		.detail-overview-social {
			margin-bottom: 0;
		}
	}

	.detail-overview-content {
		.rich-text {
			margin-left: 0;
		}
	}

	.detail-overview-header {
    	padding-top: rem(100px);
    }
}
