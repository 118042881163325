.flex-panel {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px rem(16px);

  &.gray-background {
    background-color: $color-gray-section-background;
  }

  .rich-text {
    margin: 0;
  }

  .bio-card-figure {
    @include screen($bp-tablet) {
      height: 100%;
    }
    @include screen($bp-mobile) {
      height: 100%;
    }
  }

  &.remove-padding:not(.gray-background) {
    padding-top: rem(50px);
  }

  .bio-card {
    border-bottom: 0 none;
  }
}

.flex-panel-col-container {
  max-width: $site-max-width;
  width: 100%;
  padding: rem(40px) 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  // column-gap: 0px;
  row-gap: rem(20px);

  & > .flex-panel-col + .flex-panel-col {
    // column-gap: rem(40px);
  }

  @include screen($bp-phablet) {
    padding: rem(27px) 0px;
    flex-direction: column;
    max-width: $site-mobile-max-width;
  }
}

.flex-panel-col-container.mobile-flip {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 767px){
    flex-direction: column-reverse;
  }
}

.flex-panel-col {
  flex-grow: 1;
  width: 50%;

  &:first-child {
    @include screen($bp-phablet, min) {
      margin-right: rem(20px);
    }
  }
  &:last-child {
    @include screen($bp-phablet, min) {
      margin-left: rem(20px);
    }
  }
  &:only-child {
    margin: 0px;
  }
  &.half-width {
    .featured-contact {
      max-width: rem(340px);
      width: 100%;

      @include screen($bp-phablet) {
        max-width: none;
      }
    }
  }

  .attorney-area-listing {
    @include screen($bp-phablet) {
      flex-direction: column;

      .bio-card {
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
      }
    }
  }

  @include screen($bp-phablet) {
    width: 100%;
    max-width: none;
  }

  &.empty,
  &:empty {
    flex-grow: 0;
    width: auto;
    padding: 0;
  }

  &.empty + &,
  &:empty + & {
    flex: 1 1 100%;
    width: 100%;
  }
}

.flex-panel-headline-cta {
  @include font-soleil-bold(44px);
  line-height: 1.15;
  margin-bottom: $white-space-base/2;
}

.flex-panel-header {
  @include font-soleil-bold(30px);
  line-height: 1.15;

  .header-text {
    font-size: 2em;
    margin: 0.67em 0;
    font-weight: bold;
  }

  h2 {
    @include font-h2-redesign;
  }
}

.flex-panel-subheader {
  @include font-h3-redesign;
  max-width: $site-max-width;
}

.flex-panel-copy {
  @include font-soleil-light(18px);
  line-height: 1.55;
  padding-bottom: $white-space-base/2;

  .rss-feed {
    margin-bottom: 16px;

    img {
      height: 2em;
    }
  }

  .job-opportunity {
    display: block;
    padding-bottom: 15px;
    margin-right: 10px;

    .date {
      display: inline-block;
      width: 200px;
    }

    .title {
      font-weight: bold;
      display: block;
    }

    .abstract {
      padding-bottom: 1em;
      width: 80%;
    }
  }

  &--center {
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    padding-bottom: 0;
  }
}

.flex-panel-topics {
  padding: rem(62px) 0;
}

.job-opportunity-title {
  text-align: center;
  padding-bottom: $white-space-base;
  margin-bottom: $white-space-base;
  border-bottom: $border-section;
}

.opportunity-listing {
  width: 60%;

  .flex-panel-cta-link {
    @include respond-to-max-width($bp-desktop-large) {
      min-height: 0em;
    }

    @include respond-to-max-width($bp-mid-mobile) {
      min-height: 0em;
    }
  }
}

.flex-panel-img {
  width: 100%;
}

.flex-panel-cta-links {
  margin-bottom: $white-space-base/2;

  > li {
    margin-bottom: $white-space-small;
  }

  .flex-panel-col + .flex-panel-col & {
    text-align: center;
  }
}

.flex-panel-cta-link {
  @include orange-cta;
  width: fit-content;

  @include screen($bp-phablet) {
    min-width: auto;
    width: 100%;
  }
}

@include screen($bp-mobile-portrait) {
  .flex-panel {
    padding-top: 0;
  }

  .contact-us .flex-panel-header {
    margin-left: 0.75em;
  }

  .contact-us ul {
    margin-left: 1.5em;
  }
}
