$full-height-with-nav: calc(100vh - $head-height-collapsed);
$dual-image-max-width: rem(1348px);

.dual-image-card {
  box-sizing: border-box;
  margin: 0;
  padding: rem(100px) rem(27px);
  display: flex;
  justify-content: center;
  min-height: $full-height-with-nav;
  background-color: $color-gray-section-background;

  @include screen($bp-desktop) {
    --isMobile: true;
    padding: rem(27px);
    height: auto;
    min-height: auto;
  }

  &__flex-container {
    width: 100%;
    max-width: $dual-image-max-width;
    display: flex;
    justify-content: center;
    gap: rem(75px);

    @include screen($bp-desktop) {
      flex-direction: column-reverse;
      gap: rem(20px);
      max-width: none;
    }
  }

  &__col {
    flex: 1 1;
    display: flex;
    gap: rem(16px);
    height: 100%;

    @include screen($bp-desktop) {
      flex: 1 0;
      width: auto;

      .dual-image-card__copy {
        align-self: stretch;
      }
      &:first-of-type {
        box-sizing: border-box;
        justify-content: center;
        min-height: rem(310px);
      }
      &:last-of-type {
        gap: rem(21px);
      }
    }

    &:last-of-type {
      flex-direction: column;
      justify-content: center;
      gap: rem(30px);
    }
  }

  &__img {
    max-width: calc(50% - rem(8px));
    height: 100%;
    max-height: rem(543px);
    padding: 0px;
    object-fit: cover;

    @include screen($bp-desktop) {
      height: rem(277px);
      max-width: rem(170px);
    }
    &:first-of-type {
      align-self: flex-start;
    }
    &:last-of-type {
      align-self: flex-end;
    }
  }

  &__headline {
    @include font-h2-redesign;
    margin: 0px;
  }
  &__copy {
    @include font-body-redesign;
  }
  &__cta {
    @include orange-cta;
    flex-shrink: 0;
    @include screen($bp-desktop, min) {
      align-self: flex-start;
    }
  }
}
