.hero-intro-bg {
    position: absolute;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    left: 0;
    padding-bottom: $landing-img-ratio; //image ratio from templates
    box-sizing: border-box;
    .breadcrumb {
        z-index: 20;
        top: 0;
        position: absolute;
        padding: $head-height-lg 0 5rem 0;
        width: 100%;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 30%, transparent 100%);
            mix-blend-mode: multiply;
        }
    }

    &--gradient {
        background: linear-gradient(rgba(0,0,0,.375), transparent);
        height: 200px;
        z-index: 1;
        position: relative;

        &-absolute {
            width: 100%;
            position: absolute;
        }
    }
}
