//want to use em here since we want everything to scale down as screen gets smaller in height
.mobile-menu {
  $menu-vertical-gap: 28px;
  $animation-timing: 0.5s;
  $animation-type: ease-in-out;
  $menu-width: 500px;
  $menu-padding: $home-padding;
  $menu-padding-offset: 8vh 0 5vh;
  position: fixed;
  padding: $menu-padding-offset;
  top: 0;
  right: $menu-width * -1 - $menu-padding * 2;
  width: $menu-width;
  height: 100vh;
  font-size: 1em;
  text-align: right;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $color-brand-black;
  opacity: 0.95;
  color: $color-brand-white;
  z-index: $z-index-mobile-menu;
  transition: right $animation-timing $animation-type;
  border-right: 6px solid $color-brand-orange;
  box-sizing: border-box;

  .container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    z-index: auto;
  }
  &.active {
    right: 0;
  }
  .menu-icon,
  .menu-close
  {
    position: absolute;
    top: 0;
    right: 0;
    height: 2rem;
    padding: 2rem;
    display: block;
    cursor: pointer;
    font-size: em(40px);
    vertical-align: top;
    i {
      display: block;
    }
  }
  .menu-close {
    color: #ffffff;
    z-index: 3;
  }
  // $menu-list-offset: rem(40px);
  .menu-option {
    padding-bottom: 0 0 rem(10px);
    display: inline-block;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    a:hover {
      text-decoration: none;
    }

    .sub-menu {
      @extend %list-reset;
      font-size: em(22px);
      line-height: 1em;
      li {
        margin-bottom: 2rem;
        position: relative;
      }
    }

    &--primary {
      .sub-menu {
        font-size: em(28px);
        font-weight: $font-weight-bold;
      }
    }
  }
  .sub-menu {
    &__flyout-trigger {
      color: $color-brand-white;
      font-size: inherit;
      font-weight: $font-weight-bold;
      padding-left: 0;
      padding-right: 0;

      &:after {
        font-size: 1.5em;
        color: $color-brand-white;
        content: $ionicon-var-ios-arrow-right;
        font-family: $font-family-ionicons;
        position: absolute;
        vertical-align: middle;
        font-weight: $font-weight-bold;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .menu-title {
    display: inline-block;
    font-size: em(30px);
    font-weight: $font-weight-light;
    padding-top: em($menu-vertical-gap, 30px);
    //cursor: pointer;
    //font-family: $font-family-light;
  }
  .subscribe-panel {
    font-size: rem(22px);
    padding-bottom: rem(10);
    text-align: left;

    &--title {
      padding-bottom: 1em;
    }

    &--btn {
      display: inline-block;
      color: $color-brand-white;
      border: 2px solid $color-brand-orange;
      padding: em(5px) em(20px);

      &:hover {
        background-color: $color-brand-orange;
        border-bottom-width: 2px;
        color: $color-brand-black;
        text-decoration: none;
      }

      &:after {
        content: $ionicon-var-ios-arrow-right;
        font-family: $font-family-ionicons;
        position: relative;
        vertical-align: middle;
        font-weight: bold;
        margin-left: 0.5rem;
      }
    }
  }
  .social-links {
    // padding: 0 0 0 em(40px);
    font-size: em(24px);
    a {
      margin-right: em(20px);
    }
    text-align: left;
  }
  .other-links {
    font-size: em(14px);
    font-family: $font-family-secondary;
    margin-top: $menu-vertical-gap * 0.5;
    a + a {
      &:before {
        content: '|';
        padding: 0 10px;
      }
    }
  }
  #{$a-tags-hover} {
    color: $color-brand-white;
    border-bottom: 3px solid $color-brand-orange;
    text-decoration: none;
  }
  .disclaimer {
    @include clearfix();
    margin-top: $menu-vertical-gap * 1.4;
    p {
      margin-bottom: 0;
    }
  }
  @include screen($bp-phablet) {
  }
  @include screen($bp-mobile) {
    right: -100vw;
    width: 100vw;
  }

  //for additions to the menu
  $podcasts-offset: 40px;

  @include media-height(910px + $podcasts-offset) {
    font-size: 0.9em;
  }
  @include media-height(830px + $podcasts-offset) {
    font-size: 0.8em;
  }
  @include media-height(750px + $podcasts-offset) {
    font-size: 0.7em;
  }

  $extra-offset: 70px;
  //even more room for another event type besides podcasts
  &.smaller-font-size {
    @include media-height(910px + $extra-offset) {
      font-size: 0.9em;
    }
    @include media-height(830px + $extra-offset) {
      font-size: 0.8em;
    }
    @include media-height(750px + $extra-offset) {
      font-size: 0.7em;
    }
  }

  // Flyout Styles
  &__flyout {
    $transition-timing: 0.3s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: inherit;
    padding: $menu-padding-offset;
    text-align: left;
    transition: transform $transition-timing ease-in,
      visibility 1ms linear $transition-timing;
    transform: translateX(100%);
    visibility: hidden;

    &.is-active {
      transform: translateX(0%);
      visibility: visible;
      transition: transform $transition-timing ease-in;
    }

    &-container {
      position: relative;
      overflow: hidden;
      height: 100%;
      z-index: auto;
      padding: 0 2.5rem;
      width: 100%;
      box-sizing: border-box;
    }

  }
  &__flyout-content {
    > div {
      font-size: em(28px);
      margin-top: 40px;
      margin-bottom: 1.2em;
      font-weight:bold;
    }
  }

  &__list {
    @extend %list-reset;

    font-size: em(24px);

    li {
      margin-bottom: 2rem;
    }
  }

  &__flyout-close {
    position: absolute;
    left: 2rem;
    top: 2rem;
    font-size: rem(14px);
    text-transform: uppercase;
    color: $color-brand-white;
    padding-left: 1.2rem;

    &:before {
      font-size: 1.5em;
      content: $ionicon-var-ios-arrow-left;
      font-family: $font-family-ionicons;
      vertical-align: middle;
      font-weight: $font-weight-bold;
      position: absolute;
      left: 0;
    }

    @include respond-to-max-width($bp-mid-mobile) {
      left: 1rem;
    }
  }
}
