.breadcrumb {
  font-size: $font-breadcrumb-size;

  .hero-content-wrapper &,
  .detail-overview-header &,
  .hero-intro-bg & {
    z-index: 20;
    position: absolute;
    font-size: $font-breadcrumb-size;
    padding: $head-height-lg 0 5rem 0;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 30%,
        transparent 100%
      );
      mix-blend-mode: multiply;
      z-index: -1;
    }
  }
  .bio-hero & {
    margin-bottom: 5px;
    padding: 0px;
    margin-top: 0px;

    .container {
      padding: 0;
      margin: 0;
    }
  }

  .bio-hero & {
    .nav-menu-item {
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7);
    }
  }

  .section-heading {
    color: $color-light-gray;
  }

  .nav-menu-item {
    color: $color-brand-white;
    font-weight: 600;

    &:not(:first-of-type):before {
        content: '/ ';
        color: $color-brand-white;
      }

    a {
    font-weight: $font-weight-semibold;
    padding: 0 1px;

      &:hover {
        color: $color-bilzin-orange;
      }
    }

    &:last-of-type {
      color: $color-brand-white;;
      font-weight: $font-weight-normal;
    }
  }

  h1 {
    &.nav-menu-item {
      font-size: 1em;
      display: inline;
    }
  }

  &--white {
    .nav-menu-item {
      &.has-section-heading:not(:first-of-type) {
        &:before {
          content: '/ ';
          color: $color-brand-white;
        }
      }

      &:last-of-type {
        color: $color-brand-white;
      }
    }
  }

  &--hide {
    display: none;
  }
}
