@keyframes fade-up-and-in {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@mixin fade-up($duration: 0.8s, $delay: 0s) {
  animation-name: fade-up;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.js-fade-in {
  opacity: 0;

  &.visible {
    animation: 0.8s ease-out 0s 1 forwards fade-up;
  }
}
