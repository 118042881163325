[class^="icon-svg-"] {
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(#{$image-path}sprite.svg);
}

.icon-svg-speaking {
    width: rem(10px);
    height: rem(32px);
    background-position: 0 0;
    color: $color-brand-white;
    transform: rotate(45deg);
    margin-bottom: rem(-15px);
}
.icon-svg-professional {
    width: rem(39px);
    height: rem(32px);
    background-position: rem(-48px) 0;
}
.icon-svg-award {
    width: rem(30px);
    height: rem(32px);
    background-position: -96px 0;
}
.icon-svg-quote {
    width: rem(10px);
    height: rem(32px);
    background-position: 0 0;
    color: $color-brand-white;
    transform: rotate(45deg);
    margin-bottom: rem(-15px);
}
.icon-svg-community {
    width: rem(30px);
    height: rem(32px);
    background-position: -96px 0;
}