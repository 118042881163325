﻿.emergency-notification {
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
  position: absolute;
  padding: 2rem;
  padding-top: calc($head-height-lg + rem(24px));
  top: 0;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  &::before {
    content: '';
    position: absolute;
    background-color: $color-charcoal;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.85;
  }
  &__content {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
  }
  &__close {
    position: absolute;
    right: 1rem;
    &:before {
      content: $ionicon-var-ios-close-outline;
      font-family: $font-family-ionicons;
      font-size: rem(32px);
      width: 20px;
      height: 20px;
    }
    &:hover {
      cursor: pointer;
      &:before {
        content: $ionicon-var-ios-close;
      }
    }
  }

  .alert {
    font-weight: 700;
    font-size: 1.5rem;
    display: inline-block;
    margin-bottom: 1rem;
    &:after {
      content: ': ';
    }
  }
  .alert-description {
    font-weight: 100;
  }
  img {
    height: 1.33em;
    padding-right: 0.5em;
    margin-bottom: -2px;
  }
  @include screen($bp-tile-mobile) {
    padding-top: $head-height-lg + 2rem;
  }
}
