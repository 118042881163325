.search-landing {
  &__header {
    margin: 1.5em 0;
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-light-gray;
    align-items: baseline;

    &--title {
      font-size: em($section-title-size);
      font-weight: $font-weight-semibold;
      margin:0;
    }
  }

  &__section {
    padding: 1.5em 0;

     &--title {
      font-size: em($section-title-size);
      font-weight: $font-weight-semibold;
      margin: 0.67em 0;
    }
    &__viewall {
      font-weight: $font-weight-bold;

      &:after {
        color: $color-brand-orange;
        content: $ionicon-var-ios-arrow-right;
        font-family: $font-family-ionicons;
        position: relative;
        vertical-align: middle;
        font-weight: $font-weight-bold;
        padding-left: 1em;
      }
    }

    &__loadmore {
      border: 2px solid $color-brand-orange;
      padding: rem(10px) rem(25px);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      &:hover {
        background: $color-brand-orange;
        color: $color-brand-white;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__footer {
    padding: 1.5em 0;

    &__viewall {
      width: 100%;
      border: 2px solid $color-brand-orange;
      padding: rem(10px) rem(25px);
      &:hover {
        background: $color-brand-orange;
        color: $color-brand-white;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
