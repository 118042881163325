﻿.oci-events {
	.detail-overview-header {
		padding: $white-space-base $indented-padding;
		&:before {
			content: none;
		}

		.detail-overview-title {
			color: $color-text-gray;			
		}		
	}

	.oci-events-area-listing {
		padding: 5rem $white-space-base $indented-padding;

		.oci-event-tile {
			&:not(:first-child) {
				border-top: 3px solid $color-border-gray;
			}
			padding: 3.125rem 0 1.5625rem;

			.event-title {
				font-size: 1.875rem;
				font-family: "Soleil", sans-serif;
				font-weight: 700;
				line-height: 1.15;
				margin-bottom: 1em;
			}

			.date, .school, .label {
				font-size: 1.125rem;
				font-family: "Soleil", sans-serif;
				font-weight: 700;
				color: #68686c;
				margin-top: 0.5em;
				//match spacing if other content missing
				&:last-child {
					margin-bottom: 1rem;
				}
			}

			.rich-text {
				margin: 1rem 0;
			}

			.related-lawyer-listing {
				.related-lawyer {
					&:after {
						content: ', ';
					}
					&:last-child {
						&:after {
							content: '';
						}
					}
				}
			}
		}			    
	}
}