.container {
  width: 100%;
  max-width: $site-max-width;
  margin: 0 auto;
  // position: relative;
  z-index: 1;
  padding: 0 2rem;
  box-sizing: border-box;
  .container {
    padding: 0;
  }

  @include respond-to-max-width($bp-mid-mobile) {
    padding: 0 1rem;
  }

  &.container__typeahead {
      z-index: $z-index-typeahead;  
  }
}
