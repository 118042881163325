﻿.about-us {
  .container {
    padding: 0px;
    max-width: none;
  }
  .careers-landing-body-copy {
    padding-bottom: 0;
  }

  .attorney-area {
    margin-left: 0;
    margin-right: 0;
    border-top: none;
    padding-top: 0;

    @include respond-to-max-width($bp-mobile) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .flex-panel-cta-link {
    @include respond-to-max-width($bp-mobile) {
      min-width: auto;
      display: block;
    }
  }

  .contact-info {
    border-top: 2px solid $color-light-gray;
    margin: 0 $left-right-spacing $top-bottom-spacing * 2;

    @include respond-to-max-width($bp-bio-overview-scale) {
      margin: 0 $left-right-spacing-tablet $top-bottom-spacing;
    }

    .rich-text,
    .flex-panel-cta-link {
      margin-bottom: 1.5rem;
    }

    @include respond-to-max-width($bp-tablet) {
      margin: 0 $left-right-spacing-tablet $top-bottom-spacing * 2;
      padding-top: 0;
    }
  }

  .bio-card-figure {
    @include respond-to-max-width($bp-mobile) {
      height: initial;
    }
  }
}
