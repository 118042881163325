$full-height-with-nav: calc(100vh - $head-height-collapsed);

.quote-carousel {
  height: $full-height-with-nav;
  overflow-y: auto;
  position: relative;
  border-top: 1px solid $color-opaque-gray;
  border-bottom: 1px solid $color-opaque-gray;
  background-color: $color-gray-section-background;

  @include screen($bp-desktop) {
    --isMobile: true;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quote-carousel-inner-container {
    display: flex;
    height: 100%;
    overflow: hidden;

    @include screen($bp-desktop) {
      flex-direction: column-reverse;
      width: 100%;
    }
  }

  .progress-indicator {
    box-sizing: border-box;
    height: $full-height-with-nav;
    min-width: rem(70px);
    border-right: 1px solid $color-opaque-gray;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: rem(8px);

    @include screen($bp-desktop) {
      flex-direction: row;
      height: rem(70px);
      width: 100%;
      border-right: none;
      border-top: 1px solid $color-opaque-gray;
    }

    &-flex-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: rem(8px);

      @include screen($bp-desktop) {
        flex-direction: row-reverse;
        justify-content: flex-start;
        margin: 0px rem(20px);
        flex-shrink: 1;
        min-width: 0;
        width: 100%;
      }
    }

    .slide-numbers {
      width: 13px;
      height: 18px;
      color: $color-text-gray;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;

      @include screen($bp-desktop) {
        transform: translateY(2px);
      }

      .slide-number {
        @include font-soleil-regular(12px);
        line-height: 1;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.8px;
        opacity: 0;
        transition: all 0.2s ease-in;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      .active-number {
        opacity: 1;
      }
    }

    .divider-line {
      width: 1px;
      height: rem(195px);
      background-color: $color-text-gray;

      @include screen($bp-desktop) {
        width: rem(195px);
        height: 1px;
        flex-shrink: 1;
      }
    }

    .indicator-dots-container {
      display: flex;
      flex-direction: column;
      gap: rem(8px);
      flex-shrink: 0;

      @include screen($bp-desktop) {
        flex-direction: row;
        
      }
    }

    .indicator-dot {
      height: rem(7px);
      width: rem(7px);
      border-radius: 50%;
      border: 1px solid $color-text-gray;
      transition: all 0.2s ease-in;
      flex-shrink: 0;

      &:last-of-type {
        @include screen($bp-desktop, min) {
          margin-bottom: rem(36px);
        }
      }
    }

    .active-dot {
      height: rem(19px);
      border-radius: 4px;
      background-color: $color-bilzin-orange;
      border: 1px solid $color-bilzin-orange;

      @include screen($bp-desktop) {
        width: rem(19px);
        height: rem(7px);
      }
    }
  }

  .carousel-container {
    display: flex;
    width: 100%;

    @include screen($bp-desktop) {
      display: block;
      overflow-x: auto;
      align-self: center;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
        opacity: 0;
      }
    }
  }

  .testimonials-container {
    width: 50%;
    flex: 1 1 50%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @include screen($bp-desktop) {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }
  }

  .testimonial-container {
    box-sizing: border-box;
    display: flex;

    position: relative;
    flex-direction: column;
    padding: rem(21px) rem(16px);
    width: 100vw;
    flex-shrink: 0;
    scroll-snap-align: start;
    scroll-snap-stop: always;

    @include screen($bp-desktop, min) {
      justify-content: center;
      align-items: center;
      height: $full-height-with-nav;
      width: 100%;
      padding: 0px;
      padding: 0 rem(24px);
      max-width: none;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
  }

  .testimonial-copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: $site-mobile-max-width;

    @include screen($bp-desktop, min) {
      width: 100%;
      gap: rem(30px);
      max-width: rem(484px);
    }

    img {
      max-width: 100%;
    }
  }

  .testimonial-quote {
    @include font-soleil-regular(20px);
    margin-bottom: rem(30px);
    quotes: '“' '”' '‘' '’';

    &::before {
      content: open-quote;
    }
    &::after {
      content: close-quote;
    }

    @include screen($bp-desktop, min) {
      @include font-h4-redesign;
      opacity: 0;
      margin-bottom: 0px;
    }
  }

  .testimonial-eyebrow {
    @include font-eyebrow-redesign;
    margin-bottom: rem(10px);
    @include screen($bp-desktop, min) {
      opacity: 0;
      margin-bottom: 0px;
    }
  }

  .testimonial-name {
    @include font-body-redesign;
    margin: 0px;

    @include screen($bp-desktop, min) {
      opacity: 0;
    }
  }

  .active-testimonial {
    @include screen($bp-desktop, min) {
      .testimonial-quote {
        animation: 0.8s ease-out 0s 1 forwards fade-up;
      }
      .testimonial-eyebrow {
        animation: 0.6s ease-out 0.2s 1 forwards fade-up;
      }
      .testimonial-name {
        animation: 0.4s ease-out 0.4s 1 forwards fade-up;
      }
    }
  }

  .testimonial-images--desktop {
    width: 50%;
    flex: 1 1 50%;
    height: $full-height-with-nav;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @include screen($bp-desktop) {
      display: none;
    }
  }

  .testimonial-img-container--desktop {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    overflow: hidden;
    height: 100%;
    transition: height 0.8s ease-out;

    &.hidden-image {
      height: 0px;
    }

    img {
      height: $full-height-with-nav;
      width: 100%;
      object-fit: cover;
    }
  }

  .testimonial-img-container--mobile {
    width: rem(170px);
    margin-bottom: rem(28px);
    @include screen($bp-desktop, min) {
      display: none;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    margin-top: rem(22px);

    @include screen($bp-desktop) {
      flex-direction: row-reverse;
      margin-top: 0px;
    }
  }

  &__next-button,
  &__previous-button {
    height: 70px;
    width: 70px;
    padding: 0px;
    border-top: 1px solid $color-opaque-gray;
    transition: all 0.3s ease-in;

    @include screen($bp-desktop) {
      border-left: 1px solid $color-opaque-gray;
      border-top: none;
    }

    svg {
      height: 100%;
    }

    path {
      transition: all 0.3s ease-in;
    }

    &:hover:not(:disabled) {
      background-color: $color-bilzin-orange;
      path {
        stroke: $color-brand-white;
      }
    }
  }

  &__next-button:disabled,
  &__previous-button:disabled {
    path {
      stroke: $color-opaque-gray;
    }
  }
}
