$slide-in-translate-duration: 1s;
$slide-in-translate-percent: 20%;

.large-story-panel {

	.center {
		text-align: center;

		.large-story-panel-title-line {
			display: inline-block;
			position: relative;
		    top: 2rem;

			&::after {
				width: 0;
				transition: width 0.75s;
				background-color: $color-text-gray;
				content: "";
				display: block;
				height: 3px;
			}
		}
	}

	&.large-story-panel-animate--is-in-view {
	    .large-story-panel-title-line {
            &::after {
				width: 1.5625rem;                
            }
        }
	}
}

.large-story-animate {
    opacity: 0;
    transition: transform $slide-in-translate-duration, opacity $slide-in-translate-duration / 2 $slide-in-translate-duration / 2;

    &.large-story-animate--is-in-view {
        opacity: 1;
        transform: translateX(0);
	}
}

.large-story-animate--from-left {
    transform: translateX(-$slide-in-translate-percent);
}

.large-story-animate--from-right {
    transform: translateX($slide-in-translate-percent);
}
