//  Brandon Mathis Media Queries
///////////////////////////////////////////////////////////////////////////////
//
//	http://codepen.io/imathis/pen/BDzcI
//
//  You should use the Breakpoint Aliases (below) when referencing media query
//  breakpoints with your custom CSS
//
//	Breakpoint Values
//	http://www.paintcodeapp.com/news/iphone-6-screens-demystified
///////////////////////////////////////////////////////////////////////////////

$bp-section-nav-right: 1630px;
$bp-bio-image-scale: 1446px;
$bp-bio-overview-scale: 1250px;
$bp-bio-overview-scale-2: 1250px;
$bp-desktop-large: 1024px;
$bp-desktop: 980px;
$bp-tablet: 900px;
$bp-phablet: 768px;
$bp-mid-mobile: 645px;
$bp-mobile: 630px;
$bp-mobile-portrait: 480px;

$bp-tile-tablet: 1050px;
$bp-tile-mobile: 650px;


@mixin screen($device-width, $type: max) {
    @if $type == 'min' {
        @media screen and (min-width: $device-width) {
            @content;
        }
    }

    @else {
        @media screen and (max-width: $device-width - 1) {
            @content
        }
    }
}




$bp-narrow: 820px;
$bp-landscape: 745px;

@mixin media-height($device-height) {
    @media screen and (max-height: $device-height) {
        @content
    }
}


@mixin media-orientation() {
    @media screen and (max-width: $bp-mobile) and (max-height: $bp-mobile-portrait) {
        @content
    }
}
