
.load-container {
    display:none;
    position: fixed;
    height: 100vh;
    width: 100%;
    text-align: center;
    top: 0;
    background-color: rgba(255,255,255,.7);
    .load-boxes{
        top: 50%;
        position: relative;
    }
    .box { 
        display: inline-block;
        width: 1.5vw;
        height: 3vw;
        background-color: $color-brand-orange;       
        animation-name: pulse;
        animation-duration: .5s;
        animation-iteration-count: infinite;
        margin: 2px;
        vertical-align: middle;

        &.two {
            animation-delay: .2s;
            height: 4.5vw;
        }

        &.three {
            animation-delay: .4s;
            height: 4.5vw;
        }

        &.four {
            animation-delay: .6s;
        }
    }
}



@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}
