// BASE LINE HEIGHT
$base-line-height: 1.4;

$measure: 40em;

@mixin font-h7-body-1() {
  @include font-soleil-light(16px);

  line-height: 1.5;
}

@mixin font-h4() {
  @include font-soleil-bold(26px);

  line-height: 1.25em;
}

@mixin font-h3() {
  @include font-soleil-bold(34px);

  line-height: 1.2em;

  @include screen($bp-phablet) {
    font-size: rem(26px);
  }
}

@mixin font-h5() {
  @include font-soleil-bold(22px);

  line-height: 1.2em;

  @include screen($bp-phablet) {
    font-size: rem(20px);
  }
}

@mixin font-eyebrow() {
  @include font-soleil-bold(14px);

  line-height: 1.1;
}

@mixin font-body-redesign() {
  @include font-soleil-regular(16px);
  line-height: 23px;
  @include screen($bp-desktop, min) {
    @include font-soleil-regular(18px);
    line-height: 26px;
  }
}

@mixin font-h1-redesign() {
  @include font-soleil-semibold(42px);
  @include screen($bp-desktop, min) {
    @include font-soleil-semibold(55px);
  }
}

@mixin font-h2-redesign() {
  @include font-soleil-semibold(32px);
  line-height: 37px;
  @include screen($bp-desktop, min) {
    @include font-soleil-semibold(48px);
    line-height: 55px;
  }
}

@mixin font-h3-redesign() {
  @include font-soleil-semibold(28px);
  line-height: 32px;
  @include screen($bp-desktop, min) {
    @include font-soleil-semibold(34px);
    line-height: 39px;
  }
}

@mixin font-h4-redesign() {
  @include font-soleil-regular(26px);
  line-height: 1.2;
}

@mixin font-eyebrow-redesign() {
  @include font-soleil-regular(14px);
  text-transform: uppercase;
  letter-spacing: 2.1px;
  font-weight: $font-weight-semibold;
  line-height: 21px;
}
