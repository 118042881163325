.card-post {
    height: auto;
    background-color: $color-brand-white;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    color: $color-black;
    transition: all $transition-ease $transition-timing;
    padding: rem(12px);
    box-sizing: border-box;

    & + & {
        margin-top: 1rem;
    }

    
    &:hover {
        color: $color-black;
        background-color: $color-brand-white;
        box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.1);
        text-decoration: none;
        .card-post__title {
            text-decoration: underline;
            color: $color-brand-orange;
        }
    }

    &__img {
        flex-shrink: 0;
        min-width: 160px;
        width: 30%;
        margin-right: 32px;
        box-sizing: border-box;
    }

    &__content {
        flex-grow: 1;
    }

    &__image {
        border-left: 4px solid $color-brand-orange;
        width: 100%;
        display: block; 
        margin-right: 5%;
    }
    &__meta {
        @include font-eyebrow();
        margin-bottom: 1rem;
        text-transform: uppercase;
        
        &-item {
            padding-right: 0.5em;
            display: inline;
            color: inherit;

            &:not(:last-child) {
                &:after {
                    padding-left: 0.8em;
                    content: '|';
                }
            }
        }
    }

    &__title {
        @include font-h4();

        margin-bottom: 0.5rem;
        transition: color 0.2s ease-in-out;
    }
    &__authors {
        @include font-eyebrow();
        margin-bottom: 0.5rem;
    }
    &__abstract {
        @include font-h7-body-1();
        color: inherit;
        margin-bottom: 1rem;
    }

    @include screen($bp-mobile) {
       display: block;
       padding-top: rem(24px);
       padding-bottom: rem(24px);
       border-top: 3px solid $color-brand-orange;

       &__img {
           display: none;
       }

       &__abstract {
           margin-bottom: 0;
       }
    }
}
