.subscribe-now {
    &__box {
        @include bg-cover();
        padding: rem(35px) rem(30px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-content: center;
        min-height: rem(250px);
        text-align: center;
        position: relative;
        box-sizing: border-box;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-opaque-dark
        }

        &-heading {
            @include font-h4;
            color: $color-brand-white;
            position: relative;
            z-index: 2;
            width: 100%;
        }

        &-link {
            margin-top: rem(32px);
            position: relative;
            z-index: 1;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        padding-left: 1rem;
        margin-top: rem(24px);

        &-label {
            min-width: 75px;
            display: inline-block;
        }
        &-option {
            @include size(rem(26px));
            border-radius: 2px;
            background-color: $color-brand-orange;
            color: $color-brand-white;
            font-size: 20px;
            display: inline-flex;
            justify-content: center;

            &:not(:last-child) {
                margin-right: 10%;
            }
        }

        @include screen($bp-phablet, 'min') {
            &-option {
                &:hover {
                    background-color: $color-brand-black;
                }
            }
        }
    }


    

    @include screen($bp-tablet) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(100%);
        @include transition(transform);
        background-color: $color-brand-white;

        &--reveal {
            transform: translateY(0%);
        }


        &__box {
            background: none !important;
            padding: 0;
            min-height: inherit;
            &-heading {
                display: none;
            }
            &-link {
                display: block;
                width: 100%;
                margin-top: 0;
            }

            &:before {
                display: none;
            }
        }

        &__link {
            display: none;
            justify-content: center;
            margin: 1rem 0;
            padding-left: 0;

            &--share {
                display: flex;
            }
        }

        &__link-label {
            display: none;
        }

        &__link-option {
            &:not(:last-child) {
                margin-right: 1rem;
            }
            margin: 0 1rem;
        }
    }
}
