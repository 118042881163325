$story-height: rem(400px);
$story-padding: rem(140px);;

.story-tile {
    @include bg-cover;
    min-height: $story-height;
    color: $color-brand-black;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    @include respond-to-max-width($bp-tablet) {
        flex-direction: column-reverse;
    }
    
    & + & {
        margin-top: rem(55px);
    }
    &:hover {
        box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.05);
    }


    &:before:not(pdf) {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60%;
        z-index: 1;

        @include gradient( rgba(0,0,0,.45), rgba(45,53,57, 0), 0deg, 100% ) ; 
    }

	&.pdf{
        padding: 0;
		min-height: rem(0px);
		background-color: white;
		
		.story-tile-banner{
			top: 40%;
		}
	}
}

.story-tile-banner {
    width: 50%;
    background-size: cover;
    background-position: center;
    z-index: 1;
    position: static;
    @include respond-to-max-width($bp-tablet) {
        width: 100%;
        height: 40vw;
    }

    // transform: scale(1.0, 1.0) translateX(-25%);
    // transition: transform .5s;
    // transform-origin: 0;

}

.is-zoomed {
    .story-tile-banner {
        // transform: scale(1.05, 1.05) translateX(-25%);
    }

    .story-tile-title {
        &:after {
            // width: rem(25px);
        }
    }
}


.story-tile-info {
    background-color: $color-brand-white;
    z-index: 2;
    padding: 2rem;
    width: 50%;
    box-sizing: border-box;
    @include respond-to-max-width($bp-tablet) {
        width: 100%;
    }
}


.story-tile-title {
    &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        bottom: 0;
        width: rem(10px);
        background-color: $color-brand-orange;
        z-index: 1;

        @include transition(width);
    }


    &:hover {
        color: $color-brand-orange;
    }
}

.story-tile-heading {
    @include font-soleil-bold(32px);
    line-height: 1.2;
    margin: 0 0 1rem 0;
}

.story-tile-related-info {
    //1 lines of 1.25rem @1.25 line spacing plus gap
}

.story-tile-practices {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.story-tile-practice {
    @include font-soleil-bold(20px);
    color: $color-brand-orange;
    line-height: 1.2;

    &:hover{
        color: $color-text-gray;
    }
}

.story-tile-cta {
    @include font-soleil-bold(20px);

    line-height: 1.25;
    position: relative;
    display: inline-block;


    &:hover {
        color: $color-brand-orange;

        &:after {
            transform: translateX(50%);
        }
    }

    &:after {
        @include transition(transform);
        
        content:$ionicon-var-arrow-right-b;
        font-family:$font-family-ionicons;
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.25em;
        font-size: 0.8em;
    }
}


@include respond-to-max-width($bp-desktop) {

    // .story-tile {
    //     $desktop-padding: rem(20px);
        
    //     padding-left: $desktop-padding;
    //     padding-right: $desktop-padding;
    // }
}


@include respond-to-max-width($bp-phablet) {

    .story-tile-info {
         display: block;

    }

    .story-tile-title {
        width: auto;
        border-left: 0;
        padding-left: 0;
        margin-left: 0;
    }
    .story-tile-heading {
        font-size: rem(28px);
    }

    .story-tile-related-info {
        margin-top: rem(15px);
        padding-bottom: $white-space-base;
    }
}