﻿.insight-landing {
	.applied-filters {
		@include base-wrapper;
	}
}

.insights-events-landing {
	@include screen($bp-phablet) {
		.flexible-content-component {
			padding-bottom: 0;
		}
		.trending-topics,
		.latest-insights {
			padding: 3rem 0;
		}
	}
}