.tile-item {
  margin: 1rem 0;
  height: auto;
  cursor: pointer;
  background-color: $color-brand-white;
  min-height: em(300px);
  display: flex;
  flex-direction: column;
  color: $color-black;
  width: 100%;
  transition: all $transition-ease $transition-timing;
  border-top: 4px solid $color-border-gray-alt;

  // &--with-border {
  //   border-top: 2px solid $color-med-light-gray;
  // }
  .pdf-tile & {
    width: 100%;
    min-height: 0;
    margin: 1rem 0;
    &__title {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    &__content {
      display: block;
      padding: 0;
    }
  }
  &:hover {
    color: $color-black;
    background-color: $color-brand-white;
    box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.05);
    text-decoration: none;
    border-color:$color-brand-orange;

    .tile-item__image {
      background-image: url('#{$tile-path}/orange.jpg');
    }
    .tile-item__title {
      text-decoration: underline;
      color: $color-brand-orange;
    }
    .tile-item__cta {
      color: $color-brand-orange;
    }
  }

  &__thirds {
    @include screen($bp-tablet, 'min') {
      display: flex;
      margin: 0 -1rem;
      flex-flow: row wrap;
      .btn-viewmore--full {
        margin: 0 1rem 3rem 1rem;
      }
    }  
  }

  @include screen($bp-tablet, 'min') {
    margin: 1rem;
    width: calc(50% - 2rem);
  }

  @include screen($bp-desktop-large, 'min') {
    margin: 1rem;
    width: calc(100% / 3 - 2rem);

    .page-layout-blog &{
      width: calc(50% - 2rem);
    }
  }

  &__image {
    background-size: cover;
    background-image: url('#{$tile-path}/gray.jpg');
    width: 100%;
    padding-bottom: 50%;
  }

  &__content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    

    &__source {
      margin-bottom: 0.5rem;
    }

    &--external-link {
      padding-bottom: 3rem;
      position: relative;

      &:after {
        @include size(32px);

        content: $ionicon-var-android-open;
        font-family: $font-family-ionicons;
        display:inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        bottom: 1.25rem;
        right: 1.5rem;
        background-color: $color-brand-orange;
        color: $color-brand-white;
      }
    }
  }

  &__meta {
    margin-bottom: 1rem;
    font-size: rem(12px);
    text-transform: uppercase;

    > li, > span {
      padding-right: 0.5em;
      display: inline;
      &:not(:first-child) {
        &:before {
          padding-right: 0.5em;
          content: '|';
        }
      }
    }
  }

  &__title {
    font-size: rem(24px);
    font-weight: $font-weight-bold;
    margin-bottom: 1rem;
    line-height: 1.25em;
    transition: color 0.2s ease-in-out;
  }

  &__source {
    margin-bottom: 1rem;
  }

  &__abstract {
    margin-bottom: 1rem;
    font-weight: 300;
  }

  &__links {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }

  &__cta {
    font-weight: $font-weight-bold;
    transition: color 0.2s ease-in-out;

    &:after {
      color: $color-brand-orange;
      content: $ionicon-var-ios-arrow-right;
      font-family: $font-family-ionicons;
      position: relative;
      vertical-align: middle;
      font-weight: $font-weight-bold;
      padding-left: 1em;
    }
  }

  &__podcast {
    font-weight: $font-weight-bold;

    &:after {
      color: $color-brand-orange;
      content: $ionicon-var-android-microphone;
      font-family: $font-family-ionicons;
      position: relative;
      vertical-align: middle;
      font-weight: $font-weight-bold;
      padding-left: 1em;
    }
  }
}
