.filter-topics {
    margin-bottom: $top-bottom-spacing;
}

.filter-topics-label {
    @include font-soleil-bold(24px);
    margin-bottom: rem(20px);
}


.filter-topics-list {
    @include clearfix;

    border: 0 none;
    padding: 0 0 1px;
    margin: 0;
    overflow: hidden;
   
	    

    &.is-collapsed {
		.filter-topic-option.non-featured {
			display: none;             
		}    
	}
}



.filter-topic-option,
.filter-topic-view-all {
    $spacing: rem(15px);

    @include font-soleil-bold(15px);

    color: $color-brand-purple;
    padding: $spacing;
    background-color: #e5e5e5;
    border: 1px solid $color-brand-purple;

    float: left;
    display: inline-block;
    transition: background-color $transition-timing $transition-ease,
                color $transition-timing $transition-ease;
    margin-right: .5em;
    border: none;
    line-height: 1em;
    margin-bottom: .5em;

}

.filter-topic-option {
    &:hover,
    &.is-selected {
        background-color: $color-brand-purple;
        color: $color-brand-white;
    }
}

.filter-topic-view-all {
    background-color: $color-brand-purple;
    color: $color-brand-white;        
    text-transform: uppercase;
    margin-left: 5px;

    &:hover {
        background-color: $color-text-gray; 
        color: $color-med-light-gray;
        border-color: $color-text-gray;
    }
}


@include screen($bp-mobile) {

    .filter-topics-label {
        $arrow-spacing: rem(40px);
        $spacing: rem(20px);

        font-size: rem(16px);
        background-color: $color-med-light-gray;
        padding-right: $arrow-spacing;
        border: 1px solid $color-input-gray;
        padding: $spacing/2 $arrow-spacing $spacing/2 $spacing;
        cursor:pointer;
        position: relative;

        &:after {
            @include center(vertical);

            content:$ionicon-var-ios-arrow-down;
            right: $arrow-spacing/2;
            font-family:$font-family-ionicons;
            font-size: rem(25px);
            transform-origin: left top;
            transition: transform $transition-ease $transition-timing;
        }


        &.show-topics {
            &:after {
                transform: rotateX(180deg) translateY(-50%);
            }
        }

    }
    .filter-topics-list {
        display: none;
    }
}