.insights-area {
  margin: $left-right-spacing 0;

  .tile-border-expand {
    &:after {
      background-color:$color-brand-yellow;
    }
  }
  .tile-item:nth-child(n+4) {
    border-top: 3px solid $color-light-gray;
    .tile-item__image {
        display: none;
    }
  }


  &--hidden {
      display: none;
  }
}
