//neutrals
$color-brand-white: #fff;
$color-brand-gray: #343434;
$color-input-background-gray: #f4f4f4;
$color-input-gray: #7e7e7e;
$color-header-gray: #67696b;
$color-light-gray: #c2c2c2;
$color-lighter-gray: #FCFCFC;
$color-dark-gray-font: #444446;
$color-med-gray: #9fa2a6;
$color-med-light-gray: #ececec;
$color-light-black: #323232;
$color-brand-black: #000;
$color-black: #000;
$color-text-gray: #68686c;
$color-button-border-gray: #8e8e92;
$color-section-gray: #f7f7f7;

//oranges
// $color-brand-orange: #ff7900;
$color-brand-orange: #e76d00;
$color-border-orange: #eeb698;
$color-border-gray: #b8b8b8;
$color-border-gray-alt: #d8d8d8;

//greens
$color-brand-green: #77aa00;
$color-dark-green: #a3ce40;

//purples
$color-brand-purple: #bb3185;
$color-dark-purple: #b7247d;

//yellows
$color-brand-yellow: #ffc72c;
$color-brand-darkyellow: #ebb623;

//colors with opacity
$color-opaque-light: rgba(255, 255, 255, 0.7);
$color-opaque-gray: rgba(104, 104, 108, 0.9);
$color-opaque-dark: rgba(49, 49, 49, 0.7);

//redesign colors
$color-charcoal: #272727;
$color-gray-section-background: #f8f8f8;
$color-bilzin-orange: #ff7900;
$color-gray-border-redesign: #d9d9d9;
