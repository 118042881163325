.flexible-content-component {
    padding-top: $white-space-base;
    .attorney-area,
    .experience-area,
    .insights-area,
    .topics-area {
        margin: 0;
        padding: 0;
    }

    .attorney-area {
         border-top: none;
    }

    .featured-article-info {
        min-height: 8rem;
        justify-content: center;
    }

    &.flex-panel-component {
        padding: 0;
        & + .flex-panel-component {
            border-top: $border-section;
        }
    }

    .featured-article-component {
        .section-title {
            margin-bottom: 1em;
            line-height: 1.15;
            text-align: center;
        }
    }
    .tile-item:nth-child(n+4) {
        border-top: 3px solid $color-light-gray;
        .tile-item__image {
            display: none;
        }
    }

    &:last-of-type {
        padding-bottom: $white-space-base;
    }
}

.flexible-content-page {
    .large-story-title {
        max-width: 100%;
    }

    @include screen($bp-desktop, min) {
        scroll-snap-align: start;
        scroll-margin-top: $head-height-collapsed;
        scroll-snap-stop: always;
    }
}