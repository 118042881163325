.footer {
  background-color: $color-text-gray;
  color: $color-brand-white;
  background-color: $color-charcoal;
  padding: 1rem 0;

  $btn-padding: rem(15px);

  &-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;

    h3 {
      font-weight: $font-weight-light;
      font-size: 1.5rem;
    }

    &__panel {
      flex: 1;

      h2 {
          line-height: 1.4;
          margin: 0 auto rem(18px);
          font-size: rem(14px);
          font-weight: $font-weight-semibold;
          letter-spacing: 2.1px;
          text-transform: uppercase;
      }
      h3 {
          line-height: 1.4;
          margin:auto;
          font-size:1em;
          font-weight:400;
      }
    }

    &__logo {
      max-width: 200px;
      margin-bottom: 1rem;
      img {
        max-width: 100%;
      }
    }

    &--reverse {
      flex-direction: column-reverse;
    }
  }

  &__quick-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      width: 45%;
      padding-bottom: 1em;
      margin-right: 1em;
    }
  }

  &-btn {
    color: $color-brand-white;
    border: 2px solid $color-brand-orange;
    padding: $btn-padding/2 $btn-padding;
    display: inline-flex;
    align-items: center;

    &:after {
      content: $ionicon-var-ios-arrow-right;
      font-family: $font-family-ionicons;
      position: relative;
      font-weight: bold;
      margin-left: 0.5rem;
    }
    &:hover {
      background-color: $color-brand-orange;
      color: $color-charcoal;
      transition: all 0.2s ease-in-out;
      text-decoration: none;
    }
  }

  @include screen($bp-mobile, 'min') {
    &-container {
      flex-direction: row;
    }
  }

  .home ~ & {
    margin-top: 0;
  }

  .footer-legal {
    font-size: rem(14px);
    padding-right: 1rem;

    .copyright {
      margin-bottom: 5px;

      span {
        display: block;
      }
    }
  }

  .social-links {
    font-size: rem(24px);
    margin-bottom: $default-spacing;

    a:not(:first-child) {
      margin-left: rem(20px);
    }
  }

  .other-links {
    font-size: rem(14px);
    font-family: $font-family-primary;

    a + a {
      &:before {
        content: '|';
        padding: 0 10px;
        color: $color-brand-white;
      }
    }
  }
}

@include screen($bp-desktop) {
  .footer {
    .footer-links {
      margin-bottom: $top-bottom-spacing;
    }
  }
}

@include screen($bp-desktop, 'min') {
  .footer {
    padding: 2rem 0;

    .footer-legal {
      flex-grow: 1;

      .copyright {
        span {
          display: inline;
        }

        span + span {
          &:before {
            content: '|';
            padding: 0 10px;
            color: $color-brand-white;
          }
        }
      }
    }

    .footer-links {
      display: flex;
      flex-direction: column;
      min-height: 1px;
      justify-content: space-between;
      text-align: right;
      flex: 0.5;
    }
  }
}
