.attorney-area {
	$white-space: rem(70px);
	border-top: 3px solid $color-border-gray;
	padding: $white-space 0;
	margin-top: $white-space;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;

	&-background {
		// default if no image
		background-color: black;

		.bio-card-body {
			background-color: white;
			border-bottom: 2px solid $color-border-gray;
		}
	}

	&__contact-people {
		margin-top: none;
	}


	.section-title {
		$vertical-spacing: rem(50px);
		margin: 0 6rem $vertical-spacing;
		color: $color-brand-white;
		z-index: 1;

		&--small {
			margin-bottom: rem(32px);
		}
	}
}

.attorney-area-listing {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	z-index: 1;
	gap: 1rem;

	.bio-card,
	.author-card {
		width: $tile-grid-one-third;
		box-sizing: border-box;
		margin-bottom: 2vw; //need for ie and firefox calculation

		@include screen($bp-tile-tablet) {
			width: 19rem;
		}
		@include screen($bp-mobile) {
			width: 19rem;
		}

		&:nth-of-type(3n) {
			margin-right: 0;
		}

		&.all-professionals {
			display: none;
		}
	}

	&--contact-people {
		width: 65.25rem;
	}

	&--half-column {
		.author-card {
			width: $tile-grid-half;
			margin-right: $tile-grid-half-gutter;
			margin-bottom: 5vw;

			&:nth-of-type(3n) {
				margin-right: $tile-grid-half-gutter;
			}

			&:nth-of-type(2n) {
				margin-right: 0;
			}
		}
	}

}

.contact-background-image {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 0;
}

.attorney-area-btn {
	margin: $white-space-base $indented-padding;
}

@include screen($bp-tile-tablet) {

	.attorney-area-listing {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.bio-card,
		.author-card {
			&:nth-of-type(3n) {
				margin-right: $tile-grid-half-gutter;
			}
		}
	}
}

@include screen($bp-desktop) {

	.stories-area-btn {
		margin-left: $indented-padding-desktop;
		margin-right: $indented-padding-desktop;
	}
}


@include screen($bp-mobile) {
	.attorney-area {
		&-listing {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			align-content: flex-end;
			width: 21.25rem;
		}

		.section-title {
			$vertical-spacing: rem(30px);
			margin-bottom: $vertical-spacing;
		}

		.bio-card:nth-of-type(2n) {
			margin-right: 1rem;
		}
	}
}