$full-height-with-nav: calc(100vh - $head-height-collapsed);

.careers-accordion {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: rem(17px) rem(16px) rem(42px);

  @include screen($bp-desktop, min) {
    padding: rem(63px) rem(16px);
    min-height: $full-height-with-nav;
  }


  &__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $site-redesign-content-width;
  }

  &__title {
    @include font-h2-redesign;
    margin-bottom: rem(16px);
  }

  &__body {
    @include font-body-redesign;
    display: block;
    margin-bottom: rem(72px);
  }

  &__expand-collapse-button {
    align-self: flex-end;
    margin-bottom: rem(10px);
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(21px);

    &:hover {
      text-decoration: none;
    }
  }

  &__button-text {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    @include font-eyebrow-redesign;

    span {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      transition: opacity 0.3s ease-in;
    }
  }

  &__expand-text {
    opacity: 1;
    .collapse & {
      opacity: 0;
    }
  }

  &__collapse-text {
    opacity: 0;
    .collapse & {
      opacity: 1;
    }
  }

  &__switch {
    box-sizing: border-box;
    width: rem(48px);
    height: rem(24px);
    background-color: $color-gray-border-redesign;
    border-radius: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: rem(6px);

    &-circle {
      height: rem(14px);
      width: rem(14px);
      background-color: $color-text-gray;
      border-radius: 50%;
      transition: all 0.3s ease-in;

      .collapse & {
        transform: translateX(calc(48px - 12px - 14px));
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    border-top: 1px solid $color-gray-border-redesign;

    &:last-of-type {
      border-bottom: 1px solid $color-gray-border-redesign;
    }

    &--content {
      display: grid;
      grid-template-rows: 0fr;
      overflow: hidden;
      transition: all 0.3s ease-in;

      .expanded & {
        grid-template-rows: 1fr;
      }
    }
    &--wrapper {
      overflow: hidden;
    }
    &--button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: rem(16px) rem(21px) rem(16px) rem(13px);

      &:hover {
        text-decoration: none;
      }

      svg {
        transform-origin: center;
        transition: all 0.3s ease-in;
        overflow: visible;
      }
      .expanded & {
        svg {
          transform: rotateX(180deg);
        }
      }
    }

    &--label {
      margin: 0px;
      text-align: left;
    }

    &--body {
      padding: rem(15px) rem(10px);
    }
  }
}
