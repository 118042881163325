$ac-grid-spacing:3%;

.audience-controls {
    background-color: $color-med-light-gray;
    padding: $wrapper-subpadding 0;
    display: flex;
    flex-wrap: wrap;
   
}

.audience-controls-mobile{
    display: none;
}

.audience-controls-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: (298/396) * 100%;

    img {
        @include center(both);

        min-width: 100%;
        position: absolute;
        transition: transform 1s ease-in-out;
    }
}

.audience-controls-option {
    display: block;
    box-sizing: border-box;
    width: 33.33334%;
    padding:0 $wrapper-subpadding $wrapper-subpadding; //need for ie and firefox calculation
    color: $color-brand-orange;

    &:hover {
        color: $color-text-gray;

        img {
            transform: translate(-50%, -50%) scale(1.1, 1.1);
        }
    }
}

.audience-controls-title {
    @include font-soleil-bold(24px);

    line-height: 1.2;
    margin: rem(25px) 0 0;
}

@include screen($bp-tile-tablet) {
    $ac-grid-spacing:3%;

    .audience-controls-option {
        width: calc(100 / 3) ;
        margin-bottom: 0;
    }
}


@include screen($bp-tile-mobile) {
    .audience-controls {
        display: none;
        background-color: $color-brand-white;
    }

    .audience-controls-option {
        width: 90%;
        margin: 1em;
        height: 25%;
    }
    .audience-controls-img {
        display: none;   
    }
    .audience-controls-title{
        top:33%;
        position:relative;
        margin-top: 0;
    }
    .audience-controls-mobile{
        display: block;
        text-align: center;
        height: 84vh;
        padding-top: 0;
        .mobile-header{
            background-color: transparent;
            padding-bottom: 0;
        }
        img{
            opacity: 0.6;
        }
        .audience-controls-option{
            position:relative;
            padding-bottom: 0;
            background-color: rgba(255,255,255,.5);
            border-style:solid;
            border-color:white;
            border-width: 1px;
        }
    }
}