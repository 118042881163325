.tiles {
    @include screen($bp-mobile, 'min') {
        display: flex;
        margin: 0 -1rem;
        flex-flow: row wrap;
    }  
}

.tile-base {
    box-sizing: border-box;
    width: $tile-grid-one-third;
    margin: $tile-grid-one-third-gutter;
    // margin-right: $tile-grid-one-third-gutter;
    // margin-bottom: 2vw; //need for ie and firefox calculation

    &.image-tile {
        border: none;

        .tile-container {
            padding: 0;
        }
    }

    &:nth-of-type(3n) {
        margin-right: 0;
    }

    .tile-border-expand {
        height: rem(30px);
        width: 100%;
        
        z-index: 3;
        margin-top: -2px;
        box-sizing: border-box;

        &:after {
            transition: height .5s;
            content: '';
            display: block;
            width: 100%;
            height: 0;
        }
    }

    .tile-container {
        transform: scale(1.0, 1.0);
        transition: transform .5s;
        border-top: 2px solid $color-light-gray;
    }

    &.is-zoomed {

        .tile-container {
            transform: scale(1.05, 1.05);
            transition: transform .5s;

            &:after {
                content: " ";
            }

            .tile-border-expand {

                &:after {
                    height: 5px;
                }
            }
        }
    }

    @include respond-to-max-width($bp-tile-tablet) {
        width: $tile-grid-half;
        margin-right: $tile-grid-half-gutter;
        margin-bottom: 5vw;

        &:nth-of-type(3n) {
            margin-right: $tile-grid-half-gutter;
        }

        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    @include respond-to-max-width($bp-tile-mobile) {
        width: 100%;
        margin:0 0 5%;
    }

    .tile-image {
        min-height: rem(250px);
        max-height: rem(250px);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: rem(30px);
    }

    .type {
        padding: 0 rem($section-spacing);

        .dot {
            display: inline-block;
            width: rem(14px);
            height: rem(14px);
            border-radius: rem(14px);
            margin-right: rem(10px);
        }

        .type-name {
            font-family: $font-family-primary;
            font-weight: $font-weight-bold;
            font-size: rem(18px);
        }
    }

    .title {
        font-weight: $font-weight-bold;
        font-size: $section-title-size;
        padding: rem(10px) rem($section-spacing) rem($section-spacing);
        color: $color-header-gray;
        line-height: 1.25em;
    }

    .abstract {
        font-family: $font-family-bookmania;
        font-size: rem(18px);
        padding: 0 rem($section-spacing);
        line-height: $text-line-height;
        color: $color-header-gray;
    }
}