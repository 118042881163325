.bio-overview {
    width: $inner-one-third-width;
    line-height: $text-line-height;
    margin-bottom: 10px;

    @include respond-to-max-width($bp-bio-overview-scale) {
        width: 100%;
        margin-bottom: 0;
    }

    @include respond-to-max-width($bp-desktop) {
    }



    .contact-info {
        font-family: $font-family-primary;
        opacity: 1;
        padding: rem(24px);
        background-color: $color-opaque-light;
        color: $color-dark-gray-font;
        font-size: rem(16px);

        @include respond-to-max-width($bp-bio-overview-scale) {
            width: calc(100% / 3 - 3.2em);
        }

        @include respond-to-max-width($bp-tablet) {
            width: 39%;
            font-size:.75rem;
            padding-top: .75rem;
            padding-bottom: .75rem;
        }

        @include respond-to-max-width($bp-mid-mobile) {
            margin-top: 70vw;
            width: auto;
            font-size: rem(14px);

            @media screen and (min-device-width: 769px) {
                margin-top: 37vw;
            }
        }



        .name {
            font-family: $font-family-primary;
            font-weight: $font-weight-bold;
            font-size: 2vw;
            margin-bottom: 1rem;
            line-height: 1;
            margin-top: 0;

            @include respond-to-max-width($bp-desktop-large) {
                font-size: 2.5vw;
            }

            @include respond-to-max-width($bp-mid-mobile) {
                font-size: 5vw;
            }
        }


        .spacer {
            margin: 1rem 0;
            border-top: 1px solid $color-light-gray;
            width: rem(75px);

            @include screen($bp-tablet) {
                margin:0; 
            }
        }

        .focus-area {
            margin-top: 1rem;
        }

        .area-of-focus-link:not(:last-of-type) {
            &::after {
                content: ",";
                margin-right: 0.2em;
            }
        }

        .language-indicator {
            margin-right: 1vw;
            font-style: italic;
        }

        @include respond-to-min-width($bp-mid-mobile) {
            .flex-wrapper {
                display: flex;
                flex-wrap: wrap;
            }

            .phone {
                flex-grow: 5;
                display: flex;
            }

            .language-indicator {
                display: flex;
            }
        }

        .email {
            display: block;
        }

        a {
            color: $color-brand-orange;
        }
    }

    .quote {
        font-family: $font-family-bookmania;
        background-color: $color-opaque-dark;
        color: $color-brand-white;
        font-size: rem(16px);
        padding: rem(10px) rem(24px);

        @include respond-to-max-width($bp-bio-image-scale) {
            font-size: rem(16px);
        }

        @include respond-to-max-width($bp-bio-overview-scale) {
            //margin-top: 15vw;
            padding: rem(24px);
            background-color: $color-dark-gray-font;
        }

        @include respond-to-max-width($bp-desktop-large) {
            //margin-top: 10vw;
        }

        @include respond-to-max-width($bp-tablet) {
            //margin-top: 2vw;
        }

        @include respond-to-max-width($bp-mid-mobile) {
            //margin-top: 3vw;
        }

        @include respond-to-max-width($bp-mobile) {
            margin-top: 0;
        }
        //below are fixes for crazy zoom levels
        @media screen and (max-width: 78.125rem) and (min-device-width: 1024px) {
            margin-top: 0;
            width: auto;
        }

        @media screen and (max-width: 1024px) and (min-device-width: 769px) {
            margin-top: 0;
        }
    }
}
