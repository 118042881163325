.page-default {
  //height: 100vh;
  //width: 100vw;

  .main-content {
    // max-width: $site-max-width;
    min-height: calc(100vh - #{$head-height-lg});
    margin-left: auto;
    margin-right: auto;
    position: relative;
    // padding: 0 2rem;
    z-index: 1;

    @include respond-to-max-width($bp-mid-mobile) {
      margin-top: $head-height-sm;
    }
  }
}
