.credentials-area {
    background-color: $color-lighter-gray;
    padding: 60px 0;

    .credentials {
        display: flex;
        justify-content: space-between;
        .credentials-container {
            list-style: none;
            margin: 0;
            padding: rem(40px) 0 0;
            width: calc(50% - 1em);
            
            .credential-subitem {
                margin-left: 1em;
            }
            
            .credential {
                border-bottom: 2px solid $color-med-light-gray;
                padding-bottom: rem(20px);
                padding-left: rem(20px);
                margin-bottom: rem(20px);

                // .icon {
                //     font-size: rem(16px);
                //     transform: rotate(45deg);
                //     transition: transform .3s;
                //     margin-left: 10px;
                // }

                .title {
                    font-size: rem(22px);
                    cursor: pointer;

                    &.expanded {
                        color: $color-brand-orange;
                    }
                    &:hover {
                        color: $color-brand-orange;
                    }

                    &.expanded {
                        &:after {
                            content: $ionicon-var-minus;
                        }

                    }

                    &:after {
                        content: $ionicon-var-android-add;
                        font-family: $font-family-ionicons;
                        vertical-align: middle;
                        font-size: rem(22px);
                        position: relative;
                        // font-weight: $font-weight-bold;
                        margin-left: 10px;
                    }
                }

                .credential-item {
                    margin-bottom: rem(10px);
                }

                .credential-content {
                    padding-top: rem(25px);
                    display: none;
                    &.expanded{
                        display:block;
                    }
                }
            }
        }
    }

    @include respond-to-max-width($bp-mid-mobile) {
        padding: 40px 0;

        .credentials {
            display:block;

            .credentials-container {
                width: 100%;

                .credential {
                    .title {
                        font-size: rem(18px);
                    }
                }
            }
        }
    }
}
