.blog-filters {
    padding: 1rem;

    &__control {
        display: flex;
        align-items: center;

        &-label {
            flex-shrink: 0;
            max-width: 90px;
            margin-right: 0.5rem;
            display: inline-block;
        }

        .dropdown {
            flex-grow: 1;
        }
    }

    &__clear-filter {
        color: $color-brand-orange;
        padding-left: 6px;
        padding-right: 0;
        margin-bottom: 0.5rem;
        
        &:before {
            content: $ionicon-var-close;
            font-family: $font-family-ionicons;
            padding-right: rem(8px);
            display: inline-block;
        }
    }

    
    &__activated {
        margin-top: 2rem;
    }

    &__applied & {
        &__clear-filter {
            margin-right: 0
        }
    }
    @include screen($bp-mobile) {
       &__img {
           display: none;
       }
    }
}
