.blog-podcast-card {
  width: calc(100% / 4 - 2rem);
  margin: 1rem;
  height: auto;
  cursor: pointer;
  background-color: $color-brand-white;
  min-height: em(400px);
  transition: all $transition-ease $transition-timing;
  @include screen($bp-tablet, 'max') {
    width: calc(50% - 2rem);
  }
  &:hover {
    box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.05);
    background-color: $color-brand-white;
    text-decoration: none;
    .blog-podcast-card__data__title {
      text-decoration: underline;
    }
  }

  &__image {
    background-size: cover;
    width: 100%;
    padding-bottom: 60%;
  }

  &__icon {
    width: rem(40px);
    height: rem(40px);
    border-radius: 20px;
    color: $color-brand-white;
    background-color: $color-brand-orange;
    text-align: center;
    vertical-align: middle;
    margin: rem(-20px) auto;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: rem(27px);
    }
  }

  &__data {
    padding: 2em 1em;
    text-align: center;
    font-weight: 300;

    &__title {
      font-size: rem(20px);
      font-weight: $font-weight-bold;
      padding-bottom: 0.5em;
    }
  }
}
