﻿.modal-wrapper {
	opacity: 0;
    background-color: rgba(0,0,0,.4);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity .2s,visibility .2s;
    visibility: hidden;
    z-index: 999;
    transition: opacity .2s,visibility .2s;
	-webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

	&.active {
		visibility: visible;
		opacity: 1;

		.modal-container { 
			transform: scale(1);
		}
	}

	.modal-container {
	    background-color: $color-brand-white;
		-webkit-box-shadow: 0 0 .3rem 0 rgba(0,0,0,.5);
		box-shadow: 0 0 .3rem 0 rgba(0,0,0,.5);
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		height: 90vw;
		max-height: 30rem;
		max-width: 30rem;
		padding: 1rem;
		-webkit-transform: scale(.9);
		-ms-transform: scale(.9);
		transform: scale(.9);
		-webkit-transition: -webkit-transform .2s;
		transition: transform .2s;
		width: 90vw;

		@media (min-width: 960px) {
			padding: 4rem;
			height: 50vw;
			width: 50vw;
		}

		@media (min-width: $bp-mobile-portrait) {
			padding: 1.5rem;
			height: 100vw;
		}

		.flex-panel-cta-link {
		  position: absolute;
			bottom: 24px;
			width: 45%;
			//changed for safari
			min-width: 0;
			@include screen($bp-mobile) {
				width: 44%;
			}

			&.close-btn-js {
				right: 24px;	
			}
		}
	}
}

span.email {
	cursor: pointer;
	color: $color-brand-orange;	
}
