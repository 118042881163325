.stories-area {
	margin-bottom: $white-space-base;

    

    .section-title {
    	text-transform: uppercase;
    	text-align: center;
        border-top: 3px solid $color-border-gray;
        padding-top: $white-space-base;
        margin: $white-space-base $indented-padding;
    }
}

.stories-area-btn {
        
    margin:$white-space-base 0;
    
}

@include screen($bp-desktop) {

    .stories-area {
        .section-title {
            margin-left: $indented-padding-desktop;
            margin-right: $indented-padding-desktop;
        }
    }

    .stories-area-btn {
        margin-left: $indented-padding-desktop;
        margin-right: $indented-padding-desktop;
    }
}
