.bio-q-and-a {
    padding: 3rem;
    background-color: $color-med-light-gray;

    @include respond-to-max-width($bp-bio-overview-scale) {
        padding: 2rem;
    }

    .section-title {
        margin-bottom: 30px;
    }

    .rich-text {
        color: $color-charcoal;
    }

    .rich-text.q {
        margin-bottom: 0;
        color: $color-black;
        font-weight: $font-weight-bold;
    }

    @include respond-to-max-width($bp-mobile) {
        .bio-q-and-a__section {
            padding: 0;
        }
    }
}