﻿.practice-detail {
	$white-space: rem(50px);
	.breadcrumb {
		z-index: 20;
		top: 0;
		position: absolute;
		padding: $head-height-lg 0 5rem 0;
		width: 100%;
		&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 30%, transparent 100%);
				mix-blend-mode: multiply;
		}
	}
	.detail-overview-content {
		padding-top: 0;
	}
	.attorney-area {
		margin-bottom: $white-space;
	}
	
	.practices-area {
		margin-top: $white-space;
	}

	.related-industries{
		margin-top: rem(64px);
	}

    .experience-area {
        .tile-item {
            &__image {
                display: none;
            }
        }
    }

    .insights-area {
        .tile-item {
            &__image {
                display: none;
            }
        }
    }
}