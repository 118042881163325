$bc-img-width: rem(420px);

.bio-card-list {
    background-color: $color-med-light-gray;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.bio-card-list-figure {
    border-left: 20px solid $color-brand-orange;
    width: $bc-img-width;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;

    img {
        //@include center(horizontal);
        position: relative;
        display: block;
        left: 0;

         @media screen and (max-width: 1024px) {
            margin-left: -7em;
        }
         @include respond-to-max-width($bp-mid-mobile) {
            margin-left: 0;
        }
    }
}

.bio-card-list-body {
    @include clearfix;
    flex-grow: 1;
    vertical-align: middle;
    padding: $white-space-half 0 $white-space-half 5%;
    color: $color-dark-gray-font;
}


.bio-card-list-name {
    @include font-soleil-bold(36px);
    line-height: 1.1;
    margin: 0 0 .25em;
}

.bio-card-list-link,
.bio-card-list-email {
    color: $color-brand-orange;

    &:hover {
        color: $color-text-gray;
    }
}


.bio-card-list-info,
.bio-card-list-contact {
    width: 55%;
    float: left;
    padding-right: $wrapper-subpadding/2;
    box-sizing: border-box;
}

.bio-card-list-contact {
    @include clearfix;
    width: 35%;
    float: right;
    padding-right: $wrapper-subpadding;
}

.bio-card-list-focus-area {
    margin-top: rem(10px);
}


.bio-card-list-social {
    margin: rem(10px) 0 0;

    .share-tool {
        margin: 0;
        border: 0;

        & + .share-tool {
            border-left: 1px solid $color-border-orange;
        }

        a, span {
            $icon-size: rem(45px);
            $icon-size-mobile: rem(45px);

            @include size($icon-size);

            @include respond-to-max-width($bp-mid-mobile) {
                @include size($icon-size-mobile);
            }
        }
    }
}

@include screen($bp-desktop-large) {

    .bio-card-list-figure {
        width: rem(320px);

        img {
            //left: 30%;
        }
    }

    .bio-card-list-info {
        width: 55%;
    }

    .bio-card-list-contact {
        width: 45%;
    }
}

@include screen($bp-phablet) {

    .bio-card-list-figure {
        width: rem(250px);

        img {
            //left: 30%;
            min-height: inherit;
            width: rem(320px);
        }
    }

    .bio-card-list-info {
        width: 55%;
    }

    .bio-card-list-contact {
        width: 45%;
    }

    .bio-card-list-name {
        font-size: rem(30px);
    }
}


@include screen($bp-mid-mobile) {

    .bio-card-list {
        display: block;
    }

    .bio-card-list-figure {
        border-top: 10px solid $color-brand-orange;
        border-left: 0;
        width: 100%;

        img {
            left: auto;
            transform: translateY(0);
            min-height: inherit;
            width: 100%;
        }
    }

    .bio-card-list-info {
        width: 55%;
    }

    .bio-card-list-contact {
        width: 45%;
    }

    .bio-card-list-name {
        font-size: rem(30px);
    }
}

@include screen($bp-mobile-portrait) {
    .bio-card-list-contact,
    .bio-card-list-info {
        float: none;
        width: 100%;
    }

    .bio-card-list-contact {
        margin-top: rem(10px);
    }
}
