.experience-tile {
  .type {
    .dot {
      background-color: $color-brand-green;
    }

    .type-name {
      color: $color-brand-green;
    }
  }

  .title {
    a {
      color: inherit;
      &:hover {
        color: $color-brand-green;
      }
    }
  }
}
