.jump-navigation {
  background-color: $color-section-gray;
  color: $color-black;
  position: relative;
  position: sticky;
  top: calc(#{$head-height-collapsed} - 1px);
  z-index: $z-index-jumpmenu;
  margin-bottom: 3rem;
  transition: top .3s ease-in;
  &__jump-to {
    font-weight: $font-weight-bold;
  }
  &__items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
  }
  &__item,
  &__jump-to {
    border-bottom: 3px solid transparent;
    padding: 5px 0;
    margin: 0.5rem 1rem;
    position: relative;
    display: block;
    z-index: 2;
    &.is-active {
      border-bottom: 3px solid $color-brand-orange;
    }
  }

  &__item {
    &:hover {
      cursor: pointer;
    }
  }

  &__mobile-toggle {
    padding: 0.5rem 0;
    font-size: 1rem;
    width: 100%;
    text-align: left;

    &:focus {
      outline-offset: -2px;
    }

    &::after {
      content: $ionicon-var-chevron-down;
      font-family: $font-family-ionicons;
      width: 1em;
      height: 1em;
      display: inline-block;
    }
  }

  &__mobile-toggle {
    display: none;
  }
  &.show-navigation {
    .jump-navigation__items {
      display: block;
    }
  }

  @include respond-to-max-width($bp-phablet) {
    .insights-events-landing &{
      margin-bottom: 0;
    } 
    &__jump-to,
    &__item {
      padding: 1px 0;
      margin: 5px;
    }
    &__view-all {
      padding: 0 1rem;
    }
  }
  @include respond-to-max-width($bp-phablet) {
    top: calc(#{$head-height-sm} - 1px);

    &__mobile-toggle {
      display: block;
    }
    &__items {
      display: none;
      background: $color-brand-white;
      position: absolute;
      width: calc(100% - 2rem);
      left: 0;
      padding: 0 1rem;
    }
    &__jump-to,
    &__item {
      margin: 5px 0;

      &.is-active {
        border-bottom: 0 none;
      }
    }
  }
}
