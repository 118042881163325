.search-results {
    margin-top: $white-space-base;

    .btn-viewmore {
        margin-top: $white-space-base;
    }
}

.search-results-item {
    margin-top: $white-space-half;

    & + .search-results-item {
        padding-top:  $white-space-half;
        border-top: 2px solid $color-border-gray;
    }
}

.search-results-page-title {
    @include font-soleil-bold(36px);

    line-height: 1.1;
    margin: 0 0 .25em;   

	&.no-link {
		&:hover {
			color: $color-text-gray !important;
		}
	}
}

.search-results-meta-section{
    @include font-soleil-bold(18px);

    color:$color-text-gray;
    margin-top: 0.5em;

}

.search-results-abstract {
    margin-top: 1em;
}

.search-results-highlight {
    text-decoration: underline;
    font-weight: $font-weight-bold;

}

.search-results-related-topics {
    margin-top: $white-space-small;

    .related-label {
        font-weight: $font-weight-bold;
    }
}

.search-results-related-topic {
    display: inline-block;
    margin-left: 0.5em;

	&.bold {
		font-weight: bold !important;
	}

	&:not(:last-child) {
		&:after {
			content: ',';
		}
	}    
}

.theme--people {

    .search-results-page-title { 
        &:hover {

        }
    }
}

.theme--insights {

    .search-results-page-title,
    .search-results-related-topic { 
        &:hover {
            color: $color-brand-yellow;
        }
    }
}

.theme--experience {

    .search-results-page-title,
    .search-results-related-topic { 
        &:hover {
            color: $color-brand-green
        }
    }
}

.theme--practices {

    .search-results-page-title,
    .search-results-related-topic { 
        &:hover {
            color: $color-brand-green;
        }
    }
}