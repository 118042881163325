$bc-side-padding: rem(20px);
$h1_font_size: 2em !default;
$h1_margin: 0.67em 0 !default;

//zooming effect
.bio-card-body {
    border-top: 10px solid $color-brand-orange;
    transform: scale(1.0, 1.0);
    transition: transform .5s;
    padding-bottom: $bc-side-padding*2;

    .is-zoomed &{
        transform: scale(1.05, 1.05);
    }
}

//updated to work with low resolution image
.bio-card-figure {
    height: 15rem;
    overflow: hidden;
    
    img {
		max-width: 100%;
		display: block;
    }
}

.bio-card-header {
    padding:   $bc-side-padding $bc-side-padding 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bio-card-abstract {
     $white-space: rem(25px);

     padding:  $white-space $bc-side-padding 0;
     font-family: $font-family-bookmania;
     font-size: rem(24px);
     line-height: 1.35;
}

.bio-card-name {
    margin: 0;
    font-weight: $font-weight-bold;   
    .pdf & {
        font-size: 1rem;
    } 
}

h3 {
    &.bio-card-name {
        font-size: $h1_font_size;
        margin: $h1_margin;
    }
}

.bio-card-level {
    text-align: left;
}

/*
.bio-card-level {
    &:before {
        content: '/';
        display: inline-block;
        margin-left: .3em;
        margin-right: .5em;
        font-weight: $font-weight-bold;
    }
} */

.bio-card-link {
    color: $color-brand-orange;


    &:hover {
        color: $color-text-gray;
    }
}

.bio-card-social {
    margin: rem(20px) 0 0;
    padding: 0 $bc-side-padding;

    .share-tool {
        a, span {
            $icon-size: rem(30px);
            $icon-size-mobile: rem(24px);

            @include size($icon-size);

            @include respond-to-max-width($bp-mid-mobile) {
                @include size($icon-size-mobile);
            }

            i {
                font-size: rem(20px);
            }
        }
    }
}

@include screen($bp-mobile) {

    .bio-card-abstract{
        font-size: rem(20px);
    }
}
