$flexible-content-hero-height: rem(250px);
$flexible-content-hero-padding: rem(140px);

.flexible-content-hero {
    @include bg-cover;

    background-color: $color-brand-black;
    padding: $white-space-base $indented-padding;
    color: $color-text-gray;
    position: relative;
    overflow: hidden;
    min-height: rem(512px);
    display: flex;

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color:rgba(49,49,49,0.25);
    }

    &-title {
        position: relative;
        z-index: 2;
        @include font-soleil-bold(50px);

        color: $color-brand-white;
        line-height: 1.2;
        margin: 0;
        max-width: 100%;
    }
    &-subtitle {
        position: relative;
        z-index: 2;
        @include font-soleil-bold(24px);

        color: $color-brand-white;
    }

    &--top {
        align-items: flex-start;
    }

    &--middle {
        align-items: center;
    }

    &--bottom {
        align-items: flex-end
    }

    &--left {
        justify-content: flex-start;
    }

    &--center {
        justify-content: center;
        text-align: center;
    }

    &--right {
        justify-content: flex-end;
    }

    &--half {
        min-height: rem(400px);
    }

    &--full {
        max-height: rem(512px);
    }
}